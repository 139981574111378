export const createObjectWithChecked = (arr) => {
  return arr.map((str) => {
    if (isObject(str)) {
      return str;
    }
    return {
      value: str,
      checked: false,
      disabled: false,
    };
  });
};

export const getCheckedValues = (arr) => {
  let ans = arr.filter((obj) => {
    if (obj.checked) {
      return obj.value;
    }
  });
  // if (ans.length) {
  //   return ans[0]["value"];
  // }
  return ans.map((val) => val.value);
};

export const getCheckIds = (arr) => {
  let ans = arr.filter((obj) => {
    if (obj.checked) {
      return obj.id;
    }
  });
  // if (ans.length) {
  //   return ans[0]["value"];
  // }
  return ans.map((val) => val.id);
};
export const extractRoi = (res, channel, expansion) => {
  try {
    return Object.keys(res['roi'][expansion])
      .filter((key) => key.includes(channel))
      .filter((key) => !key.includes('None'))
      .map((key) => res['roi'][expansion][key]);
  } catch (e) {
    return [];
  }
};

export const mapAndExtractRoi = (res, channel, expansion) => {
  try {
    return Object.keys(res['roi'][expansion])
      .filter((key) => key.includes(channel))
      .filter((key) => !key.includes('None'))
      .map((key) => res['roi'][expansion][key])
      .map((val, i) => {
        let keys = Object.keys(res['roi'][expansion]);
        keys = keys.map((k) => {
          return stripquotes(k.split(',')[1].trim().slice(0, -1));
        });
        return {
          name: keys[i],
          value: val,
        };
      });
  } catch (e) {
    return [];
  }
};
export const makeFalse = (arr, i) => {
  return arr.map((obj, index) => {
    if (index !== i) {
      obj.checked = false;
    }
    return obj;
  });
};

export const selectAll = (arr, event) => {
  return arr.map((obj) => {
    if (!obj.disabled) {
      obj.checked = event;
    }
    return obj;
  });
};

export const clearAll = (arr) => {
  arr.forEach((element) => {
    if (!element.disabled) {
      element.checked = false;
    }
  });
};
export const checkDifferenceAndAdd = (mainArr, arrToCompare) => {
  let diffArr = arrToCompare.filter((val) => {
    if (!mainArr.map((obj) => obj.name).includes(val)) {
      return val;
    }
  });
  diffArr = createObjectWithChecked(diffArr);
  return [...mainArr, ...diffArr];
};

export const deleteEmpty = (obj) => {
  for (let val in obj) {
    if (obj[val] != undefined) {
      if (!obj[val].length) {
        delete obj[val];
      }
    }
  }
  return obj;
};

export const getCookie = (name: string) => {
  const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
  const caLen: number = ca.length;
  const cookieName = `${name}=`;
  let c: string;
  for (let i = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
};

export const checkBasedOnSelectedFilters = (filterArr, selectedFilterArr) => {
  return filterArr.map((obj) => {
    if (selectedFilterArr.includes(obj.value)) {
      obj.checked = true;
    }
    return obj;
  });
};

function isObject(val) {
  return val instanceof Object;
}

export const getValsFromArrBasedOnKey = (arr, key) => {
  return arr.map((obj) => obj[key]);
};

export const addId = (filterArr, idArr) => {
  return filterArr.map((o, i) => {
    o['id'] = idArr[i];
    return o;
  });
};

export const getRequiredObj = (key, arr) => {
  return arr.filter((obj) => {
    return obj.value === key;
  });
};

function stripquotes(a) {
  if (a.charAt(0) === "'" && a.charAt(a.length - 1) === "'") {
    return a.substr(1, a.length - 2);
  }
  return a;
}

export const hasSpecialCharacters = (str) => {
  return /[^\w]/gi.test(str);
};

export const hasOnlySpaces = (str) => {
  return /^\s*$/.test(str);
};

export const checkForSpecialCharacters = (name) => {
  let regex = /^[A-Za-z0-9_]+$/;
  return regex.test(name);
};
