import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, SubscriptionLike } from 'rxjs';
import { PlacementStateService } from 'src/app/placement-state.service';
import { CONFIG } from 'src/helper/app-config';

import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';

@Component({
  selector: 'app-compare-planogram-dialog',
  templateUrl: './compare-planogram-dialog.component.html',
  styleUrls: ['./compare-planogram-dialog.component.css'],
})
export class ComparePlanogramDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ComparePlanogramDialogComponent>,
    private router: Router,
    private placementState: PlacementStateService
  ) {}
  staticData = JSON.parse(
    '[{"id":14,"name":"final1","region":["North"],"channel":["On Premise","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-04 03:21:01.160000","screen_id":null},{"id":15,"name":"final1","region":["North"],"channel":["On Premise","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-04 03:21:15.127000","screen_id":null},{"id":16,"name":"final2","region":["North"],"channel":["On Premise","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-04 03:22:33.430000","screen_id":null},{"id":17,"name":"final2","region":["North"],"channel":["Small Store","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-04 03:28:36.573000","screen_id":null},{"id":18,"name":"final3","region":["North"],"channel":["Small Store","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-04 03:29:08.857000","screen_id":null},{"id":19,"name":"rohan","region":["North"],"channel":["On Premise","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-06 11:47:12.003000","screen_id":null},{"id":20,"name":"rohit","region":["North"],"channel":["On Premise","Large Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-06 13:10:40.083000","screen_id":null},{"id":21,"name":"new_base","region":["North"],"channel":["On Premise","Small Store"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-06 16:35:48.840000","screen_id":null},{"id":22,"name":"pawan","region":["North"],"channel":["On Premise"],"segmentation":"Channel","cooler_type":"IC","status":"Draft","created_on":"2022-06-06 19:55:32.617000","screen_id":null}]'
  );
  planogram_ids = [];
  search_input = '';
  displayedColumns = ['name', 'region', 'channel', 'status'];

  dataSource = new MatTableDataSource();

  displayTableHeaders = ['Scenario Name', 'Region', 'Channel', 'Status'];
  @ViewChild(MatSort) sort: MatSort;
  subscriptionsToUnsubscribe = {};

  allScenarios: Observable<any> = this.placementState.compareScenarios;
  ngOnInit(): void {
    this.dataSource.data = this.staticData;
    this.placementState.getCompareScenarios();
    this.subscriptionsToUnsubscribe['1'] = this.allScenarios.subscribe((o) => {
      this.dataSource.data = o;
    });
  }
  updateFilter = (event: any) => {
    this.dataSource.filter = this.search_input.trim().toLowerCase();
  };
  //
  comparePlanograms() {
    this.dialog.closeAll();
    setTimeout(() => {
      this.router.navigate(['/' + CONFIG.PATH.COMPARISON], {
        queryParams: { ids: [...this.planogram_ids].join(CONFIG.ID_SEPERATOR) },
      });
    });
  }
  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  selectPlanogram = (id: number) => {
    if (this.planogram_ids.includes(id)) {
      this.planogram_ids = this.planogram_ids.filter((p_id) => p_id !== id);
    } else if (this.planogram_ids.length < 3) {
      this.planogram_ids = this.planogram_ids.concat(id);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
        id: 'dialog2',
        height: '200px',
        width: '500px',
        data: {
          header_msg: 'Cannot select more than 3 scenarios.',
          header_msg_description:
            'Please clear existing selection to select other scenarios.',
          ok_action: 'Continue',
        },
      });
    }
  };
}
