<div class="outlet-container">
  <div class="scenario-details">
    <div *ngFor="let scenario of responseData" class="scenario-wrap">
      <div class="name">{{ scenario.name }}</div>
      <div
        style="
          display: grid;
          grid-template-columns: 0.3fr 1fr;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          style="justify-self: center"
          src="../../assets/CDE_Placement_SVGs/Icons/Cooler Icon (1).svg"
          alt=""
        />
        <div class="details">
          <div class="region">
            <span class="subtitle"> Region: </span>
            <span class="value"> {{ scenario.displayRegion }}</span>
          </div>
          <div class="channel">
            <span class="subtitle"> Channel:</span>
            <span class="value">{{ scenario.displayChannel }}</span>
          </div>

          <div class="last-modified">
            <span class="subtitle"> Created on: </span>
            <span class="value">{{ scenario.createdAt }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="plan-summary">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState1 = true"
        (closed)="panelOpenState1 = false"
        [expanded]="panelOpenState1"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            PLAN SUMMARY
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="vertical">
          <div class="dataset-vertical-table">
            <div class="heading-row custom-row">
              <div
                class="left normal-text-montserrat"
                style="font-weight: bold"
              >
                KPIs
              </div>
              <div
                *ngFor="let header of tableHeaders"
                class="right normal-text-montserrat"
                style="font-weight: bold"
              >
                {{ header }}
              </div>
            </div>
            <div style="display: flex">
              <div style="flex: 1">
                <div *ngFor="let row of uniquePlanSummaryKeys; let i = index">
                  <div
                    class="left special-left normal-text-montserrat"
                    style="padding: 8px"
                  >
                    <span>
                      {{ row }}
                    </span>
                  </div>
                </div>
              </div>

              <div style="flex: 1" *ngFor="let data of planSummary">
                <div
                  *ngFor="let val of data['values']"
                  class="right normal-text-montserrat"
                  style="padding: 8px"
                >
                  <!-- {{ row.value }} -->
                  {{ val }}
                </div>
              </div>
            </div>

            <div
              class="custom-row"
              style="
                grid-template-columns: 1fr 1.1fr 1fr;
                grid-column-gap: 0px;
                display: none;
              "
            >
              <div class="left special-left normal-text-montserrat;">
                <span style="opacity: 0.5">
                  <img
                    src="./../../../assets/CDE_Placement_SVGs/Icons/Plus.svg"
                    alt=""
                  />
                  <span class="medium-header-montserrat disabled">
                    Add New KPI
                  </span>
                </span>
              </div>
              <!-- 
              <span class="normal-text-montserrat" style="justify-self: flex-end">
                Total Weightage</span
              >
              <div class="right normal-text-montserrat">
                <input
                  style="text-align: center"
                  disabled
                  [(ngModel)]="totalWeightage"
                  class="vertical-input"
                  type="text"
                />
                %
              </div> -->
              <!-- <span> -->
              <!-- </span> -->
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="execution-impact">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState3 = true"
        (closed)="panelOpenState3 = false"
        [expanded]="panelOpenState3"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            EXECUTION IMPACT
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="vertical">
          <div class="dataset-vertical-table">
            <div class="heading-row custom-row">
              <div
                class="left normal-text-montserrat"
                style="font-weight: bold"
              >
                KPIs
              </div>
              <div
                *ngFor="let header of tableHeaders"
                class="right normal-text-montserrat"
                style="font-weight: bold"
              >
                {{ header }}
              </div>
            </div>

            <div style="display: flex">
              <div style="flex: 1">
                <div
                  *ngFor="let row of uniqueExecutionImpactKeys; let i = index"
                >
                  <div
                    class="left special-left normal-text-montserrat"
                    style="padding: 8px"
                  >
                    <span>
                      {{ row }}
                    </span>
                  </div>
                </div>
              </div>

              <div style="flex: 1" *ngFor="let data of executionImpact">
                <div
                  *ngFor="let val of data['values']"
                  class="right normal-text-montserrat"
                  style="padding: 8px"
                >
                  <!-- {{ row.value }} -->
                  {{ val }}
                </div>
              </div>
            </div>
            <div
              class="custom-row"
              style="
                grid-template-columns: 1fr 1.1fr 1fr;
                grid-column-gap: 0px;
                display: none;
              "
            >
              <div class="left special-left normal-text-montserrat">
                <span style="opacity: 0.5">
                  <img
                    src="./../../../assets/CDE_Placement_SVGs/Icons/Plus.svg"
                    alt=""
                  />
                  <span class="medium-header-montserrat disabled">
                    Add New KPI
                  </span>
                </span>
              </div>
              <!-- 
              <span class="normal-text-montserrat" style="justify-self: flex-end">
                Total Weightage</span
              >
              <div class="right normal-text-montserrat">
                <input
                  style="text-align: center"
                  disabled
                  [(ngModel)]="totalWeightage"
                  class="vertical-input"
                  type="text"
                />
                %
              </div> -->
              <!-- <span> -->
              <!-- </span> -->
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="business-summary">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState2 = true"
        (closed)="panelOpenState2 = false"
        [expanded]="panelOpenState2"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            BUSINESS IMPACT
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="vertical">
          <div class="dataset-vertical-table">
            <div class="heading-row custom-row">
              <div
                class="left normal-text-montserrat"
                style="font-weight: bold"
              >
                KPIs
              </div>
              <div
                *ngFor="let header of tableHeaders"
                class="right normal-text-montserrat"
                style="font-weight: bold"
              >
                {{ header }}
              </div>
            </div>
            <div style="display: flex">
              <div style="flex: 1">
                <div
                  *ngFor="let row of uniqueBusinessImpactKeys; let i = index"
                >
                  <div
                    class="left special-left normal-text-montserrat"
                    style="padding: 8px"
                  >
                    <span>
                      {{ row }}
                    </span>
                  </div>
                </div>
              </div>

              <div style="flex: 1" *ngFor="let data of businessImpact">
                <div
                  *ngFor="let val of data['values']"
                  class="right normal-text-montserrat"
                  style="padding: 8px"
                >
                  <!-- {{ row.value }} -->
                  {{ val }}
                </div>
              </div>
            </div>
            <div
              class="custom-row"
              style="
                grid-template-columns: 1fr 1.1fr 1fr;
                grid-column-gap: 0px;
                display: none;
              "
            >
              <div class="left special-left normal-text-montserrat">
                <span style="opacity: 0.5">
                  <img
                    src="./../../../assets/CDE_Placement_SVGs/Icons/Plus.svg"
                    alt=""
                  />
                  <span class="medium-header-montserrat disabled">
                    Add New KPI
                  </span>
                </span>
              </div>
              <!-- 
              <span class="normal-text-montserrat" style="justify-self: flex-end">
                Total Weightage</span
              >
              <div class="right normal-text-montserrat">
                <input
                  style="text-align: center"
                  disabled
                  [(ngModel)]="totalWeightage"
                  class="vertical-input"
                  type="text"
                />
                %
              </div> -->
              <!-- <span> -->
              <!-- </span> -->
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
