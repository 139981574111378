<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 10.5L8.81802 7.31802L5.63604 10.5"
    stroke="#25D4CF"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
