import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import { Papa } from 'ngx-papaparse';
import { PlacementStateService } from 'src/app/placement-state.service';
import { DataService } from 'src/app/services/data.service';
import { CONFIG } from 'src/helper/app-config';
import {
  createObjectWithChecked,
  extractRoi,
  getCheckedValues,
  getRequiredObj,
  makeFalse,
  mapAndExtractRoi,
  selectAll,
} from 'src/shared/common-methods';
import { STATIC_DATA } from 'src/shared/data-store';

@Component({
  selector: 'app-placement-plan',
  templateUrl: './placement-plan.component.html',
  styleUrls: ['./placement-plan.component.scss'],
})
export class PlacementPlanComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  newCoolersData = [
    {
      name: 'ON PREMISE',
      series: [
        { name: 'Horizontal', value: '4' },
        { name: 'Vertical', value: '60' },
        { name: 'Replacement', value: '60' },
      ],
    },
    {
      name: 'LARGE STORE',
      series: [
        { name: 'Horizontal', value: '10' },
        { name: 'Vertical', value: '20' },
        { name: 'Replacement', value: '30' },
      ],
    },
  ];
  showAll = true;
  newCoolersconfig: any = {
    view: [400, 300],
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showXAxisLabel: true,
    barPadding: 10,
    yAxisLabel: 'Channels',
    showYAxisLabel: true,
    xAxisLabel: 'No. of Coolers',
    colorScheme: {
      domain: ['#5E3FBE', '#5E3FBE', '#5E3FBE'],
    },
    schemeType: 'linear',
  };

  roiChartData = [];
  roiView = [400, 300];
  roiChartConfig: any = {
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showLegend: true,
    showXAxisLabel: true,
    xAxisLabel: 'Revenue Buckets',
    showYAxisLabel: true,
    barPadding: 20,
    yAxisLabel: 'No. of Outlets',
    colorScheme: {
      domain: ['#FE7E85', '#FE7E85', '#FE7E85', '#FE7E85'],
    },
  };

  reasonChartData = [];
  reasonsChartConfig: any = {
    view: [400, 300],
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showLegend: true,
    showXAxisLabel: true,
    xAxisLabel: 'Reasons to place',
    showYAxisLabel: true,
    barPadding: 30,
    yAxisLabel: 'No. of Doors',
    colorScheme: {
      domain: ['#35BC9A', '#35BC9A', '#35BC9A', '#35BC9A'],
    },
  };
  planSummaryData = [];
  planSummaryTotal = 0;
  businessImpactTotal = 23000030;
  executionImpactData = [];

  businessImpactData = [];
  isAggregatedActive = true;
  outletFilters = [];
  outleFilterWidth = '223px';
  aggregatedDataSource = new MatTableDataSource();

  extraColumns = {
    Address: 'Address',
    Customer_Name: 'Customer Name',
    Postal_Code: 'Postal Code',
    Sales_Office: 'Sales Office',
  };

  aggregatedDisplayedColumns = [
    'Customer_Number',
    'channel',
    'current_doors',
    'current_volume_per_door',
    'picos_compliance',
    'added_doors_total',
    'high_sku_range',
    'outlet_density',
    'cde_density',
    'revenue_gr',
  ];
  horizontalColumns = [
    'Customer_Number',
    'channel',
    'current_doors',
    'current_volume_per_door',
    'picos_compliance',
    'added_doors_total',
    'cdeDensityScore',
    'skuRangeScore',
    'revenueGrScore',
    'vpoFlags',
  ];

  headingMappers = {
    channel: 'Channel',
    current_volume_per_door: 'KO Current Volume (UC)',
    added_doors_total: 'Placement Plan',
    market_share: 'Ko Market Share',
    outlet_density: 'VPO',
    revenue_gr: 'Revenue Growth %',
    point_of_interest: 'No of Points of Interest',
    current_doors: 'Current Doors',
    Customer_Number: 'Customer ID',
    picos_compliance: 'PicOs Compliance',
    headroom: 'Headroom',
    high_sku_range: 'High SKU Range',
    skuRangeScore: 'SKU Range',
    cde_density: 'CDE Density',
    cdeDensityScore: 'CDE Density',
    Door_No: 'Current Doors',
    Sum_of_Case_Vol_This: 'KO Current Volume (UC)',
    PicOs_Recommendation: 'PicOs Compliance',
    doorsAdded: 'Placement Plan',
    skuRangeFlags: 'SKU Range',
    profitPerDoorFlags: 'Revenue Per Door',
    revenueGrFlags: 'Revenue Gr%',
    revPerDoorFlags: 'Revenue Per Door',
    CDE_per_10k_Flags: 'CDE Per 10k',
    revenueGrScore: 'Revenue Gr%',
    doorGapFlags: 'Door Gap',
    doorShareFlags: 'Door Share',
    vpoFlags: 'VPO',
    Sum_of_Rev_This: 'KO Current Value ($)',
    revenueGrPercentageFlags: 'Revenue Gr%',
  };

  symbolColumns = {
    // market_share: 'market_share',
    // outlet_density: 'outlet_density',
    // revenue_gr: 'revenue_gr',
    // point_of_interest: 'point_of_interest',
    // headroom: 'headroom',
    // high_sku_range: 'high_sku_range',
    // cde_density: 'cde_density',
    // doorGapFlags: 'doorGapFlags',
    // doorShareFlags: 'doorShareFlags',
    // profitPerDoorFlags: 'profitPerDoorFlags',
    // revenueGrFlags: 'revenueGrFlags',
    // skuRangeFlags: 'skuRangeFlags',
    // CDE_per_10k_Flags: 'CDE_per_10k_Flags',
    // revPerDoorFlags: 'revPerDoorFlags',
    // cdeDensityScore: 'cdeDensityScore',
    // skuRangeScore: 'skuRangeScore',
    // revenueGrScore: 'revenueGrScore',
    // vpoFlags: 'vpoFlags',
    // revenueGrPercentageFlags: 'revenueGrPercentageFlags',
  };

  replacementData = {
    displayedColumns: [
      'Customer_Number',
      'channel',
      'Door_No',
      'Sum_of_Case_Vol_This',
      'doorsAdded',
      'skuRangeFlags',
      'revenueGrFlags',
      'CDE_per_10k_Flags',
      'revPerDoorFlags',
      'vpoFlags',
    ],
  };
  verticalDataSource = new MatTableDataSource();
  verticalData = {
    displayedColumns: [
      'Customer_Number',
      'channel',
      'Door_No',
      'Sum_of_Case_Vol_This',
      'PicOs_Recommendation',
      'doorsAdded',
      'doorGapFlags',
      'skuRangeFlags',
      'CDE_per_10k_Flags',
      'revenueGrPercentageFlags',
      'revPerDoorFlags',
      'vpoFlags',
    ],
  };

  normalColumns = {
    // channel: 'channel',
    // current_doors: 'current_doors',
    // Customer_Number: 'Customer_Number',
    // picos_compliance: 'picos_compliance',
    // added_doors_total: 'added_doors_total',
    // Door_No: 'Door_No',
    // PicOs_Recommendation: 'PicOs_Recommendation',
    // doorsAdded: 'doorsAdded',
  };
  placementPlanData;
  @ViewChildren('filter') filterWidthArr;
  @ViewChildren(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private papa: Papa,
    private dataService: DataService,
    private placementState: PlacementStateService
  ) {
    this.roiChartConfig.view = [innerWidth / 4, 300];
  }
  planSummary;
  businessImpact;
  executionImpact;
  scenarioId;
  offset = 0;
  limit = 20;
  percentageRows = {
    'Compliance Level': 'Compliance Level',
    'Cooler Share': 'Cooler Share',
  };
  formatingColumns = {
    // current_volume_per_door: 'current_volume_per_door',
    // Sum_of_Case_Vol_This: 'Sum_of_Case_Vol_This',
    // 'KO Current Volume (UC)': 'KO Current Volume (UC)',
    // 'KO Current Value ($)': 'KO Current Value ($)',
  };
  subscriptionsToUnsubscribe = {};
  businessImpactMapper = {
    'Inc Profit Door': 'Inc. Revenue Per Door',
  };
  activeType = 'Horizontal';
  outputFilters = cloneDeep(STATIC_DATA.OUTLET_LEVEL.OUTPUT_FILTERS);
  myOptions = {
    placement: 'right',
  };

  persistentColumns = [
    'Placement Type',
    'Customer ID',
    'Channel',
    'Customer Name',
    'Region',
    'Sales Office',
    'Address',
    'Postal Code',
    'KO Current Volume (UC)',
    'KO Current Value ($)',
    'Current Doors',
    'PicOs Compliance',
    'Placement Plan',
  ];

  toolVisualColumns = [
    'Placement Type',
    'Customer ID',
    'Channel',
    'Customer Name',
    'Region',
    'KO Current Volume (UC)',
    'KO Current Value ($)',
    'Current Doors',
    'PicOs Compliance',
    'Placement Plan',
  ];
  formatCols = ['KO Current Volume (UC)', 'KO Current Value ($)'];
  // plus kpis
  csvData = [];
  sticky = true;

  ngOnInit(): void {
    this.mapOutputFilters();
    this.placementState.outputFilterObj.subscribe((obj) => {
      if (Object.keys(obj).length) {
        let filtersData = this.outputFilters.map((outputObj, i) => {
          outputObj['filters'] = obj[outputObj['value']];
          return outputObj;
        });

        this.mapOutputFilters();
        this.outputFilters.map((obj, i) => {
          this.outputFilterChange('', 0, '', obj['filters']);
        });
      }
      this.outputApplyFilters();
    });
    this.subscriptionsToUnsubscribe['1'] =
      this.placementState.scenarioId.subscribe((observer) => {
        this.scenarioId = observer;
      });

    this.placementState.placementPlan.subscribe((data) => {
      this.placementPlanData = data;
      this.planSummaryMapping();
      this.executionTotalMapping();
      this.businessImpactMapping();
      this.mapNewCoolersChart();
    });
    this.placementState.placementPlanReasonsChart.subscribe((data) => {
      this.placementPlanData['reasonsToPlace'] = data;
      this.mapReasonsToPlaceChart();
    });
    this.placementState.placementPlanRoiChart.subscribe((data) => {
      this.placementPlanData['placementPlanRoiChart'] = data;
      this.mapRoiChart();
    });
    this.subscriptionsToUnsubscribe['3'] =
      this.placementState.placementPlanTableData.subscribe((data) => {
        if (data && data.length) {
          let cols = Object.keys(data[0]);
          cols = cols.filter((col) => col != 'scenario_id' && col != 'id');
          this.csvData = cloneDeep(data);
          let kpiCols = this.extractKpiColumns(cols);
          this.aggregatedDisplayedColumns = [
            ...this.toolVisualColumns,
            ...kpiCols,
          ];
          let normalCols = cols.filter(
            (col) => !kpiCols.includes(col) && !this.formatCols.includes(col)
          );
          // this.normalColumns =
          normalCols.forEach((col) => {
            this.normalColumns[col] = col;
          });
          kpiCols.forEach((col) => {
            this.symbolColumns[col] = col;
          });
          this.formatCols.forEach((col) => {
            this.formatingColumns[col] = col;
          });
          this.aggregatedDataSource.data = data;
        }
      });

    this.placementState.populatePlacementPlanTableDropDownData();

    this.subscriptionsToUnsubscribe['4'] =
      this.placementState.outletFilters.subscribe((res) => {
        this.outletFilters = res;
      });

    this.placementState.getPlacementPlanInitialData();
    this.placementState.getPlacementPlanTableData();
  }

  extractKpiColumns(cols) {
    return cols.filter((col) => {
      if (!this.persistentColumns.includes(col)) {
        return col;
      }
    });
  }

  downloadCsv() {
    // let channel = getRequiredObj('channels', this.outletFilters)[0][
    //   'selectedFilters'
    // ].map((val) => val.toUpperCase());
    // let region = getRequiredObj('regions', this.outletFilters)[0][
    //   'selectedFilters'
    // ];
    // let placementType = getRequiredObj('placement_type', this.outletFilters)[0][
    //   'selectedFilters'
    // ][0];

    // let payload = {
    //   scenario_id: this.scenarioId,
    //   channels: channel,
    //   regions: region,
    //   type: placementType,
    // };
    // this.dataService
    //   .postRequest(payload, CONFIG.API.PLACEMENT_PLAN.CSV)
    //   .then((res) => {
    //     let csvData = res['data'];

    //     if (csvData.length) {
    //       let data = cloneDeep(csvData);

    //       let a = document.createElement('a');
    //       a.setAttribute('href', data);
    //       a.setAttribute('target', '_blank');
    //       a.click();
    //     }
    //   });

    let order = [
      'Placement Type',
      'Customer ID',
      'Channel',
      'Customer Name',
      'Region',
      'Sales Office',
      'Address',
      'Postal Code',
      'KO Current Volume (UC)',
      'KO Current Value ($)',
      'Current Doors',
      'PicOs Compliance',
      'Placement Plan',
    ];

    order = [
      ...order,
      ...this.extractKpiColumns(Object.keys(this.aggregatedDataSource.data[0])),
    ];
    order = order.filter((col) => col != 'scenario_id' && col != 'id');
    const config = {
      columns: order,
    };
    this.csvData = this.csvData.map((o) => {
      delete o['id'];
      delete o['scenario_id'];
      return o;
    });
    let result = this.papa.unparse(this.csvData, config);
    console.log(result);
    var blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });

    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Outlet Level Sample.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  convertCoolersChart(originalData) {
    let requiredData = [];
    for (let key1 in originalData) {
      for (let key2 in originalData[key1]) {
        let entry = requiredData.find((entry) => entry.name == key2);
        if (entry) {
          entry.series.push({ name: key1, value: originalData[key1][key2] });
        } else {
          requiredData.push({
            name: key2,
            series: [{ name: key1, value: originalData[key1][key2] }],
          });
        }
      }
    }
    return requiredData;
  }

  widthsArr(i) {
    return () => {
      return this.allFiltersWidth[i];
    };
  }
  allFiltersWidth = [];
  ngAfterViewInit() {
    console.log('init');
    console.log(this.filterWidthArr);
    this.filterWidthArr.changes.subscribe((val) => {
      console.log(val);
      val._results.forEach((element) => {
        console.log('width');
        console.log(element.nativeElement.offsetWidth);
        this.allFiltersWidth.push(element.nativeElement.offsetWidth);
      });
    });
    this.aggregatedDataSource.sort = this.sort;
    this.aggregatedDataSource.paginator = this.paginator;
  }
  addSpace(arr) {
    return arr.map((str, i) => {
      let st;
      if (arr.length === 1) {
        st = str;
      } else if (arr.length === i + 1) {
        st = ' ' + str;
      } else {
        st = ' ' + str;
      }
      return st;
    });
  }
  downArrows() {
    this.outletFilters = this.outletFilters.map((obj) => {
      obj.filterIcon = 'keyboard_arrow_down';
      return obj;
    });
  }
  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
    this.placementState.setStateWithKey('placementObj', {});
    this.placementState.setStateWithKey('initialDataObj', {});
    this.reasonChartData = [];
    this.roiChartData = [];
  }

  checkTableVal(value) {}
  isBool(val) {
    if (typeof val === 'boolean') {
      if (val) {
        return true;
      } else {
        return false;
      }
    }
  }
  ngOnChanges(changes) {
    console.log(changes);
  }
  mapRoiChart() {
    let roiMapper = [' < -50', '-50 - 0', '0 - 50', '50 - 100', '>100'];

    this.roiChartData = this.placementPlanData?.['placementPlanRoiChart'];

    this.roiChartData = this.roiChartData.map((obj) => {
      if (roiMapper.includes(obj.name)) {
        obj.name = roiMapper[roiMapper.indexOf(obj.name)];
      }
      return obj;
    });

    this.roiChartData.sort(function (a, b) {
      return roiMapper.indexOf(a.name) - roiMapper.indexOf(b.name);
    });

    this.roiChartData = this.roiChartData.filter((o) => o.name != '0');
  }

  mapReasonsToPlaceChart() {
    this.reasonChartData = Object.keys(
      this.placementPlanData?.['reasonsToPlace']
    )
      .map((key) => {
        return {
          name: key,
          value: this.placementPlanData?.['reasonsToPlace'][key],
        };
      })
      .filter((o) => {
        if (o.name === 'Outlet Density') {
          o.name = 'VPO';
        }
        return (
          o.name !== 'KO Market Share' &&
          o.name !== 'Headroom' &&
          o.name !== 'Point Of Interest'
        );
      });
  }

  mapNewCoolersChart() {
    if (Object.keys(this.placementPlanData['newCoolers']).length) {
      // this.newCoolersData = this.newCoolersData.map((obj) => {
      //   obj.series = [
      //     {
      //       name: obj.displayValue,
      //       value:
      //         this.placementPlanData?.['newCoolers']?.[obj.displayValue][
      //           obj.name
      //         ],
      //     },
      //   ];
      //   return obj;
      // });
      this.newCoolersData = this.convertCoolersChart(
        this.placementPlanData['newCoolers']
      );
    }
  }
  executionTotalMapping() {
    Object.keys(this.placementPlanData?.['executionImpact']).map((key) => {
      this.placementPlanData.executionImpact[key]['key'] = key;
    });
    this.executionImpactData = Object.keys(
      this.placementPlanData['executionImpact']
    ).map((key) => {
      return this.placementPlanData['executionImpact'][key];
    });
    this.executionImpactData = this.executionImpactData.map((obj) => {
      obj['new'] =
        typeof obj['new'] === 'string'
          ? Number(obj['new'].slice(0, obj['new'].length - 1))
          : obj['new'];

      obj['old'] =
        typeof obj['old'] === 'string'
          ? Number(obj['old'].slice(0, obj['old'].length - 1))
          : obj['old'];

      return obj;
    });
  }
  planSummaryMapping() {
    this.planSummaryData = Object.keys(this.placementPlanData['planSummary'])
      .filter((key) => key !== 'noOfCoolersPlaces')
      .map((key) => {
        let o = {};
        o['key'] = key;
        o['value'] = this.placementPlanData['planSummary'][key];
        return o;
      });
    let totalCoolers = this.planSummaryData.find(
      (obj) => obj.key === 'Total Cooler Placed (1 Door)'
    );
    this.planSummaryData = this.planSummaryData.filter(
      (obj) => obj.key != 'Total Cooler Placed (1 Door)'
    );
    if (totalCoolers) {
      this.planSummaryData = [totalCoolers, ...this.planSummaryData];
    }
    this.planSummaryTotal =
      this.placementPlanData['planSummary']['noOfCoolersPlaces'];
  }
  businessImpactMapping() {
    let incProfitKey = 'Incremental Revenue';
    this.businessImpactData = Object.keys(
      this.placementPlanData?.['businessImpact']
    )
      .filter((key) => key !== incProfitKey)
      .map((key) => {
        let o = {};
        o['key'] = key;
        o['value'] = this.placementPlanData?.['businessImpact'][key];
        o['value'] = Number(o['value']);
        return o;
      })
      .filter(
        (o) =>
          o['key'] != 'Inc Profit Door' &&
          o['key'] != 'buinessImpact' &&
          o['key'] != 'ROI'
      );
    this.businessImpactTotal =
      this.placementPlanData?.['businessImpact'][incProfitKey];
  }
  toggleAggregated() {
    this.isAggregatedActive = !this.isAggregatedActive;
  }
  iconClick(type, key, val, resize?, index?) {
    if (type === 'ageCooler') {
      if (this.outletFilters[index]['filterIcon'] === 'keyboard_arrow_down') {
        this.outletFilters[index]['filterIcon'] = 'keyboard_arrow_up';
      } else {
        this.outletFilters[index]['filterIcon'] = 'keyboard_arrow_down';
      }
      return;
    }
  }
  getDynamicHeaders(val) {
    if (val === 'High Revenue') {
      return 'Profit per Door';
    }
    if (val === 'High Revnue Door') {
      return 'Revenue Growth %';
    }
    return val;
  }
  mapOutputFilters() {
    this.outputFilters = this.outputFilters.map((obj) => {
      obj.filters = createObjectWithChecked(obj.filters);
      return obj;
    });
  }
  filterChange(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      if (arr.length) {
        arr[i].checked = !arr[i].checked;
      }
    }
  }
  outputFilterChange(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      if (arr.length) {
        arr[i].checked = !arr[i].checked;
        arr = makeFalse(arr, i);
      }
    }
  }
  placementFilterChange(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      arr[i].checked = !arr[i].checked;
      arr = makeFalse(arr, i);
    }
  }

  outputApplyFilters() {
    this.outputFilters = this.outputFilters.map((obj) => {
      obj.selectedFilters = getCheckedValues(obj.filters);
      return obj;
    });
    this.trigger?.['_results'].map((obj) => {
      obj.closeMenu();
    });
    let channel = getRequiredObj('channels', this.outputFilters)?.[0]?.[
      'selectedFilters'
    ]?.[0];
    let expansion = getRequiredObj('expansion', this.outputFilters)?.[0]?.[
      'selectedFilters'
    ]?.[0];
    if (channel || expansion) {
      let roi = mapAndExtractRoi(
        this.placementState.initialDataObj,
        channel,
        expansion
      );
      let reasonsToPlace =
        this.placementState.initialDataObj['reason_to_place'][expansion][
          channel
        ];
      this.placementState.setStateWithKey(
        'placementPlanReasonsChart',
        reasonsToPlace
      );
      this.placementState.setStateWithKey('placementPlanRoiChart', roi);
    }

    if (!channel || !expansion) {
      this.placementState.setStateWithKey('placementPlanRoiChart', []);
      this.placementState.setStateWithKey('placementPlanReasonsChart', []);
    }
  }
  clearAll = (arr) => {
    arr.forEach((element) => {
      if (!element.disabled) {
        element.checked = false;
      }
    });
  };

  changeToNumber2(val, key) {
    if (key === 'Compliance Level' || key === 'Cooler Share') {
      return Number(val.substring(0, val.length - 1));
    }
    return val;
  }

  changeToNumber(val, key) {
    if (key === 'Compliance Level' || key === 'Cooler Share') {
      return this.formatingData(Number(val.substring(0, val.length - 1))) + '%';
    }
    return val;
  }
  toNumber(val) {
    return Number(val);
  }
  onResize(event) {
    this.roiChartConfig.view = [event.target.innerWidth / 1.35, 300];
  }
  formatValue(val, column) {
    if (column === 'activity' || column === 'channel') {
      return val;
    }
    return this.formatingData(val);
  }
  formatingData2(n) {
    let k = '';
    let p = 1;
    if (n < 0) {
      n = n * -1;
      p = -1;
      k = '-';
    }
    if (n >= 1000 && n < 1000000) {
      // 1,02,280
      let x = Math.floor(n / 1000);
      let y = Math.round((n % 1000) / 100);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'K';
      } else return parseFloat(k + x).toFixed(1) + 'K';
    } else if (n >= 1000000 && n < 1000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000);
      let y = Math.round((n % 1000000) / 100000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(2) + 'M';
        // return (parseFloat().toFixed(1) + 'M');
      } else return parseFloat(k + x).toFixed(2) + 'M';
    } else if (n >= 1000000000 && n < 1000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000);
      let y = Math.round((n % 1000000000) / 100000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(2) + 'B';
      else return parseFloat(k + x).toFixed(2) + 'B';
    } else if (n >= 1000000000000 && n < 1000000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000000);
      let y = Math.round((n % 1000000000000) / 100000000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(2) + 'T';
      else return parseFloat(k + x).toFixed(2) + 'T';
    } else {
      return (n * p).toFixed(1);
    }
  }
  formatingData(n) {
    if (typeof n == 'string') {
      return n;
    }
    let k = '';
    let p = 1;
    if (n < 0) {
      n = n * -1;
      p = -1;
      k = '-';
    }
    if (n >= 1000 && n < 1000000) {
      // 1,02,280
      let x = Math.floor(n / 1000);
      let y = Math.round((n % 1000) / 100);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'K';
      } else return parseFloat(k + x).toFixed(1) + 'K';
    } else if (n >= 1000000 && n < 1000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000);
      let y = Math.round((n % 1000000) / 100000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'M';
        // return (parseFloat().toFixed(1) + 'M');
      } else return parseFloat(k + x).toFixed(1) + 'M';
    } else if (n >= 1000000000 && n < 1000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000);
      let y = Math.round((n % 1000000000) / 100000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'B';
      else return parseFloat(k + x).toFixed(1) + 'B';
    } else if (n >= 1000000000000 && n < 1000000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000000);
      let y = Math.round((n % 1000000000000) / 100000000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'T';
      else return parseFloat(k + x).toFixed(1) + 'T';
    } else {
      return (n * p).toFixed(1);
    }
  }
}
