export const environment = {
  production: true,
  environmentName: 'dev',
  client_id: '41e36eb2-dc62-40df-a350-ed455d360324',
  tenant_autority:
    'https://login.microsoftonline.com/77184d99-6065-42b9-9452-8b2b70385193',
  redirect_url:
    'https://ko-cde-dev-placement-in-frontend.azurewebsites.net/auth-response',

  api_base_url: 'https://rgm-placement-be.azurewebsites.net/',
  hubble_dashboard_url:"https://hubble.decisionpoint.in/",
  hubble_dashboard_backend_url:"https://hubble-backend-poc.azurewebsites.net/api/"
};
