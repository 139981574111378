import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlacementStateService } from '../placement-state.service';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  mode = 'new';
  fileName = '';
  scenarioDetails;
  constructor(
    private http: HttpClient,
    private route: Router,
    private _snackBar: MatSnackBar,
    private injector: Injector
  ) {}
  getRequest(url) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let options = {
      headers: headers,
      responseType: 'text' as 'text',
    };
    return this.http
      .get(environment.api_base_url + url, options)
      .toPromise()
      .catch(this.handleError.bind(this))
      .then(this.handleEditError.bind(this));
  }

  postRequest(payload, url) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post(environment.api_base_url + url, payload, options)
      .toPromise()
      .catch(this.handleError.bind(this))
      .then(this.handleEditError.bind(this));
  }

  postRequest2(payload, url) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post(environment.api_base_url + url, payload, options)
      .toPromise();
    // .catch(this.handleError.bind(this))
    // .then(this.handleEditError.bind(this));
  }

  putRequest(payload, url) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http
      .put(environment.api_base_url + url, payload, options)
      .toPromise()
      .catch(this.handleError.bind(this))
      .then(this.handleEditError.bind(this));
  }

  handleError() {
    this._snackBar.open('some thing went wrong', null);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 6000);
  }

  deleteRequest(payload, url) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
      body: { ...payload },
    };
    return this.http.delete(environment.api_base_url + url, options);
    // .toPromise()
    // .then(this.handleEditError)
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  tokenValidation(hubble_access_token) {
    var baseUrl = environment.hubble_dashboard_backend_url + "/usersDetails";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': hubble_access_token,
    });
    let options = { headers: headers };
    return this.http.get(baseUrl, options).toPromise();
  }

  handleEditError(res) {
    // res.code = 501;
    if (res.code == 400 || res.code == 500) {
      let text =
        res.data === 'Cannot Modify Scenario Created by Other User'
          ? res.data
          : 'Some thing went wrong';
      this.openSnackBar(text);
      setTimeout(() => {
        this.closeSnackBar();
      }, 6000);
      let placementState = this.injector.get(PlacementStateService);
      placementState.removeLoader();
      throw Error(res.data);
      // return;
    }
    return new Promise((resolve, reject) => {
      resolve(res);
    }).catch((err) => {
      this._snackBar.open('some thing went wrong', null);
      setTimeout(() => {
        this._snackBar.dismiss();
      }, 6000);
    });
  }
  openSnackBar(text) {
    this._snackBar.open(text, 'Close');
  }
  closeSnackBar() {
    this._snackBar.dismiss();
  }

  getMode() {
    return this.mode;
  }
  setMode(val) {
    this.mode = val;
  }
  getScenarioDetailsForDraft() {
    return this.scenarioDetails;
  }
  setScenarioDetailsForDraft(row) {
    this.scenarioDetails = row;
  }
  setFilename(val) {
    this.fileName = val;
  }
  getFileName() {
    return this.fileName;
  }
}
