<mat-dialog-content id="compare-planogram-dialog-content">
  <div class="header">
    <div>
      <span>Add To compare</span>
      <mat-form-field appearance="fill" class="compare-planogram-search-input">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          type="text"
          [(ngModel)]="search_input"
          placeholder="Search Placement"
          (ngModelChange)="updateFilter($event)"
        />
        <!-- <i class="bi bi-funnel"></i> -->
      </mat-form-field>
    </div>

    <div class="buttons">
      <button
        mat-raised-button
        color="warn"
        [disabled]="planogram_ids.length < 2"
        (click)="comparePlanograms()"
      >
        <i class="bi bi-check-circle-fill"></i>
        Compare Now
      </button>
      <button
        mat-icon-button
        class="close-button"
        disableRipple
        [mat-dialog-close]="true"
      >
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="warning">
    Note: For Comparison Maximum 3 Scenarios will be selected.
  </div>

  <div style="width: 100%; height: calc(100% - 100px); overflow: auto">
    <!-- <ng-template #mytable let-planograms="data_source" [ngTemplateOutlet]="mytable"
            [ngTemplateOutletContext]="{data_source: getDataSource()}"> -->
    <mat-table
      style="max-height: 65vh; overflow: scroll"
      [dataSource]="dataSource"
      matSort
    >
      <!-- ID Column -->

      <ng-container
        *ngFor="let column of displayedColumns; let i = index"
        [cdkColumnDef]="column"
      >
        <mat-header-cell
          [ngStyle]="{
            flex:
              column == 'name'
                ? 2
                : column == 'channel' || column == 'region'
                ? 2.8
                : 0.7
          }"
          mat-sort-header
          *cdkHeaderCellDef
          >{{ displayTableHeaders[i] }}</mat-header-cell
        >
        <mat-cell
          [ngStyle]="{
            flex:
              column == 'name'
                ? 2
                : column == 'channel' || column == 'region'
                ? 2.8
                : 0.7
          }"
          *cdkCellDef="let row"
          style="word-break: break-all"
        >
          <ng-container *ngIf="column !== 'status'">
            <ng-container *ngIf="column === 'name'">
              <i
                [ngClass]="{
                  'bi-check-square text-danger': planogram_ids.includes(row.id),
                  'bi-square default-color': !planogram_ids.includes(row.id)
                }"
                class="bi"
                (click)="$event.stopPropagation(); selectPlanogram(row.id)"
              ></i>
              <span style="margin-left: 5px">
                {{ row[column] }}
              </span>
            </ng-container>

            <ng-container *ngIf="column !== 'name'">
              {{ row[column] }}
            </ng-container>
          </ng-container>

          <ng-container>
            <span class="inactive_status" *ngIf="row[column] === 'Draft'">
              {{ row[column] }}
            </span>
            <span class="active_status" *ngIf="row[column] === 'Active'">
              {{ row[column] }}
            </span>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-cell *cdkHeaderCellDef>Actions</mat-header-cell>
      <mat-header-row
        style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        style="cursor: pointer"
        *matRowDef="let row; columns: displayedColumns"
      >
      </mat-row>
    </mat-table>
  </div>

  <!-- <app-loader [is_show_loader]="(is_show_loader | async)!" ></app-loader> -->
</mat-dialog-content>
