function getCommonPath() {
  return 'api/v1/';
}
export const CONFIG = {
  ID_SEPERATOR: '--',
  CMTOPIXCEL: 37.8,
  CMTOPIXCELHALF__: 18.9,
  CMTOPIXCELHALF___: 9.4,
  CMTOPIXCELHALF____: 4.7,
  CMTOPIXCELHALF: 4.7,
  NUMBER_SUFFIX: ['', 'K', 'M', 'B', 'T'],
  DECIMALPLACE: 1,
  PATH: {
    PLANOGRAMS: 'planograms',
    PUBLISHED: 'published',
    DRAFT: 'drafts',
    CPCMASTER: '',
    NEWPLANOGRAM: 'new-planogram',
    PLANOGRAM: 'planogram/',
    COMPAREPLANOGRAM: 'compare-planograms',
    AUTHRESPONSE: 'auth-response',
    // used
    LOGIN: 'login',
    EMPTY: '',
    SCENARIO_LIBRARY: 'scenario-library',
    PLACEMENT_BASE: 'placement-base/',
    COMPARISON: 'comparison',
  },
  API: {
    DOWNLOAD_CSV: `${getCommonPath()}scenario/get_purchase_plan/`,
    SAVEUSERTOKEN: `${getCommonPath()}ko_plan/users/save_token/`,
    GETALLBASESCENARIOS: `${getCommonPath()}scenario/list`,
    GET_COMPARE_SCENARIOS: `${getCommonPath()}scenario/active_comparison_screen/`,
    COMPARE: {
      COMPARISON: `${getCommonPath()}scenario/comparison_screen/`,
    },
    COOLER_PURCHASE: {
      DROPDOWN_DATA: `${getCommonPath()}scenario/dropdown_data/`,
      UNIQUENESS: `${getCommonPath()}scenario/uniqueness/`,
      SAVE: `${getCommonPath()}scenario/save/`,
      GET_REGIONS: `${getCommonPath()}scenario/get_regions/`,
      GET_CHANNELS: `${getCommonPath()}scenario/get_channels/`,
    },
    OUTLET_POTENTIAL: {
      POPULATE: `${getCommonPath()}kpi/populate_outlet_potential/`,
      OUTLET: `${getCommonPath()}kpi/outlet_potential/`,
      DRAFT: `${getCommonPath()}kpi/get_kpi_data/`,
    },
    COOLER_PLACMENT: {
      PLACEMENT: `${getCommonPath()}scenario/coolar_placement/`,
      SAVE: `${getCommonPath()}scenario/save_cooler_placement_scenarioInfo/`,
      CALC: `${getCommonPath()}scenario/initiate_calc/`,
      AGE: `${getCommonPath()}scenario/select_cooler_age/`,
    },
    PLACEMENT_PLAN: {
      PLAN: `${getCommonPath()}scenario/getPlacementPlanOutputData/`,
      TABLE: `${getCommonPath()}scenario/placement_plan_table_data/`,
      DROPDWON: `${getCommonPath()}scenario/populat_placement_plan_table_dropdown/`,
      CSV: `${getCommonPath()}scenario/get_output_screen_data/`,
    },
    ACTIVE: `${getCommonPath()}scenario/active_plan`,
    DELETE: `${getCommonPath()}scenario/delete_plan/`,
  },
  PLANOGRAMSTATUS: { ACTIVE: 'Active', INACTIVE: 'Inactive', DRAFT: 'Draft' },
  COOLERFACINGMODE: {
    BATCH: 'batch',
    INDIVIDUAL: 'individual',
  },
  COOLERTRAFFICDIRECTIONS: ['Left', 'Right'],
  PLACEMENT_BASE_TABS: {
    COOLER_PURCHASE: {
      displayName: 'Cooler Purchase Plan',
      value: 'coolerPurchasePlan',
    },
    OUTLET: {
      displayName: 'Outlet Potential',
      value: 'outletPotential',
    },
    COOLER_PLACEMENT: {
      displayName: 'Cooler Placement',
      value: 'coolerPlacement',
    },
    PLACEMENT_PLAN: {
      displayName: 'Placement Plan',
      value: 'placementPlan',
    },
  },
  TABS: [
    'Cooler Purchase Plan',
    'Outlet Potential',
    'Cooler Placement',
    'Placement Plan',
  ],
  LIMIT: 20,
};
