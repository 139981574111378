<app-loader [is_show_loader]="showLoader | async"></app-loader>
<div class="scenario-library-wrapper">
  <div class="all-scenarios common-block">
    <div class="table-wrapper">
      <div class="table-controls">
        <div
          style="
            width: 100%;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            display: grid;
            height: calc(100%);
          "
        >
          <mat-form-field
            style="width: 250px"
            appearance="fill"
            class="planogram-search-input"
          >
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              type="text"
              [(ngModel)]="search_input"
              placeholder="Search Placement"
              (ngModelChange)="updateFilter($event)"
            />
          </mat-form-field>
          <div class="box">
            <button
              (click)="redirectToPlacementBase()"
              mat-stroked-button
              style="color: #25d4cf"
            >
              <i class="bi bi-plus-circle-fill"></i>
              Create Scenario
            </button>
            <button
              (click)="openComparePlanogramDialog()"
              mat-stroked-button
              style="color: #25d4cf"
            >
              <i class="bi bi-eye"></i>
              Compare Scenario
            </button>
          </div>
        </div>
      </div>
      <div class="table-body">
        <mat-table
          style="max-height: 65vh; overflow: scroll"
          [dataSource]="dataSource"
          matSort
        >
          <ng-container
            *ngFor="let column of displayedColumns; let i = index"
            [cdkColumnDef]="column"
          >
            <ng-container *ngIf="column != 'Actions'">
              <mat-header-cell
                [ngStyle]="{
                  flex:
                    column == 'name'
                      ? 2
                      : column == 'channel' || column == 'region2'
                      ? 3
                      : column === 'created_by'
                      ? 1.4
                      : 0.8
                }"
                mat-sort-header
                *cdkHeaderCellDef
                >{{ displayTableHeaders[i] }}</mat-header-cell
              >
              <mat-cell
                [ngStyle]="{
                  flex:
                    column == 'name'
                      ? 2
                      : column == 'channel' || column == 'region2'
                      ? 3
                      : column === 'created_by'
                      ? 1.4
                      : 0.8
                }"
                *cdkCellDef="let row"
                style="word-break: break-all"
              >
                <ng-container
                  *ngIf="
                    column !== 'Actions' &&
                    column !== 'status' &&
                    column !== 'region2'
                  "
                >
                  {{ row[column] }}
                </ng-container>
                <ng-container *ngIf="column === 'region2'">
                  {{ addSpace(row[column]) }}
                </ng-container>
                <ng-container>
                  <span class="inactive_status" *ngIf="row[column] === 'Draft'">
                    {{ row[column] }}
                  </span>
                  <span class="active_status" *ngIf="row[column] === 'Active'">
                    {{ row[column] }}
                  </span>
                </ng-container>

                <ng-container *ngIf="column === 'Actions'">
                  <td
                    [ngClass]="
                      row['can_edit'] ? 'action-link' : 'action-link-disabled'
                    "
                  >
                    <i
                      (click)="stopBubbling($event, row)"
                      class="bi bi-pencil"
                      style="margin-right: 10px"
                    ></i>

                    <i
                      class="bi bi-trash"
                      style="cursor: pointer"
                      (click)="deleteScenario($event, row)"
                    ></i>
                  </td>
                  <!-- <button

                    class="common-btn"
                    (click)="stopBubbling($event, row)"
                  >
                    <mat-icon>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.6271 1.12853C11.6678 1.08779 11.716 1.05546 11.7692 1.03341C11.8223 1.01135 11.8793 1 11.9369 1C11.9944 1 12.0514 1.01135 12.1045 1.03341C12.1577 1.05546 12.206 1.08779 12.2466 1.12853L14.8715 3.75341C14.9122 3.79405 14.9445 3.84233 14.9666 3.89548C14.9886 3.94863 15 4.0056 15 4.06315C15 4.12069 14.9886 4.17767 14.9666 4.23082C14.9445 4.28397 14.9122 4.33225 14.8715 4.37288L6.12186 13.1225C6.07987 13.1642 6.02986 13.1969 5.97486 13.2187L1.60005 14.9687C1.52055 15.0005 1.43346 15.0083 1.34957 14.9911C1.26568 14.9739 1.18869 14.9324 1.12814 14.8719C1.06758 14.8113 1.02613 14.7343 1.00892 14.6504C0.991716 14.5665 0.999506 14.4794 1.03133 14.3999L2.78125 10.0251C2.80306 9.97013 2.83581 9.92013 2.8775 9.87814L11.6271 1.12853V1.12853ZM10.8055 3.18819L12.8118 5.19447L13.9431 4.06315L11.9369 2.05686L10.8055 3.18819ZM12.1932 5.81307L10.1869 3.80678L4.49968 9.49403V9.7504H4.93716C5.05319 9.7504 5.16446 9.79649 5.2465 9.87853C5.32855 9.96058 5.37464 10.0719 5.37464 10.1879V10.6254H5.81212C5.92815 10.6254 6.03942 10.6715 6.12147 10.7535C6.20351 10.8355 6.2496 10.9468 6.2496 11.0628V11.5003H6.50596L12.1932 5.81307V5.81307ZM3.65271 10.341L3.55997 10.4337L2.22303 13.777L5.56626 12.44L5.659 12.3473C5.57555 12.3161 5.5036 12.2602 5.45279 12.187C5.40197 12.1138 5.37471 12.0269 5.37464 11.9378V11.5003H4.93716C4.82113 11.5003 4.70986 11.4542 4.62781 11.3722C4.54577 11.2901 4.49968 11.1789 4.49968 11.0628V10.6254H4.0622C3.97311 10.6253 3.88616 10.598 3.81299 10.5472C3.73981 10.4964 3.6839 10.4245 3.65271 10.341V10.341Z"
                          fill="#25D4CF"
                        />
                      </svg>
                    </mat-icon>
                  </button> -->

                  <!-- <mat-icon
                    style="cursor: pointer"
                    (click)="deleteScenario($event, row)"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.077 7.23047C7.179 7.23047 7.27683 7.27099 7.34896 7.34312C7.42109 7.41525 7.46161 7.51308 7.46161 7.61508V12.2305C7.46161 12.3325 7.42109 12.4303 7.34896 12.5024C7.27683 12.5746 7.179 12.6151 7.077 12.6151C6.97499 12.6151 6.87716 12.5746 6.80503 12.5024C6.7329 12.4303 6.69238 12.3325 6.69238 12.2305V7.61508C6.69238 7.51308 6.7329 7.41525 6.80503 7.34312C6.87716 7.27099 6.97499 7.23047 7.077 7.23047ZM9.00008 7.23047C9.10208 7.23047 9.19991 7.27099 9.27204 7.34312C9.34417 7.41525 9.38469 7.51308 9.38469 7.61508V12.2305C9.38469 12.3325 9.34417 12.4303 9.27204 12.5024C9.19991 12.5746 9.10208 12.6151 9.00008 12.6151C8.89807 12.6151 8.80024 12.5746 8.72811 12.5024C8.65598 12.4303 8.61546 12.3325 8.61546 12.2305V7.61508C8.61546 7.51308 8.65598 7.41525 8.72811 7.34312C8.80024 7.27099 8.89807 7.23047 9.00008 7.23047ZM11.3078 7.61508C11.3078 7.51308 11.2672 7.41525 11.1951 7.34312C11.123 7.27099 11.0252 7.23047 10.9232 7.23047C10.8211 7.23047 10.7233 7.27099 10.6512 7.34312C10.5791 7.41525 10.5385 7.51308 10.5385 7.61508V12.2305C10.5385 12.3325 10.5791 12.4303 10.6512 12.5024C10.7233 12.5746 10.8211 12.6151 10.9232 12.6151C11.0252 12.6151 11.123 12.5746 11.1951 12.5024C11.2672 12.4303 11.3078 12.3325 11.3078 12.2305V7.61508Z"
                        fill="#25D4CF"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 5.30769C14 5.51171 13.919 5.70736 13.7747 5.85162C13.6304 5.99588 13.4348 6.07692 13.2308 6.07692H12.8462V13C12.8462 13.408 12.6841 13.7993 12.3955 14.0879C12.107 14.3764 11.7157 14.5385 11.3077 14.5385H6.69231C6.28428 14.5385 5.89297 14.3764 5.60445 14.0879C5.31593 13.7993 5.15385 13.408 5.15385 13V6.07692H4.76923C4.56522 6.07692 4.36956 5.99588 4.2253 5.85162C4.08104 5.70736 4 5.51171 4 5.30769V4.53846C4 4.33445 4.08104 4.13879 4.2253 3.99453C4.36956 3.85027 4.56522 3.76923 4.76923 3.76923H7.46154C7.46154 3.56522 7.54258 3.36956 7.68684 3.2253C7.8311 3.08104 8.02676 3 8.23077 3H9.76923C9.97324 3 10.1689 3.08104 10.3132 3.2253C10.4574 3.36956 10.5385 3.56522 10.5385 3.76923H13.2308C13.4348 3.76923 13.6304 3.85027 13.7747 3.99453C13.919 4.13879 14 4.33445 14 4.53846V5.30769ZM6.01385 6.07692L5.92308 6.12231V13C5.92308 13.204 6.00412 13.3997 6.14838 13.5439C6.29264 13.6882 6.4883 13.7692 6.69231 13.7692H11.3077C11.5117 13.7692 11.7074 13.6882 11.8516 13.5439C11.9959 13.3997 12.0769 13.204 12.0769 13V6.12231L11.9862 6.07692H6.01385ZM4.76923 5.30769V4.53846H13.2308V5.30769H4.76923Z"
                        fill="#25D4CF"
                      />
                    </svg>
                  </mat-icon> -->
                </ng-container>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column == 'Actions'">
              <mat-header-cell
                [ngStyle]="{
                  flex:
                    column == 'name'
                      ? 2
                      : column == 'channel' || column == 'region2'
                      ? 3
                      : column === 'created_by'
                      ? 1.4
                      : 0.8
                }"
                *cdkHeaderCellDef
                >{{ displayTableHeaders[i] }}</mat-header-cell
              >
              <mat-cell
                [ngStyle]="{
                  flex:
                    column == 'name'
                      ? 2
                      : column == 'channel' || column == 'region2'
                      ? 3
                      : column === 'created_by'
                      ? 1.4
                      : 0.8
                }"
                *cdkCellDef="let row"
                style="word-break: break-all"
              >
                <ng-container
                  *ngIf="
                    column !== 'Actions' &&
                    column !== 'status' &&
                    column !== 'region2'
                  "
                >
                  {{ row[column] }}
                </ng-container>
                <ng-container *ngIf="column === 'region2'">
                  {{ addSpace(row[column]) }}
                </ng-container>
                <ng-container>
                  <span class="inactive_status" *ngIf="row[column] === 'Draft'">
                    {{ row[column] }}
                  </span>
                  <span class="active_status" *ngIf="row[column] === 'Active'">
                    {{ row[column] }}
                  </span>
                </ng-container>

                <ng-container *ngIf="column === 'Actions'">
                  <!-- <button
                  style="border: none; margin-top: 8px; opacity: 3px"
                  disabled
                >
                  <mat-icon style="color: red; font-size: 20px">
                    visibility
                  </mat-icon>
                </button> -->

                  <!-- <button
                    class="common-btn"
                    (click)="stopBubbling($event, row)"
                  >
                    <mat-icon>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.6271 1.12853C11.6678 1.08779 11.716 1.05546 11.7692 1.03341C11.8223 1.01135 11.8793 1 11.9369 1C11.9944 1 12.0514 1.01135 12.1045 1.03341C12.1577 1.05546 12.206 1.08779 12.2466 1.12853L14.8715 3.75341C14.9122 3.79405 14.9445 3.84233 14.9666 3.89548C14.9886 3.94863 15 4.0056 15 4.06315C15 4.12069 14.9886 4.17767 14.9666 4.23082C14.9445 4.28397 14.9122 4.33225 14.8715 4.37288L6.12186 13.1225C6.07987 13.1642 6.02986 13.1969 5.97486 13.2187L1.60005 14.9687C1.52055 15.0005 1.43346 15.0083 1.34957 14.9911C1.26568 14.9739 1.18869 14.9324 1.12814 14.8719C1.06758 14.8113 1.02613 14.7343 1.00892 14.6504C0.991716 14.5665 0.999506 14.4794 1.03133 14.3999L2.78125 10.0251C2.80306 9.97013 2.83581 9.92013 2.8775 9.87814L11.6271 1.12853V1.12853ZM10.8055 3.18819L12.8118 5.19447L13.9431 4.06315L11.9369 2.05686L10.8055 3.18819ZM12.1932 5.81307L10.1869 3.80678L4.49968 9.49403V9.7504H4.93716C5.05319 9.7504 5.16446 9.79649 5.2465 9.87853C5.32855 9.96058 5.37464 10.0719 5.37464 10.1879V10.6254H5.81212C5.92815 10.6254 6.03942 10.6715 6.12147 10.7535C6.20351 10.8355 6.2496 10.9468 6.2496 11.0628V11.5003H6.50596L12.1932 5.81307V5.81307ZM3.65271 10.341L3.55997 10.4337L2.22303 13.777L5.56626 12.44L5.659 12.3473C5.57555 12.3161 5.5036 12.2602 5.45279 12.187C5.40197 12.1138 5.37471 12.0269 5.37464 11.9378V11.5003H4.93716C4.82113 11.5003 4.70986 11.4542 4.62781 11.3722C4.54577 11.2901 4.49968 11.1789 4.49968 11.0628V10.6254H4.0622C3.97311 10.6253 3.88616 10.598 3.81299 10.5472C3.73981 10.4964 3.6839 10.4245 3.65271 10.341V10.341Z"
                          fill="#25D4CF"
                        />
                      </svg>
                    </mat-icon>
                  </button> -->
                  <td
                    [ngClass]="
                      row['can_edit'] ? 'action-link' : 'action-link-disabled'
                    "
                  >
                    <i
                      (click)="stopBubbling($event, row)"
                      class="bi bi-pencil"
                      style="margin-right: 10px"
                    ></i>

                    <i
                      class="bi bi-trash"
                      style="cursor: pointer"
                      (click)="deleteScenario($event, row)"
                    ></i>
                  </td>
                  <!-- <mat-icon
                    style="cursor: pointer"
                    (click)="deleteScenario($event, row)"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.077 7.23047C7.179 7.23047 7.27683 7.27099 7.34896 7.34312C7.42109 7.41525 7.46161 7.51308 7.46161 7.61508V12.2305C7.46161 12.3325 7.42109 12.4303 7.34896 12.5024C7.27683 12.5746 7.179 12.6151 7.077 12.6151C6.97499 12.6151 6.87716 12.5746 6.80503 12.5024C6.7329 12.4303 6.69238 12.3325 6.69238 12.2305V7.61508C6.69238 7.51308 6.7329 7.41525 6.80503 7.34312C6.87716 7.27099 6.97499 7.23047 7.077 7.23047ZM9.00008 7.23047C9.10208 7.23047 9.19991 7.27099 9.27204 7.34312C9.34417 7.41525 9.38469 7.51308 9.38469 7.61508V12.2305C9.38469 12.3325 9.34417 12.4303 9.27204 12.5024C9.19991 12.5746 9.10208 12.6151 9.00008 12.6151C8.89807 12.6151 8.80024 12.5746 8.72811 12.5024C8.65598 12.4303 8.61546 12.3325 8.61546 12.2305V7.61508C8.61546 7.51308 8.65598 7.41525 8.72811 7.34312C8.80024 7.27099 8.89807 7.23047 9.00008 7.23047ZM11.3078 7.61508C11.3078 7.51308 11.2672 7.41525 11.1951 7.34312C11.123 7.27099 11.0252 7.23047 10.9232 7.23047C10.8211 7.23047 10.7233 7.27099 10.6512 7.34312C10.5791 7.41525 10.5385 7.51308 10.5385 7.61508V12.2305C10.5385 12.3325 10.5791 12.4303 10.6512 12.5024C10.7233 12.5746 10.8211 12.6151 10.9232 12.6151C11.0252 12.6151 11.123 12.5746 11.1951 12.5024C11.2672 12.4303 11.3078 12.3325 11.3078 12.2305V7.61508Z"
                        fill="#25D4CF"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 5.30769C14 5.51171 13.919 5.70736 13.7747 5.85162C13.6304 5.99588 13.4348 6.07692 13.2308 6.07692H12.8462V13C12.8462 13.408 12.6841 13.7993 12.3955 14.0879C12.107 14.3764 11.7157 14.5385 11.3077 14.5385H6.69231C6.28428 14.5385 5.89297 14.3764 5.60445 14.0879C5.31593 13.7993 5.15385 13.408 5.15385 13V6.07692H4.76923C4.56522 6.07692 4.36956 5.99588 4.2253 5.85162C4.08104 5.70736 4 5.51171 4 5.30769V4.53846C4 4.33445 4.08104 4.13879 4.2253 3.99453C4.36956 3.85027 4.56522 3.76923 4.76923 3.76923H7.46154C7.46154 3.56522 7.54258 3.36956 7.68684 3.2253C7.8311 3.08104 8.02676 3 8.23077 3H9.76923C9.97324 3 10.1689 3.08104 10.3132 3.2253C10.4574 3.36956 10.5385 3.56522 10.5385 3.76923H13.2308C13.4348 3.76923 13.6304 3.85027 13.7747 3.99453C13.919 4.13879 14 4.33445 14 4.53846V5.30769ZM6.01385 6.07692L5.92308 6.12231V13C5.92308 13.204 6.00412 13.3997 6.14838 13.5439C6.29264 13.6882 6.4883 13.7692 6.69231 13.7692H11.3077C11.5117 13.7692 11.7074 13.6882 11.8516 13.5439C11.9959 13.3997 12.0769 13.204 12.0769 13V6.12231L11.9862 6.07692H6.01385ZM4.76923 5.30769V4.53846H13.2308V5.30769H4.76923Z"
                        #fill="#25D4CF"
                      />
                    </svg>
                  </mat-icon> -->
                </ng-container>
              </mat-cell>
            </ng-container>
          </ng-container>

          <mat-header-cell *cdkHeaderCellDef>Actions</mat-header-cell>
          <mat-header-row
            style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            style="cursor: pointer"
            (click)="redirectToDraftState(row)"
            *matRowDef="let row; columns: displayedColumns"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          (page)="paginationChange($event)"
          #paginator
          [hidden]="dataSource.data.length === 0"
          [pageSize]="limit / 2"
          hidePageSize="true"
          showFirstLastButtons="false"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
