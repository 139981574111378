import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-menu-buttons',
  templateUrl: './menu-buttons.component.html',
  styleUrls: ['./menu-buttons.component.scss'],
  exportAs: 'menuInOtherComponent',
})
export class MenuButtonsComponent implements OnInit {
  constructor() {}
  @ViewChildren(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  ngOnInit(): void {
    this.mapper;
  }
  @Input() dropDownValues;
  @Input() changeFunc;
  @Input() downFunc;
  @Input() applyFilters;
  @Input() clearAll;
  @Input() customWidth;
  @Input() mapper;
  @Input() name;
  @Input() showAll;

  backgroundColor = 'accent';
  getValue(val) {
    return this.mapper ? this.mapper[val] : val;
  }

  closeModal() {
    this.trigger['_results'].map((obj) => {
      obj.closeMenu();
    });
  }
  allChecked(arr) {
    let ans = arr
      .filter((obj) => {
        return !obj.disabled;
      })
      .filter((obj) => {
        return !obj.checked;
      });
    return ans.length ? false : true;
  }
}
