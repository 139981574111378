import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';

import { CONFIG } from 'src/helper/app-config';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss'],
})
export class ComparisonComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) {}
  data = [
    {
      name: 'scenario1',
      kpis: {
        kpi1: 3,
        kpi2: 3,
        kpi3: 3,
        kpi4: 3,
      },
    },
    {
      name: 'scenario2',
      kpis: {
        kpi1: 3,
        kpi2: 3,
        kpi3: 3,
        kpi4: 3,
      },
    },
    {
      name: 'scenario3',
      kpis: {
        kpi1: 3,
        kpi2: 3,
        kpi3: 3,
        kpi4: 3,
      },
    },
  ];
  scenarios = [1, 2, 3];
  vertical = [
    {
      kpi: 'Door Gap',
      info: 'Recommended Doors per store – Actual Doors per store',
      id: 5,
      type: 'Vertical Expansion',
      status: 0,
    },
    {
      kpi: 'Profit Per Door',
      info: 'Profit generated from one Door',
      id: 6,
      type: 'Vertical Expansion',
      status: 0,
    },
    {
      kpi: 'SKU Range',
      info: 'Nos of unique SKUs at the outlet',
      id: 7,
      type: 'Vertical Expansion',
      status: 0,
    },
  ];
  tableHeaders = [];
  kpis;
  tableData;
  kpiIds;
  kpiData;
  uniqueKpis = [];
  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;

  uniqueBusinessImpactKeys = [];
  uniquePlanSummaryKeys = [];
  uniqueExecutionImpactKeys = [];

  businessImpact = [];
  planSummary = [];
  executionImpact = [];
  responseData = [];
  ngOnInit(): void {
    // this.tableHeaders = this.data.map((o) => o.name).join;
    this.kpis = Object.keys(this.data[0].kpis);
    this.tableData = this.kpis.map((kpi) => {});

    this.route.queryParams.subscribe((queryParams) => {
      console.log(queryParams?.ids.split(CONFIG.ID_SEPERATOR));
      this.kpiIds = queryParams?.ids.split(CONFIG.ID_SEPERATOR);
      this.getData();
    });
  }

  getData() {
    let payload = {
      scenario_id_list: this.kpiIds,
    };
    this.dataService
      .postRequest(payload, CONFIG.API.COMPARE.COMPARISON)
      .then((res: any) => {
        this.responseData = cloneDeep(res.data);

        this.responseData = this.responseData.map((obj) => {
          delete obj['planSummary']['noOfCoolersPlaces'];
          return obj;
        });

        this.responseData = this.responseData.map((obj) => {
          delete obj['businessImpact']['Inc Profit Door'];
          return obj;
        });

        this.responseData = this.responseData.map((obj) => {
          obj.displayChannel = obj.channel.map((o) => o.channel).join(', ');
          obj.displayRegion = obj.region.map((o) => o.regions).join(', ');
          obj.createdAt = new Date(obj['created_on']).toLocaleDateString();
          return obj;
        });
        // this.kpiData = this.dataMapper(res);
        // this.uniqueKpis = Array.from(
        //   new Set(this.kpiData.map((o) => o['kpi_name']))
        // );
        // console.log(this.kpiData);
        // let finalData = [];
        // // this.kpiIds.map(())
        // this.uniqueKpis.map((kpi) => {});

        // this.kpiIds.map((id) => {
        //   let obj = {};
        // });

        this.uniqueBusinessImpactKeys = this.getUniqueKeys(
          this.responseData,
          'businessImpact'
        );
        this.uniqueBusinessImpactKeys = [
          ...new Set(this.uniqueBusinessImpactKeys),
        ];
        this.uniqueExecutionImpactKeys = this.getUniqueKeys(
          this.responseData,
          'executionImpact'
        );
        this.uniqueExecutionImpactKeys = [
          ...new Set(this.uniqueExecutionImpactKeys),
        ];

        this.uniquePlanSummaryKeys = this.getUniqueKeys(
          this.responseData,
          'planSummary'
        );
        this.uniquePlanSummaryKeys = [...new Set(this.uniquePlanSummaryKeys)];

        this.uniqueBusinessImpactKeys.map((key) => {
          let obj = {};
        });

        this.tableHeaders = this.responseData.map((obj) => {
          return obj.name;
        });

        this.businessImpact = this.responseData
          .map((o) => o.businessImpact)
          .map((o) => {
            o['values'] = this.uniqueBusinessImpactKeys.map((key) => {
              if (o[key]) {
                o[key] = this.formatingData2(o[key]);
              }
              return o[key] ? o[key] : '-';
            });
            // o['values'] = [mapperKey, ...o['values']];
            return o;
          });

        this.planSummary = this.responseData
          .map((o) => o.planSummary)
          .map((o) => {
            o['values'] = this.uniquePlanSummaryKeys.map((key) => {
              return o[key] ? o[key] : '-';
            });
            return o;
          });

        this.executionImpact = this.responseData
          .map((o) => o.executionImpact)
          .map((o) => {
            o['values'] = this.uniqueExecutionImpactKeys.map((key) => {
              if (o[key]) {
                o[key]['new'] = this.formatingData2(o[key]['new']);
              }
              return o[key]?.new ? o[key]['new'] : '-';
            });
            return o;
          });
        // let struc = [
        //   {name:1,val:2,val:3,val3}
        // ]
      });
  }

  getKeywiseData(key) {}
  formatingData2(n) {
    // if (typeof n === 'string') {
    //   return n;
    // }
    let k = '';
    let p = 1;
    if (n < 0) {
      n = n * -1;
      p = -1;
      k = '-';
    }
    if (n >= 1000 && n < 1000000) {
      // 1,02,280
      let x = Math.floor(n / 1000);
      let y = Math.round((n % 1000) / 100);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'K';
      } else return parseFloat(k + x).toFixed(1) + 'K';
    } else if (n >= 1000000 && n < 1000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000);
      let y = Math.round((n % 1000000) / 100000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'M';
        // return (parseFloat().toFixed(1) + 'M');
      } else return parseFloat(k + x).toFixed(1) + 'M';
    } else if (n >= 1000000000 && n < 1000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000);
      let y = Math.round((n % 1000000000) / 100000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'B';
      else return parseFloat(k + x).toFixed(1) + 'B';
    } else if (n >= 1000000000000 && n < 1000000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000000);
      let y = Math.round((n % 1000000000000) / 100000000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'T';
      else return parseFloat(k + x).toFixed(1) + 'T';
    } else {
      return (n * p).toFixed(1);
    }
  }
  getKpiInfo(kpi) {}
  dataMapper(res) {
    let finalArr = [];
    let arr = res.map((obj) => {
      let keys = Object.keys(obj);
      //  console.log(obj)
      let ans = {
        ...obj[keys[0]],
        scenario_id: keys[0],
      };
      finalArr.push(ans);
      return ans;
    });
    return finalArr;
  }
  getUniqueKeys(data, key) {
    let keys = [];
    data.map((o) => {
      keys = [...keys, ...Object.keys(o[key])];
    });

    return keys;
  }
}
