import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlacementStateService } from 'src/app/placement-state.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/helper/app-config';
import { ComparePlanogramDialogComponent } from '../compare-planogram-dialog/compare-planogram-dialog.component';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';

@Component({
  selector: 'app-scenario-library',
  templateUrl: './scenario-library.component.html',
  styleUrls: ['./scenario-library.component.scss'],
})
export class ScenarioLibraryComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private dataService: DataService,
    public dialog: MatDialog,
    private placementState: PlacementStateService
  ) {}
  search_input = '';
  currentUser = '';
  displayedColumns = [
    'name',
    'region2',
    'channel',
    // 'segmentation',
    // 'cooler_type',
    'status',
    'created_by',
    'created_on',
    'Actions',
  ];

  dataSource = new MatTableDataSource();

  displayTableHeaders = [
    'Scenario Name',
    'Region',
    'Channel',
    // 'Segmentation',
    // 'Cooler Type',
    'Status',
    'Created By',
    'Created on',
    'Actions',
  ];

  @ViewChild(MatSort) sort: MatSort;
  showLoader: Observable<any> = this.placementState.isLoading;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  allScenarios: Observable<any> = this.placementState.allScenarios;
  subscriptionsToUnsubscribe = {};
  offset = 0;
  limit = CONFIG.LIMIT;
  ngOnInit(): void {
    // this.placementState.getScenarios().subscribe((o) => {
    //   console.log(o);
    // this.dataSource.data = o;
    // });
    this.placementState.setStateWithKey('isLoading', true);
    this.dataSource.data = [];
    this.placementState.setStateWithKey('allScenarios', []);
    setTimeout(() => {
      this.placementState.getScenarios({
        offset: 0,
        limit: this.limit,
      });
    }, 2000);
    this.subscriptionsToUnsubscribe['1'] = this.allScenarios.subscribe((o) => {
      this.dataSource.data = [...this.dataSource.data, ...o];
      console.log(this.showLoader);
    });
  }
  openComparePlanogramDialog = () => {
    const dialogRef = this.dialog.open(ComparePlanogramDialogComponent, {
      height: '90%',
      width: '80%',
    });
  };

  updateFilter = (event: any) => {
    this.dataSource.filter = this.search_input.trim().toLowerCase();
  };
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  formatValue(val, column) {
    if (column === 'activity' || column === 'channel') {
      return val;
    }
    return this.formatingData(val);
  }

  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }

  stopBubbling(e, row) {
    e.stopPropagation();
    this.redirectToDraftState(row);
    return;
  }

  redirectToPlacementBase() {
    this.dataService.setFilename('');
    this.placementState.setMode('new');
    this.placementState.resetStateForNewScenario();
    this.placementState.setStateWithKey('isNextDisabled', true);
    this.router.navigate(['/' + CONFIG.PATH.PLACEMENT_BASE + 0], {
      queryParams: { mode: 'new' },
    });
  }

  redirectToDraftState(row) {
    let screenId = row.screen_id - 1;
    this.placementState.setMode('draft');
    this.placementState.resetStateForDraftMode();
    this.placementState.setStateWithKey('scenarioDetails', row);
    localStorage.setItem('scenarioDetails', JSON.stringify(row));
    this.placementState.setScenarioName(row.name);
    this.placementState.setScenarioId(row.id);

    this.router.navigate(['/' + CONFIG.PATH.PLACEMENT_BASE + screenId], {
      queryParams: { scenarioId: row.id, mode: 'draft' },
    });
  }

  deleteScenario(e, row) {
    e.stopPropagation();
    let payload = {
      scenario_id: row.id,
    };

    const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
      height: '200px',
      width: '500px',
      data: {
        header_msg: 'Are you sure you want to delete?',
        header_msg_description: 'this action cannot be undone.',
        cancel_action: 'Cancel',
        ok_action: 'Continue',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (row.id !== undefined) {
          this.dataSource.data = [];
          this.placementState.deleteScenario(payload);
        }
      }
    });
  }
  addSpace(arr) {
    return arr.map((str, i) => {
      let st;
      if (arr.length === 1) {
        st = str;
      } else if (arr.length === i + 1) {
        st = ' ' + str;
      } else {
        st = ' ' + str;
      }
      return st;
    });
  }
  paginationChange(e) {
    console.log(e);
    this.offset = this.offset + this.limit;
    this.placementState.getScenarios({
      offset: this.offset,
      limit: this.limit,
    });
  }

  formatingData(n) {
    let k = '';
    let p = 1;
    if (n < 0) {
      n = n * -1;
      p = -1;
      k = '-';
    }
    if (n >= 1000 && n < 1000000) {
      // 1,02,280
      let x = Math.floor(n / 1000);
      let y = Math.round((n % 1000) / 100);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'K';
      } else return parseFloat(k + x).toFixed(1) + 'K';
    } else if (n >= 1000000 && n < 1000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000);
      let y = Math.round((n % 1000000) / 100000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'M';
        // return (parseFloat().toFixed(1) + 'M');
      } else return parseFloat(k + x).toFixed(1) + 'M';
    } else if (n >= 1000000000 && n < 1000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000);
      let y = Math.round((n % 1000000000) / 100000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'B';
      else return parseFloat(k + x).toFixed(1) + 'B';
    } else if (n >= 1000000000000 && n < 1000000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000000);
      let y = Math.round((n % 1000000000000) / 100000000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'T';
      else return parseFloat(k + x).toFixed(1) + 'T';
    } else {
      return (n * p).toFixed(1);
    }
  }
}
