import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user-service';
import { environment } from 'src/environments/environment';
import { DataService } from './services/data.service';
import Swal from 'sweetalert2';
import { getCookie } from '../shared/common-methods';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'angular-cde-builder';
  isIframe = false;
  login_display = false;
  private readonly _destroying$ = new Subject<void>();
  saved_access_token_db = '';

  constructor(
    private userService: UserService,
    public dataService: DataService
  ) {}

  ngOnInit() {
    if (!getCookie('hubble_access_token')) {
      // console.log('this is toekn '+ this.dataService.tokenValidation("hubble_access_token"))
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Access Token ,Redirecting to Hubble !!!',
        showConfirmButton: false,
        timer: 4000,
      }).then(
        () => (window.location.href = environment.hubble_dashboard_url)
      );
      // this.dataService.tokenValidation("hubble_access_token")
    } else {
      this.dataService
        .tokenValidation(getCookie('hubble_access_token'))
        .then((res) => {
          if (res['code'] !== 200) {
            Swal.fire({
              icon: 'warning',
              title: res['err_msg'] + ' ,Redirecting to Hubble !!!',
              showConfirmButton: false,
              timer: 4000,
            }).then(
              () => (window.location.href = environment.hubble_dashboard_url)
            );
          }
        })
        .catch((err) =>
          Swal.fire({
            icon: 'warning',
            title: 'An Error Ocurred ,Redirecting to Hubble !!!',
            showConfirmButton: false,
            timer: 4000,
          }).then(
            () => (window.location.href = environment.hubble_dashboard_url)
          )
        );
    }
  }

  saveToken(payload: any) {
    if (this.saved_access_token_db !== payload.accessToken) {
      let user_info = {
        username: payload.account.username,
        access_token: payload.accessToken,
        expiresOn: payload.expiresOn,
        name: payload.account.name,
      };
      this.userService.saveUserToken(user_info).subscribe({
        next: (res: any) => {
          this.saved_access_token_db = res.access_token;
        },
        error: (error) => {
          console.log('error :: ', error);
        },
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
