import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-down-arrow',
  templateUrl: './down-arrow.component.html',
  styleUrls: ['./down-arrow.component.css']
})
export class DownArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
