<div id="nav-container" style="height: 100%; width: 100%">
  <nav class="navbar navbar-expand-lg navbar-light" style="height: 100%">
    <div class="logo-container">
    <img
      (click)="redirectToHome()"  alt="Decision Point Logo"
      src="./assets/DP_logo.png"
      style="height: 37px; width: 122px; cursor: pointer"
    />
  </div>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      style="height: 100%; justify-content: flex-end"
    >
      <ul
        class="navbar-nav mx-auto"
        style="height: 100%; margin-left: 200px !important"
      >
        <li class="nav-item" *ngFor="let tab of tabs">
          <a
            class="nav-link"
            [routerLink]="tab.pathname"
            routerLinkActive="tab.name === active_tab"
            [ngClass]="{
              active: tab.name === active_tab,
              disabled: tab.name !== tabs[0].name
            }"
            >{{ tab.name }}</a
          >
        </li>
      </ul>
      <i class="bi bi-person" style="font-size: 1.5rem"></i>
    </div>
  </nav>
</div>
