export const FILTER_KEYS = {
  REGION: 'regions',
  CHANNEL: 'channels',
};
export const STATIC_DATA = {
  COOLER_PURCHASE_FILTERS: [
    {
      name: 'Region',
      filters: [],
      selectedFilters: [],
      filterIcon: 'keyboard_arrow_down',
      value: FILTER_KEYS.REGION,
      key: 'regions',
      // single: true,
      draftKey: 'region',
    },
    {
      name: 'Channel',
      filters: [],
      selectedFilters: [],
      filterIcon: 'keyboard_arrow_down',
      value: FILTER_KEYS.CHANNEL,
      key: 'channel',
      draftKey: 'channel',
    },
  ],
  COOLER_PLACEMENT: {
    AGE_COOLER_FILTERS: [
      {
        name: 'Age',
        filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'Age',
      },
      // {
      //   name: 'Cooler Type',
      //   filters: ['Regular', 'IC', 'Stills'],
      //   selectedFilters: [],
      //   filterIcon: 'keyboard_arrow_down',
      //   value: 'Cooler Type',
      // },
    ],
    REGION_CHANNEL_BASE_FILTERS: [
      {
        name: 'Region',
        filters: ['Central', 'East', 'West'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'Region',
      },
      {
        name: 'Channel',
        filters: ['Small Store', 'Large Store', 'On Premise', 'Other'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'Channel',
      },
      {
        name: 'Base Door',
        filters: [1, 2, 3, 4],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'Base Door',
      },
    ],
  },
  OUTLET_LEVEL: {
    OUTLET_FILTERS: [
      {
        name: 'Placement Type',
        filters: ['Vertical', 'Horizontal', 'Replacement'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'placement_type',
      },
      // {
      //   name: 'Cooler Type',
      //   filters: ['Regular', 'IC', 'Stills'],
      //   selectedFilters: [],
      //   filterIcon: 'keyboard_arrow_down',
      //   value: 'cooler_type',
      //   hide: true,
      // },
      {
        name: 'Region',
        filters: ['Central', 'East', 'West'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'regions',
      },
      {
        name: 'Channel',
        filters: ['Small Store', 'Large Store', 'On Premise'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'channels',
      },
    ],
    OUTPUT_FILTERS: [
      {
        name: 'Channel',
        filters: ['SMALL STORE', 'LARGE STORE', 'ON PREMISE'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'channels',
      },
      {
        name: 'Expansion',
        filters: ['Horizontal', 'Vertical', 'Replacement'],
        selectedFilters: [],
        filterIcon: 'keyboard_arrow_down',
        value: 'expansion',
      },
    ],
  },
};
