import { Injectable } from '@angular/core';
import { defer, from } from 'rxjs';
import { cloneDeep } from 'lodash';

import { map, tap, pluck } from 'rxjs/operators';
import { CONFIG } from 'src/helper/app-config';
import { FILTER_KEYS, STATIC_DATA } from 'src/shared/data-store';
import { DataService } from './services/data.service';
import { StateService } from './services/state.service';
import {
  addId,
  createObjectWithChecked,
  extractRoi,
  getCheckedValues,
  getRequiredObj,
} from 'src/shared/common-methods';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
const mapper = {
  Replacement: 'replacement',
  'Horizontal Expansion': 'horizontal',
  'Vertical Expansion': 'vertical',
};
const types = ['Replacement', 'Horizontal Expansion', 'Vertical Expansion'];

const initialState = {
  allScenarios: [],
  compareScenarios: [],
  isLoading: true,
  mode: 'new',
  isNextDisabled: true,
  scenarioId: null,
  scenarioName: '',
  coolerPurchaseFilters: cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS),
  activeTab: null,
  outletVertical: [],
  outletHorizontal: [],
  outletReplacement: [],
  coolerPlacement: {
    horizontal: [],
    vertical: [],
    replacement: {
      filters: {
        ageCooler: [],
        regionChannelBase: [],
      },
      graph: [],
    },
  },
  placementPlan: {
    planSummary: {},
    businessImpact: {},
    executionImpact: {},
    newCoolers: {},
    roiChart: {},
    reasonsToPlace: {},
    outputFilters: {},
  },
  placementPlanReasonsChart: {},
  placementPlanRoiChart: [],
  placementPlanTableData: [],
  scenarioDetails: JSON.parse(localStorage.getItem('scenarioDetails')) || {},
  purchasePlanData: [],
  outletFilters: cloneDeep(STATIC_DATA.OUTLET_LEVEL.OUTLET_FILTERS),
  outputFilterObj: {},
  coolerPlacementReplacementTable: [],
};
@Injectable({
  providedIn: 'root',
})
export class PlacementStateService extends StateService<any> {
  initialDataObj = {};
  allScenarios = this.select((state) => state.allScenarios);
  compareScenarios = this.select((state) => state.compareScenarios);

  isLoading = this.select((state) => state.isLoading);
  mode = this.select((state) => state.mode);
  isNextDisabled = this.select((state) => state.isNextDisabled);
  scenarioId = this.select((state) => state.scenarioId);
  scenarioName = this.select((state) => state.scenarioName);
  // coolerPurchaseFilters
  coolerPurchaseFilters = this.select((state) => state.coolerPurchaseFilters);
  outletVertical = this.select((state) => state.outletVertical);
  outletHorizontal = this.select((state) => state.outletHorizontal);
  outletReplacement = this.select((state) => state.outletReplacement);

  activeTab = this.select((state) => state.activeTab);
  coolerPlacement = this.select((state) => state.coolerPlacement);

  placementPlan = this.select((state) => state.placementPlan);
  placementPlanTableData = this.select((state) => state.placementPlanTableData);
  scenarioDetails = this.select((state) => state.scenarioDetails);
  purchasePlanData = this.select((state) => state.purchasePlanData);
  outletFilters = this.select((state) => state.outletFilters);

  placementPlanReasonsChart = this.select(
    (state) => state.placementPlanReasonsChart
  );

  coolerPlacementReplacementTable = this.select(
    (state) => state.coolerPlacementReplacementTable
  );

  placementPlanRoiChart = this.select((state) => state.placementPlanRoiChart);
  // outputFilterObj : {}
  outputFilterObj = this.select((state) => state.outputFilterObj);

  tabs = CONFIG.TABS.map((val) => val);

  constructor(
    private dataService: DataService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    super(initialState);
  }

  openSnackBar(text) {
    this._snackBar.open(text, 'Close');
  }
  closeSnackBar() {
    this._snackBar.dismiss();
  }

  getScenarios(payload) {
    this.setState({ isLoading: true });

    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.GETALLBASESCENARIOS)
      )
    )
      .pipe(
        // map((res) => JSON.parse(res ? res : '')),
        tap((res) => console.log(res)),
        pluck('data'),
        tap((data) => console.log(data)),
        map((data) => {
          console.log(data);
          return data;
        }),
        map((data: any) => {
          return data
            .map((obj) => {
              obj['created_on'] = new Date(
                obj['created_on']
              ).toLocaleDateString();
              obj['region2'] =
                obj['region'].length === 4 ? 'All' : obj['region'];
              return obj;
            })
            .reverse();
        })
      )
      .subscribe(
        (data) => {
          this.setState({ isLoading: false });
          this.setState({ allScenarios: data.reverse() });
        },
        (err) => {
          console.log(err);
        }
      );
  }
  getCompareScenarios() {
    this.setState({ isLoading: true });

    defer(() =>
      from(this.dataService.getRequest(CONFIG.API.GET_COMPARE_SCENARIOS))
    )
      .pipe(
        map((res: any) => JSON.parse(res ? res : '')),
        tap((res) => console.log(res)),
        pluck('data'),
        tap((data) => console.log(data)),
        map((data) => {
          console.log(data);
          return data;
        }),
        map((data) => {
          return data
            .map((obj) => {
              obj['created_on'] = new Date(
                obj['created_on']
              ).toLocaleDateString();
              obj['region2'] =
                obj['region'].length === 4 ? 'All' : obj['region'];
              return obj;
            })
            .reverse();
        })
      )
      .subscribe(
        (data) => {
          this.setState({ compareScenarios: data, isLoading: false });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  deleteScenario(payload) {
    this.setState({ isLoading: true, allScenarios: [] });
    this.dataService
      .deleteRequest(payload, CONFIG.API.DELETE)
      .subscribe((o) => {
        if (o['code'] == 400) {
          this._snackBar.open(
            'User has no access to delete the scenario(Only the user who created the scenario will be able to delete it.)',
            'Close'
          );
          this.setState({ isLoading: false });

          this.getScenarios({
            offset: 0,
            limit: CONFIG.LIMIT,
          });

          return;
        }
        console.log(o);
        this.setState({ isLoading: false });
        this.getScenarios({
          offset: 0,
          limit: CONFIG.LIMIT,
        });
      });
  }

  discardDeleteScenario(payload) {
    this.setState({ isLoading: true, allScenarios: [] });
    this.dataService
      .deleteRequest(payload, CONFIG.API.DELETE)
      .subscribe((o) => {
        if (o['code'] == 400) {
          this._snackBar.open(
            'User has no access to delete the scenario(Only the user who created the scenario will be able to delete it.)',
            'Close'
          );
          this.setState({ isLoading: false });
          return;
        }
        this.setState({ isLoading: false });
      });
  }
  setMode(mode) {
    this.setState({ mode: mode });
  }

  setNextButtonState(value) {
    this.setState({ isNextDisabled: value });
  }

  setScenarioId(id) {
    this.setState({ scenarioId: id });
  }

  setScenarioName(name) {
    this.setState({ scenarioName: name });
  }

  resetStateForDraftMode() {
    this.setState({
      coolerPurchaseFilters: cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS),
      outletVertical: [],
      outletHorizontal: [],
      outletReplacement: [],
      purchasePlanData: [],
      coolerPlacement: {
        horizontal: [],
        vertical: [],
        replacement: {
          filters: {
            ageCooler: cloneDeep(
              STATIC_DATA.COOLER_PLACEMENT.AGE_COOLER_FILTERS
            ),
            regionChannelBase: [],
          },
          graph: [],
        },
      },
      placementPlan: {
        planSummary: {},
        businessImpact: {},
        executionImpact: {},
        newCoolers: {},
        roiChart: {},
        reasonsToPlace: {},
        outputFilters: {},
        initialDataObj: {},
      },
      placementPlanReasonsChart: {},
      placementPlanTableData: [],
      placementPlanRoiChart: [],
      outletFilters: cloneDeep(STATIC_DATA.OUTLET_LEVEL.OUTLET_FILTERS),
      outputFilterObj: {},
    });
  }

  resetStateForNewScenario() {
    this.setState({
      scenarioId: null,
      scenarioName: '',
      coolerPurchaseFilters: cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS),
      outletVertical: [],
      outletHorizontal: [],
      outletReplacement: [],
      purchasePlanData: [],
      coolerPlacement: {
        horizontal: [],
        vertical: [],
        replacement: {
          filters: {
            ageCooler: cloneDeep(
              STATIC_DATA.COOLER_PLACEMENT.AGE_COOLER_FILTERS
            ),
            regionChannelBase: [],
          },
          graph: [],
        },
      },
      placementPlan: {
        planSummary: {},
        businessImpact: {},
        executionImpact: {},
        newCoolers: {},
        roiChart: {},
        reasonsToPlace: {},
      },
      placementPlanTableData: [],
      scenarioDetails: {},
      outputFilterObj: {},
    });
  }
  getCoolerPurchaseChannels() {
    this.setState({ isLoading: true });

    let region = getRequiredObj(
      FILTER_KEYS.REGION,
      this.state.coolerPurchaseFilters
    )[0]['selectedFilters'];
    let payload = {
      region: region,
    };
    defer(() =>
      from(
        this.dataService.postRequest(
          payload,
          CONFIG.API.COOLER_PURCHASE.GET_CHANNELS
        )
      )
    )
      .pipe(
        map((data) => {
          let filters = data['data'].channels.map((o) => {
            let id = Object.keys(o)[0];
            let channel = o[id];
            return { id: id, channels: channel };
          });
          filters = {
            channels: filters,
          };
          return cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS).map((obj) => {
            if (obj.value === FILTER_KEYS.CHANNEL) {
              obj.filters = createObjectWithChecked(
                filters[obj.value].map((o) => o['channels'])
              );
              obj.filters = addId(
                obj.filters,
                filters[obj.value].map((o) => o.id)
              );
            }
            return obj;
          });
        }),
        map((data) => {
          let channel = data.find((obj) => obj.value === FILTER_KEYS.CHANNEL);
          let region = this.state.coolerPurchaseFilters.find(
            (obj) => obj.value === FILTER_KEYS.REGION
          );
          return [region, channel];
        })
      )
      .subscribe(
        (data) => {
          this.setCoolerPurchaseFilters(data);
          this.setState({ isLoading: false });
        },
        (err) => {
          console.log('in observable');
          console.log(err);
        }
      );
  }
  getCoolerPurchaseRegions() {
    this.setState({ isLoading: true });

    defer(() =>
      from(this.dataService.getRequest(CONFIG.API.COOLER_PURCHASE.GET_REGIONS))
    )
      .pipe(
        map((res: any) => JSON.parse(res ? res : '')),

        map((data) => {
          let filters = data['data'].regions.map((o) => {
            let id = Object.keys(o)[0];
            let region = o[id];
            return { id: id, regions: region };
          });
          filters = {
            regions: filters,
          };
          return cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS).map((obj) => {
            if (obj.value === FILTER_KEYS.REGION) {
              obj.filters = createObjectWithChecked(
                filters[obj.value].map((o) => o[obj.key])
              );
              obj.filters = addId(
                obj.filters,
                filters[obj.value].map((o) => o.id)
              );
            }
            return obj;
          });
        }),
        map((data: any) => {
          if (this.state.mode === 'draft') {
            this.setStateWithKey(
              'scenarioName',
              this.state.scenarioDetails['name']
            );
            return data.map((obj: any) => {
              if (obj.draftKey === 'region') {
                obj.filters = createObjectWithChecked(
                  this.state.scenarioDetails['region']
                );
                obj.filters = obj.filters.map((o) => {
                  o.checked = true;
                  return o;
                });
                obj.selectedFilters = getCheckedValues(obj.filters);
              }
              if (obj.draftKey === 'channel') {
                obj.filters = createObjectWithChecked(
                  this.state.scenarioDetails['channel']
                );
                obj.filters = obj.filters.map((o) => {
                  o.checked = true;
                  return o;
                });
                obj.selectedFilters = getCheckedValues(obj.filters);
              }
              return obj;
            });
          }
          return data;
        })
      )
      .subscribe(
        (data) => {
          this.setCoolerPurchaseFilters(data);
          this.setState({ isLoading: false });
        },
        (err) => {
          console.log('in observable');
          console.log(err);
        }
      );
  }
  getCoolerPurchaseDropDownData() {
    this.setState({ isLoading: true });

    defer(() =>
      from(
        this.dataService.getRequest(CONFIG.API.COOLER_PURCHASE.DROPDOWN_DATA)
      )
    )
      .pipe(
        map((res: any) => JSON.parse(res ? res : '')),
        tap((res) => console.log(res)),
        pluck('data'),
        tap((data) => console.log(data)),
        map((data) => {
          return cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS).map((obj) => {
            obj.filters = createObjectWithChecked(
              data[obj.value].map((o) => o[obj.key])
            );
            obj.filters = addId(
              obj.filters,
              data[obj.value].map((o) => o.id)
            );
            return obj;
          });
        }),
        map((data: any) => {
          if (this.state.mode === 'draft') {
            this.setStateWithKey(
              'scenarioName',
              this.state.scenarioDetails['name']
            );
            return data.map((obj: any) => {
              if (obj.draftKey === 'region') {
                obj.filters = obj.filters.map((o) => {
                  if (this.state.scenarioDetails['region'].includes(o.value)) {
                    o.checked = true;
                  }
                  return o;
                });
                obj.selectedFilters = getCheckedValues(obj.filters);
              }
              if (obj.draftKey === 'channel') {
                obj.filters = obj.filters.map((o) => {
                  if (this.state.scenarioDetails['channel'].includes(o.value)) {
                    o.checked = true;
                  }
                  return o;
                });
                obj.selectedFilters = getCheckedValues(obj.filters);
              }
              return obj;
            });
          }
          return data;
        })
      )
      .subscribe(
        (data) => {
          this.setCoolerPurchaseFilters(data);
          this.setState({ isLoading: false });
        },
        (err) => {
          console.log('in observable');
          console.log(err);
        }
      );
  }
  setCoolerPurchaseFilters(value) {
    this.setState({ coolerPurchaseFilters: value });
  }
  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }
  postAge(age) {
    let payload = {
      scenario_id: Number(this.state.scenarioId),
      cooler_age: age ? age : 2,
    };

    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.COOLER_PLACMENT.AGE)
      )
    ).subscribe((res: any) => {
      console.log(res);
      // if (res.code == 400) {
      //   this.openSnackBar(res.data);
      //   return;
      // }
      this.setState({ coolerPlacementReplacementTable: res.data });
    });
  }
  saveScenarioAndGetId(type?) {
    this.setState({ isLoading: true });

    let channel = getRequiredObj(
      FILTER_KEYS.CHANNEL,
      this.state.coolerPurchaseFilters
    )[0]['selectedIds'];
    let region = getRequiredObj(
      FILTER_KEYS.REGION,
      this.state.coolerPurchaseFilters
    )[0]['selectedIds'];
    let payload = {
      name: this.state.scenarioName,
      region: region,
      channel: channel,
      purchase_plan: this.state.purchasePlanData,
    };
    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.COOLER_PURCHASE.SAVE)
      )
    )
      .pipe(
        map((resp) => {
          this.setScenarioId(resp['ScenarioId']);
          let index = this.tabs.indexOf(this.state.activeTab);
          if (index < 3) {
            this.setActiveTab(
              this.tabs[this.tabs.indexOf(this.state.activeTab) + 1]
            );
          }
          return resp;
        })
      )
      .subscribe((o) => {
        console.log(o);
        if (type) {
          this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
        }

        this.setState({ isLoading: false });
      });
  }

  outletPotentialInitialRequests() {
    this.setState({ isLoading: true });

    defer(() =>
      from(this.dataService.getRequest(CONFIG.API.OUTLET_POTENTIAL.POPULATE))
    )
      .pipe(
        map((res: any) => JSON.parse(res ? res : '')),
        map((res) => {
          let horizontal = res['data']['data'].filter((o) => {
            return o.type === 'Horizontal Expansion';
          });

          let vertical = res['data']['data'].filter((o) => {
            return o.type === 'Vertical Expansion';
          });

          let replacement = res['data']['data'].filter((o) => {
            return o.type === 'Replacement';
          });

          let horizontalFiltered = this.removeHorizontalKpis(horizontal);

          this.setState({
            outletVertical: vertical.map((o, i, ar) => {
              o['value'] = 16.7;
              if (i === ar.length - 1) {
                o['value'] = 16.5;
              }
              o['isValid'] = true;

              return o;
            }),
          });
          this.setState({
            outletReplacement: replacement.map((o, i, ar) => {
              o['value'] = 100 / ar.length;
              o['isValid'] = true;
              return o;
            }),
          });

          horizontal = horizontal.map((o, i, ar) => {
            o['value'] = 100 / horizontalFiltered.length;
            o['disabled'] = false;
            o['isValid'] = true;

            return o;
          });

          this.setState({
            outletHorizontal: horizontal,
          });
          return res;
        })
      )
      .subscribe((res) => {
        console.log(res);
        this.setState({ isLoading: false });
      });
  }

  removeHorizontalKpis(vertical) {
    return vertical;
  }
  setLoader() {
    this.setState({ isLoading: true });
  }

  removeLoader() {
    this.setState({ isLoading: false });
  }

  outletPotentialDraftRequest() {
    this.setState({ isLoading: true });
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.OUTLET_POTENTIAL.DRAFT)
      )
    )
      .pipe(
        map((res) => {
          if(res['data'].length ==0){
            this.outletPotentialInitialRequests()
         }
         else{
          let vertical = res['data'].filter((o) => {
            o.value = o.value;
            return o.type === 'Vertical Expansion';
          });

          let horizontal = res['data'].filter((o) => {
            o.value = o.value;
            return o.type === 'Horizontal Expansion';
          });

          let replacement = res['data'].filter((o) => {
            o.value = o.value;
            return o.type === 'Replacement';
          });

          horizontal = horizontal.map((o, i, ar) => {
            o['value'] = o['value'];
            o['disabled'] = false;
            o['isValid'] = true;

            return o;
          });

          this.setState({
            outletVertical: vertical.map((o) => {
              o['isValid'] = true;
              return o;
            }),
            outletHorizontal: horizontal,
            outletReplacement: replacement.map((o) => {
              o['isValid'] = true;
              return o;
            }),
          });
          this.setState({ isLoading: false });
        }
          return res;
        })
      )
      .subscribe(() => {
        // this.setState({ isLoading: false });
      });
  }

  setStateWithKey(key, value) {
    this.setState({ [key]: value });
  }
  saveOutletPotentialProgress(type?) {
    this.setState({ isLoading: true });

    let kpis = this.state.outletVertical.map((o) => {
      let obj = {};
      obj['kpi_id'] = o.id ? o.id : o.kpi_id;
      obj['value'] = Number(o['value']);
      return obj;
    });

    let horizontalKpis = this.state.outletHorizontal.map((o) => {
      let obj = {};
      obj['kpi_id'] = o.id ? o.id : o.kpi_id;
      obj['value'] = Number(o['value']);
      return obj;
    });

    let replecementKpis = this.state.outletReplacement.map((o) => {
      let obj = {};
      obj['kpi_id'] = o.id ? o.id : o.kpi_id;
      /*  */ obj['value'] = Number(o['value']);
      return obj;
    });

    let payload = {
      kpi_data: [...kpis, ...horizontalKpis, ...replecementKpis],
      scenario_id: this.state.scenarioId,
    };

    defer(() =>
      from(
        this.dataService.postRequest(
          payload,
          CONFIG.API.OUTLET_POTENTIAL.OUTLET
        )
      )
    )
      .pipe(
        map((res) => {
          console.log(res);
          this.setActiveTab(
            this.tabs[this.tabs.indexOf(this.state.activeTab) + 1]
          );
        })
      )
      .subscribe((res) => {
        console.log(res);
        if (type) {
          this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
        }
        this.setState({ isLoading: false });
      });
  }

  getCoolerPlacementData() {
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    this.setState({ isLoading: true });

    defer(() =>
      from(
        this.dataService.postRequest(
          payload,
          CONFIG.API.COOLER_PLACMENT.PLACEMENT
        )
      )
    )
      .pipe(
        pluck('data'),
        map((res) => {
          let storeCoverage = [25];
          let recoommendedDoor = [2, 1, 4];
          let verticalCoverage = [80];
          let horizontal = Object.keys(res['Horizontal']).map((key, i) => {
            let obj = {
              ...res['Horizontal'][key],
            };
            obj['channel'] = key;
            return obj;
          });

          let vertical = Object.keys(res['Vertical']).map((key, i) => {
            let obj = {
              ...res['Vertical'][key],
            };
            obj['channel'] = key;
            return obj;
          });
          let data = {
            horizontal: horizontal,
            vertical: vertical,
            replacement: {
              filters: {
                ageCooler: cloneDeep(
                  STATIC_DATA.COOLER_PLACEMENT.AGE_COOLER_FILTERS
                ).map((obj) => {
                  obj.filters = createObjectWithChecked(obj.filters);
                  obj.filters = obj.filters.map((o) => {
                    if (o['value'] == 8) {
                      o['checked'] = true;
                    }
                    return o;
                  });
                  return obj;
                }),
              },
            },
          };
          this.setState({
            coolerPlacement: data,
          });
        })
      )
      .subscribe((res) => {
        this.setState({ isLoading: false });

        console.log(res);
      });
  }

  saveCoolerPlacementInfo() {
    this.setState({ isLoading: true });

    let horizontal = this.state.coolerPlacement.horizontal.map((row) => {
      let obj = {};
      obj['plan_id'] = Number(this.state.scenarioId);
      obj['Type'] = 'Horizontal';
      obj['channel'] = row['channel'];
      obj['Recommended_door'] = Number(row['Recommended Door Number']);
      obj['store_coverage'] = Number(row['Recommended Store Coverage']);
      return obj;
    });

    let vertical = this.state.coolerPlacement.vertical.map((row) => {
      let obj = {};
      obj['plan_id'] = Number(this.state.scenarioId);
      obj['Type'] = 'Vertical';
      obj['channel'] = row['channel'];
      obj['Recommended_door'] = Number(row['Recommended Door Number']);
      obj['store_coverage'] = Number(row['Recommended Store Coverage']);
      return obj;
    });

    let payload = {
      channel_wise_data: [...horizontal, ...vertical],
    };
    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.COOLER_PLACMENT.SAVE)
      )
    ).subscribe((res) => {
      console.log(res);
      this.intiateCalculations();
    });
  }

  intiateCalculations() {
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.COOLER_PLACMENT.CALC)
      )
    ).subscribe((res) => {
      this.setState({ isLoading: false });

      let index = this.tabs.indexOf(this.state.activeTab);
      if (index < 3) {
        this.setActiveTab(
          this.tabs[this.tabs.indexOf(this.state.activeTab) + 1]
        );
      }
      console.log(res);
    });
  }

  getPlacementPlanInitialData() {
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    this.setState({ isLoading: true });

    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.PLACEMENT_PLAN.PLAN)
      )
    )
      .pipe(
        pluck('data'),
        map((res) => {
          let roi = extractRoi(res, 'ON PREMISE', 'Horizontal');
          let placementObj = {};
          placementObj['planSummary'] = res['planSummary'];
          placementObj['businessImpact'] = res['businessImpact'];
          placementObj['executionImpact'] = res['executionImpact'];
          placementObj['newCoolers'] = res['graph_one'];

          this.initialDataObj = res;
          try {
            let expansion = Object.keys(res['reason_to_place']);
            if (expansion.length) {
            }
            let channel = Object.keys(
              res['reason_to_place'][expansion[0]]
                ? res['reason_to_place'][expansion[0]]
                : {}
            ).filter((key) => {
              if (
                Object.keys(res['reason_to_place'][expansion[0]][key]).length
              ) {
                return key;
              }
            });
            let filterObj = {
              channels: channel,
              expansion: expansion,
            };

            this.setState({
              placementPlan: placementObj,
              outputFilterObj: filterObj,
            });
          } catch (e) {
            console.log(e);
          }

          return res;
        })
      )
      .subscribe((res) => {
        this.setState({ isLoading: false });
      });
  }

  setFilterData(value) {
    this.setState({ placementPlan: value });
  }

  populatePlacementPlanTableDropDownData() {
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    defer(() =>
      from(
        this.dataService.postRequest(
          payload,
          CONFIG.API.PLACEMENT_PLAN.DROPDWON
        )
      )
    )
      .pipe(
        pluck('data'),
        map((data) => {
          return cloneDeep(STATIC_DATA.OUTLET_LEVEL.OUTLET_FILTERS).map(
            (obj) => {
              if (obj.value === 'channels' || obj.value === 'regions') {
                obj.filters = createObjectWithChecked(data[obj.value]);
              }
              return obj;
            }
          );
        }),
        map((data) => {
          this.setStateWithKey('outletFilters', data);
          return data;
        })
      )
      .subscribe((response) => {
        console.log(response);
      });
  }

  getPlacementPlanTableData() {
    this.setState({ isLoading: true });
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    defer(() =>
      from(
        this.dataService.postRequest(payload, CONFIG.API.PLACEMENT_PLAN.TABLE)
      )
    )
      .pipe(
        tap((res) => console.log(res)),
        pluck('data'),
        map((res: any) => {
          // res = res.map((o) => {
          //   o['placementType'] = placementType;
          //   return o;
          // });

          let allData = [...res];
          this.setState({ placementPlanTableData: allData });
        })
      )
      .subscribe((res) => {
        this.setState({ isLoading: false });
      });
  }

  activatePlan() {
    let payload = {
      scenario_id: this.state.scenarioId,
    };
    this.setState({ isLoading: true });

    defer(() =>
      from(this.dataService.putRequest(payload, CONFIG.API.ACTIVE))
    ).subscribe((data) => {
      console.log(data);
      this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
      this.setState({ isLoading: false });
    });
  }
}
