import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG } from 'src/helper/app-config';

@Component({
  selector: 'app-nav-component',
  templateUrl: './nav-component.component.html',
  styleUrls: ['./nav-component.component.css'],
})
export class NavComponentComponent implements OnInit {
  constructor(private router: Router) {}
  @Output() logoutEventEmitter = new EventEmitter();

  ngOnInit(): void {}

  tabs = [{ name: 'Scenario Library', pathname: CONFIG.PATH.SCENARIO_LIBRARY }];

  active_tab = this.tabs[0].name;

  handleActiveTab = (name: string) => {
    this.active_tab = name;
  };
  redirectToHome() {
    this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
  }
  logout = ()=>{
    this.logoutEventEmitter.emit();
  }
}
