import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import {
  createObjectWithChecked,
  getCheckedValues,
  getRequiredObj,
  makeFalse,
  selectAll,
} from 'src/shared/common-methods';
import { STATIC_DATA } from 'src/shared/data-store';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatMenuTrigger } from '@angular/material/menu';
import { PlacementStateService } from 'src/app/placement-state.service';

@Component({
  selector: 'app-cooler-placement',
  templateUrl: './cooler-placement.component.html',
  styleUrls: ['./cooler-placement.component.scss'],
})
export class CoolerPlacementComponent implements OnInit, OnChanges, OnDestroy {
  panelOpenState1 = false;
  panelOpenState2;
  panelOpenState3;

  horizontalData = {
    mapperForHeades: {
      channel: 'Channel',
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume per Store',
      'Revenue Per Store': 'Revenue per Store',
      'Profit Per Store': 'Profit per Store',
      // 'Avg. Profit/UC': 'Current No of Doors',
      Recommended_door: 'Recommended Door',
      store_coverage: 'Recommended Store Coverage %',
      'Current No Of Doors': 'Current No Of Doors',
    },
    boldHeaders: {
      Recommended_door: 'Recommended door / Store',
      store_coverage: 'store_coverage',
    },
    displayTableHeaders: [
      'channel',
      'No of stores',
      'Volume Per Store',
      'Revenue Per Store',
      'Current No Of Doors',

      'Recommended_door',
      'store_coverage',
    ],
    horizontalColumns: [
      'channel',
      'No of stores',
      'Volume Per Store',
      'Revenue Per Store',
      'Current No Of Doors',
      'Current Store Coverage',
      'Recommended Door Number',
      'Recommended Store Coverage',
    ],
    horizontalNormalColumns: {
      channel: 'channel',
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume Per Store',
      'Revenue Per Store': 'Revenue Per Store',
      'Profit Per Store': 'Profit Per Store',
      'Current No Of Doors': 'Current No Of Doors',
    },
    horizontalNumericalColumns: {
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume Per Store',
      'Revenue Per Store': 'Revenue Per Store',
      'Profit Per Store': 'Profit Per Store',
      'Current No Of Doors': 'Current No Of Doors',
      store_coverage: 'store_coverage',
      Recommended_door: 'Recommended_door',
    },
    horizontalStringColumns: {
      channel: 'channel',
    },
    horizontalInputColumns: {
      Recommended_door: 'Recommended_door',
      store_coverage: 'store_coverage',
    },
  };

  verticalData = {
    verticalColumns: [
      'channel',
      'No of stores',
      'Volume Per Store',
      'Revenue Per Store',
      'Current No Of Doors',
      'Current Store Coverage',
      'Recommended Door Number',
      'Recommended Store Coverage',
    ],
    mapperForVerticalColumns: {
      Channel: 'Channel',
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume Per Store',
      'Revenue Per Store': 'Revenue Per Store',
      'Profit Per Store': 'Profit Per Store',
      'Current No Of Doors': 'Current No Of Doors',
      Recommended_door: 'Recommended Door',
      store_coverage: 'Recommended Store Coverage %',
    },
    verticalNormalColumns: {
      channel: 'channel',
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume Per Store',
      'Revenue Per Store': 'Revenue Per Store',
      'Profit Per Store': 'Profit Per Store',
      'Current No Of Doors': 'Current No Of Doors',
      store_coverage: 'store_coverage',
    },
    verticalInputColumns: {
      Recommended_door: 'Recommended_door',
      store_coverage: 'store_coverage',
    },

    verticalNumericalColumns: {
      'No of stores': 'No of stores',
      'Volume Per Store': 'Volume Per Store',
      'Revenue Per Store': 'Revenue Per Store',
      'Profit Per Store': 'Profit Per Store',
      'Current No Of Doors': 'Current No Of Doors',

    },
    verticalStringColumns: {
      channel: 'channel',
    },
  };

  replacementData = {
    displayedColumns: ['channel', 'No of outlets', 'No of Doors', 'region'],
  };
  replacement = new MatTableDataSource();

  ageCoolerfilterWidth = '420px';
  horizontal = new MatTableDataSource();
  vertical = new MatTableDataSource();
  dataSource = new MatTableDataSource();


  verticalDisplayHeaders = [
    'Channel',
    '# Stores',
    'Volume / Store',
    'Revenue / Store',
    'Profit / Store',
    'Avg. Profit/UC',
    'store_coverage',
    'Current no of Doors',
    'Profit Per Door',
  ];
  verticalDisplayBoldHeaders = {
    Recommended_door: 'Recommended_door',
    store_coverage: 'Store Coverage %',
  };
  aggregatedDataSource = new MatTableDataSource();
  aggregateMockData = [
    {
      region: 'Central',
      channel: 'Small Store',
      stores: 500,
      coolers: 750,
    },
    {
      region: 'East',
      channel: 'Large Store',
      stores: 500,
      coolers: 750,
    },
    {
      region: 'Central',
      channel: 'On - Premise',
      stores: 500,
      coolers: 750,
    },
    {
      region: 'East',
      channel: 'Large Store',
      stores: 500,
      coolers: 750,
    },
    {
      region: 'Central',
      channel: 'On - Premise',
      stores: 500,
      coolers: 750,
    },
  ];
  aggregatedTableHeaders = ['Region', 'Channel', '# Stores', '# Coolers'];
  aggregatedDisplayedColumns = ['region', 'channel', 'stores', 'coolers'];
  horizontalNormalColumns = {
    channel: 'channel',
    '#stores': '#stores',
    'Volume/Store': 'Volume/Store',
    'Revenue/Store': 'Revenue/Store',
    'Profit/Store': 'Profit/Store',
    'Avg Profit/UC': 'Avg Profit/UC',
    store_coverage: 'store_coverage',
  };

  ageCoolerFilters = [];
  regionChannelbaseFilters;
  regionChannelBasefilterWidth = '225px';
  @ViewChildren(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private placementState: PlacementStateService) {}
  coolerPlacement;
  chart = {
    datasets: [
      {
        data: [0, 30, 20, 40, 35, 45, 33, 0, 0],
        label: 'Count of Customer Number',
      },
      {
        data: [45, 45, 45, 45, 45, 45, 45, 45, 45],
        label: 'Count of Equipment Model',
        type: 'line',
      },
      {
        data: [10, 30, 10, 3, 2, 8, 45, 20, 45],
        label: 'Sum of Door No.',
        type: 'line',
      },
    ],
    labels: [
      1, 2, 3, 4, 5, 6, 7, 8, 9,
    ],
    options: {
      legend: {
        text: 'You awesome chart with average line',
        display: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              min: 'Monday',
              max: 'Sunday',
            },
          },
        ],
      },
    },
  };
  graph;
  subscriptionsToUnsubscribe = {};
  showAll = true;
  ngOnInit(): void {
    this.subscriptionsToUnsubscribe['1'] =
      this.placementState.coolerPlacement.subscribe((data) => {
        this.horizontal.data = data['horizontal'];
        this.vertical.data = data['vertical'];

        this.ageCoolerFilters = data['replacement']['filters']['ageCooler'];
        this.graph = data['replacement']['graph'];
        if (this.ageCoolerFilters.length) {
          this.applyFilters();
        }
      });

    this.placementState.coolerPlacementReplacementTable.subscribe((data) => {
      this.replacement.data = data;
    });
    if (!this.vertical.data.length) {
      this.placementState.getCoolerPlacementData();
    }
  }
  ngAfterViewInit() {
  }
  downArrows() {
    this.ageCoolerFilters = this.ageCoolerFilters.map((obj) => {
      obj.filterIcon = 'keyboard_arrow_down';
      return obj;
    });
    this.regionChannelbaseFilters = this.regionChannelbaseFilters.map((obj) => {
      obj.filterIcon = 'keyboard_arrow_down';
      return obj;
    });
  }
  ngOnChanges(changes) {
    if (changes.graph.currentValue) {
      this.graphMapping(changes.graph.currentValue);
    }
  }
  graphMapping(data) {
    let barChartData = this.convertGraphStringsToNum(
      data,
      'Count of Customer Number'
    );
    let lineChart1 = this.convertGraphStringsToNum(
      data,
      'Count of Equipment Model'
    );
    let lineChart2 = this.convertGraphStringsToNum(data, 'Sum of Door No.');

    this.chart.datasets[0]['data'] = barChartData;
    this.chart.datasets[1]['data'] = lineChart1;
    this.chart.datasets[2]['data'] = lineChart2;
  }
  convertGraphStringsToNum(data, key) {
    return data
      .map((o) => o[key])
      .map((val) => val.slice(0, val.length - 1))
      .map((val) => Number(val));
  }
  iconClick(type, key, val, resize?, index?) {
    if (type === 'ageCooler') {
      if (
        this.ageCoolerFilters[index]['filterIcon'] === 'keyboard_arrow_down'
      ) {
        this.ageCoolerFilters[index]['filterIcon'] = 'keyboard_arrow_up';
      } else {
        this.ageCoolerFilters[index]['filterIcon'] = 'keyboard_arrow_down';
      }
      return;
    }

    if (type === 'regionChannelBase') {
      if (
        this.regionChannelbaseFilters[index]['filterIcon'] ===
        'keyboard_arrow_down'
      ) {
        this.regionChannelbaseFilters[index]['filterIcon'] =
          'keyboard_arrow_up';
      } else {
        this.regionChannelbaseFilters[index]['filterIcon'] =
          'keyboard_arrow_down';
      }
      return;
    }
  }
  mapFilters() {
    this.ageCoolerFilters = this.ageCoolerFilters.map((obj) => {
      obj.filters = createObjectWithChecked(obj.filters);
      return obj;
    });
    this.regionChannelbaseFilters = this.regionChannelbaseFilters.map((obj) => {
      obj.filters = createObjectWithChecked(obj.filters);
      return obj;
    });
  }
  filterChange(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      arr[i].checked = !arr[i].checked;
      arr = makeFalse(arr, i);
    }
  }
  applyFilters() {
    this.ageCoolerFilters = this.ageCoolerFilters.map((obj) => {
      obj.selectedFilters = getCheckedValues(obj.filters);
      return obj;
    });
    let age = getRequiredObj('Age', this.ageCoolerFilters);

    this.placementState.postAge(age[0]['selectedFilters'][0]);

    this.trigger?.['_results'].map((obj) => {
      obj.closeMenu();
    });
  }
  clearAll = (arr) => {
    arr.forEach((element) => {
      if (!element.disabled) {
        element.checked = false;
      }
    });
  };
  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }
  formatValue(val, column) {
    if (column === 'activity' || column === 'channel') {
      return val;
    }
    return this.formatingData(val);
  }

  formatingData(n) {
    let k = '';
    let p = 1;
    if (n < 0) {
      n = n * -1;
      p = -1;
      k = '-';
    }
    if (n >= 1000 && n < 1000000) {
      // 1,02,280
      let x = Math.floor(n / 1000);
      let y = Math.round((n % 1000) / 100);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'K';
      } else return parseFloat(k + x).toFixed(1) + 'K';
    } else if (n >= 1000000 && n < 1000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000);
      let y = Math.round((n % 1000000) / 100000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) {
        return parseFloat(k + x + '.' + y).toFixed(1) + 'M';
        // return (parseFloat().toFixed(1) + 'M');
      } else return parseFloat(k + x).toFixed(1) + 'M';
    } else if (n >= 1000000000 && n < 1000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000);
      let y = Math.round((n % 1000000000) / 100000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'B';
      else return parseFloat(k + x).toFixed(1) + 'B';
    } else if (n >= 1000000000000 && n < 1000000000000000) {
      // 1,02,280
      let x = Math.floor(n / 1000000000000);
      let y = Math.round((n % 1000000000000) / 100000000000);
      if (y == 10) {
        x = x + 1;
        y = 0;
      }
      if (y != 0) return parseFloat(k + x + '.' + y).toFixed(1) + 'T';
      else return parseFloat(k + x).toFixed(1) + 'T';
    } else {
      return (n * p).toFixed(1);
    }
  }
}
