import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uparrow',
  templateUrl: './uparrow.component.html',
  styleUrls: ['./uparrow.component.css']
})
export class UparrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
