<mat-menu
  (click)="$event.stopPropagation()"
  (closed)="downFunc()"
  #categoryMenu="matMenu"
  style="width: 100%"
>
  <div [ngStyle]="{ width: customWidth }" class="menu-buttons-wrapper">
    <div class="options">
      <ul>
        <!-- <ng-container *ngIf="showAll"> -->
        <li
          *ngIf="!showAll"
          style="
            display: flex;
            margin-bottom: 5px;
            padding: 5px;
            padding-right: 8px;
          "
          [ngClass]="{
            menu_selected: allChecked(dropDownValues)
          }"
        >
          <div class="checkbox-wrap">
            <mat-checkbox
              #val
              [color]="backgroundColor"
              [checked]="allChecked(dropDownValues)"
              (click)="$event.stopPropagation()"
              (change)="changeFunc($event.checked, 0, 'All', dropDownValues)"
            >
            </mat-checkbox>
          </div>
          <span
            [ngClass]="{
              selected_menu_text: allChecked(dropDownValues)
            }"
            class="normal-text-montserrat"
          >
            All
          </span>
        </li>
        <!-- </ng-container> -->
        <li
          style="
            display: flex;
            margin-bottom: 5px;
            padding: 5px;
            padding-right: 8px;
          "
          [ngClass]="{
            menu_selected: subtask.checked
          }"
          *ngFor="let subtask of dropDownValues; let i = index"
        >
          <div class="checkbox-wrap">
            <mat-checkbox
              [color]="backgroundColor"
              [disabled]="subtask.disabled"
              [checked]="subtask.checked"
              (click)="$event.stopPropagation()"
              (change)="changeFunc($event.checked, i, '', dropDownValues)"
            >
            </mat-checkbox>
          </div>
          <span
            [ngClass]="{
              disabled: subtask.disabled,
              selected_menu_text: subtask.checked
            }"
            class="normal-text-montserrat"
          >
            {{ getValue(subtask.value) }}
          </span>
        </li>
      </ul>
    </div>
    <div (click)="$event.stopPropagation()" class="form price">
      <button
        style="margin: 0px; padding: 0px; justify-self: flex-start"
        type="button"
        (click)="clearAll(dropDownValues)"
        class="btn btn primary cancelBtn"
      >
        Clear All
      </button>
      <button
        type="button"
        class="btn btn primary applyBtn"
        (click)="applyFilters(name)"
        style="
          background: #d3f8f5;
          padding-left: 10px;
          border-radius: 8px;
          padding-right: 10px;
          color: #25d4cf;
          padding-top: 2px;
          padding-bottom: 2px;
        "
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>
