import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { CONFIG } from 'src/helper/app-config';
import { cloneDeep } from 'lodash';

import { FILTER_KEYS, STATIC_DATA } from 'src/shared/data-store';
import {
  addId,
  createObjectWithChecked,
  getCheckedValues,
  getRequiredObj,
} from 'src/shared/common-methods';
import { filter } from 'rxjs/operators';
import { PlacementStateService } from 'src/app/placement-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  showLoader: Observable<any> = this.placementState.isLoading;
  types = ['Replacement', 'Horizontal Expansion', 'Vertical Expansion'];
  mapper = {
    Replacement: 'replacement',
    'Horizontal Expansion': 'horizontal',
    'Vertical Expansion': 'vertical',
  };
  coolerPurchaseData = {
    filters: cloneDeep(STATIC_DATA.COOLER_PURCHASE_FILTERS),
    scenarioName: '',
    isNextDisabled: true,
  };
  outletPotentialData = {
    horizontal: [],
    replacement: [],
    vertical: [],
    isNextDisabled: true,
    totalWeightage: 100,
  };
  coolerPlacement = {
    isNextDisabled: true,
    horizontal: [],
    replacement: {
      filters: {
        ageCooler: cloneDeep(STATIC_DATA.COOLER_PLACEMENT.AGE_COOLER_FILTERS),
        regionChannelBase: cloneDeep(
          STATIC_DATA.COOLER_PLACEMENT.REGION_CHANNEL_BASE_FILTERS
        ),
      },
      graph: {},
    },
    vertical: [],
  };
  placementPlanData = {
    planSummary: {},
    executionImpact: {},
    businessImpact: {},
    table: {},
    newCoolers: {},
  };
  constructor(
    private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute,
    private placementState: PlacementStateService
  ) {}
  tabs = CONFIG.TABS.map((val) => val);
  activeTab;
  scenarioId;
  isNextDisabled = false;
  scenarioName;
  mode = '';
  subscriptionsToUnsubscribe = {};
  ngOnInit(): void {
    console.log(this.tabs);
    this.subscriptionsToUnsubscribe['1'] = this.placementState.mode.subscribe(
      (observer) => {
        this.mode = observer;
      }
    );
    this.subscriptionsToUnsubscribe['2'] =
      this.placementState.isNextDisabled.subscribe((observer) => {
        this.isNextDisabled = observer;
      });
    this.subscriptionsToUnsubscribe['3'] =
      this.placementState.scenarioName.subscribe((name) => {
        this.scenarioName = name;
      });
    if (this.mode === 'draft') {
      this.placementState.setNextButtonState(false);
    }
    this.subscriptionsToUnsubscribe['4'] =
      this.placementState.activeTab.subscribe((tab) => {
        this.activeTab = tab;
      });
    this.route.params.subscribe((params) => {
      let screenId = params['screenId'];
      this.placementState.setActiveTab(this.tabs[screenId]);
      this.subscriptionsToUnsubscribe['5'] =
        this.placementState.scenarioId.subscribe((observer) => {
          this.scenarioId = observer;
        });
      if (this.mode === 'draft') {
        this.placementState.setScenarioId(
          this.route.queryParams['_value']['scenarioId']
        );
      }

      console.log(this.route.queryParams);
    });

    this.route.queryParams.subscribe((queryParams) => {
      console.log(queryParams);
      this.placementState.setStateWithKey(
        'scenarioId',
        queryParams?.scenarioId
      );
      this.placementState.setMode(queryParams?.mode);
    });
  }

  activatePlan() {
    this.placementState.activatePlan();
  }

  discard() {
    if (this.scenarioId) {
      this.deleteScenario();
    }
    return this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
  }

  deleteScenario() {
    let payload = {
      scenario_id: this.scenarioId,
    };
    this.placementState.discardDeleteScenario(payload);
  }

  getDataFromChildOutletPotential(data) {
    this.outletPotentialData[data.key] = data.value;
    this.isNextDisabled = this.outletPotentialData.isNextDisabled;
  }

  checkUniqueness() {
    this.dataService
      .postRequest(
        { scenario_name: this.scenarioName },
        CONFIG.API.COOLER_PURCHASE.UNIQUENESS
      )
      .then((res) => {
        console.log(res);
        if (res['status'] === 'success') {
          this.placementState.saveScenarioAndGetId();
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  disableNext() {}

  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }

  getDataFromChild(data) {
    this.coolerPurchaseData[data.key] = data.value;
    this.isNextDisabled = this.coolerPurchaseData.isNextDisabled;
  }
  showCompleted(index) {
    if (this.tabs.indexOf(this.activeTab) <= index) {
      return false;
    }
    return true;
  }
  moveLevel(tab) {
    this.dataService.closeSnackBar();

    let destinationIndex = this.tabs.indexOf(tab);
    let currentIndex = this.tabs.indexOf(this.activeTab);
    if (this.mode === 'new') {
      if (destinationIndex < currentIndex) {
        if (!this.isNextDisabled) {
          this.placementState.setActiveTab(tab);
        }
      }
    }
    if (this.mode === 'draft') {
      this.placementState.setActiveTab(tab);
      this.isNextDisabled = false;
    }
  }
  outletPotentialDraftRequest(id) {
    let payload = {
      scenario_id: id,
    };

    this.dataService
      .postRequest(payload, CONFIG.API.OUTLET_POTENTIAL.DRAFT)
      .then((res) => {
        this.outletPotentialMapping(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  outletPotentialMapping(res) {
    this.types.map((type) => {
      this.outletPotentialData[this.mapper[type]] = res['data'].filter((o) => {
        o.value = o.value;
        return o.type === type;
      });
    });
  }

  moveToNextLevel(type?, draft?) {
    let index = this.tabs.indexOf(this.activeTab);
    if (index === 0) {
      if (!this.scenarioId) {
        this.placementState.saveScenarioAndGetId(type);
        return;
      } else {
        this.moveLevelNoChecks(index);
      }
    }
    if (index === 1) {
      this.placementState.saveOutletPotentialProgress(type);

      return;
    }
    if (index == 2) {
      if (type) {
        this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
      }
      this.placementState.saveCoolerPlacementInfo();
    }
    if (draft) {
      this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
      return;
    }
    if (index == 3) {
      this.activatePlan();
    }
  }

  moveLevelNoChecks(index) {
    if (index < 3) {
      this.placementState.setActiveTab(
        this.tabs[this.tabs.indexOf(this.activeTab) + 1]
      );

      console.log(this.activeTab);
    }
  }

  goBack() {
    let index = this.tabs.indexOf(this.activeTab);
    if (index > 0) {
      this.placementState.setActiveTab(
        this.tabs[this.tabs.indexOf(this.activeTab) - 1]
      );
      console.log(this.activeTab);
    } else {
      this.router.navigate(['/' + CONFIG.PATH.SCENARIO_LIBRARY]);
    }
  }
}
