import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";


export function getHeaders(){
    return new HttpHeaders({
      "Content-Type": "application/json"
    });
}


  export function handleResponse (response : any){
    let response_ = JSON.parse(response);
    if(response_.code === 200){
      return response_.data;
    }
    return response_;
  }

export function handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }else if(error.status === 500){
    return throwError(() => error.statusText);
    }else if(error.status === 401){
      let error_ = JSON.parse(error.error);
      return throwError(error_.err_msg)
    }
     else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error);
    }
    // Return an observable with a user-facing error message.
    return throwError(error.message);
  }
