import { NgModule } from '@angular/core';
import { TooltipModule } from 'ng2-tooltip-directive';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';

import { AngularSplitModule } from 'angular-split';
import { NgChartsModule } from 'ng2-charts';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavComponentComponent } from './components/nav-component/nav-component.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AuthResponseComponent } from './components/auth-response/auth-response.component';

import { environment } from 'src/environments/environment';
import { ScenarioLibraryComponent } from './components/scenario-library/scenario-library.component';
import { CoolerPurchaseComponent } from './components/placement-base/cooler-purchase/cooler-purchase.component';
import { OutletPotentialComponent } from './components/placement-base/outlet-potential/outlet-potential.component';
import { CoolerPlacementComponent } from './components/placement-base/cooler-placement/cooler-placement.component';
import { PlacementPlanComponent } from './components/placement-base/placement-plan/placement-plan.component';
import { BaseComponent } from './components/placement-base/base/base.component';
import { UparrowComponent } from './components/icons/uparrow/uparrow.component';
import { DownArrowComponent } from './components/icons/down-arrow/down-arrow.component';
import { MenuButtonsComponent } from './components/filters/menu-buttons/menu-buttons.component';
import { UpArrowNormalComponent } from './components/icons/up-arrow-normal/up-arrow-normal.component';
import { MenuRoundBoxComponent } from './components/filters/menu-round-box/menu-round-box.component';
import { MenuFiltersComponent } from './components/filters/menu-filters/menu-filters.component';
import { ViewHoverComponent } from './components/icons/view-hover/view-hover.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConfirmDialogModalComponent } from './components/confirm-dialog-modal/confirm-dialog-modal.component';
import { ComparePlanogramDialogComponent } from './components/compare-planogram-dialog/compare-planogram-dialog.component';
import { ComparisonComponent } from './app/comparison/comparison.component';
import { AuthTokenInterceptor } from './app/interceptor/auth-token-interceptor';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
console.log('isIE :: ', isIE);

@NgModule({
  declarations: [
    AppComponent,
    NavComponentComponent,
    LoaderComponent,
    AuthResponseComponent,
    ScenarioLibraryComponent,
    CoolerPurchaseComponent,
    OutletPotentialComponent,
    CoolerPlacementComponent,
    PlacementPlanComponent,
    BaseComponent,
    UparrowComponent,
    DownArrowComponent,
    MenuButtonsComponent,
    UpArrowNormalComponent,
    MenuRoundBoxComponent,
    MenuFiltersComponent,
    ViewHoverComponent,
    ConfirmDialogModalComponent,
    ComparePlanogramDialogComponent,
    ComparisonComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    DragDropModule,
    MatListModule,
    MatGridListModule,
    MatTooltipModule,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatBadgeModule,
    MatExpansionModule,
    MatProgressBarModule,
    AngularSplitModule,
    NgChartsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    HttpClientModule,
    NgxChartsModule,
    CdkTableModule,
    TooltipModule,
    AngularFileUploaderModule,
    // MsalModule.forRoot(
    //   new PublicClientApplication({
    //     auth: {
    //       clientId: environment.client_id, // This is your client ID
    //       authority: environment.tenant_autority, // This is your tenant ID
    //       redirectUri: environment.redirect_url,
    //     },
    //     cache: {
    //       cacheLocation: BrowserCacheLocation.LocalStorage,
    //       storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    //     },
    //   }),
    //   {
    //     interactionType: InteractionType.Redirect, // MSAL Guard Configuration
    //     authRequest: {
    //       scopes: ['user.read'],
    //     },
    //   },
    //   {
    //     interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    //     protectedResourceMap: new Map([
    //       // ['Enter_the_Graph_Endpoint_Here/v1.0/me',['user.read']],
    //       ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    //       [environment.api_base_url, ['user.read']],
    //       [
    //         'https://kocdeglobalstorage.blob.core.windows.net/cooler-images/',
    //         ['user.read'],
    //       ],
    //     ]),
    //   }
    // ),
  ],
  providers: [
    // MsalGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
