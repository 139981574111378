import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PlacementStateService } from 'src/app/placement-state.service';
import { DataService } from 'src/app/services/data.service';
import { CONFIG } from 'src/helper/app-config';

@Component({
  selector: 'app-outlet-potential',
  templateUrl: './outlet-potential.component.html',
  styleUrls: ['./outlet-potential.component.scss'],
})
export class OutletPotentialComponent implements OnInit, OnChanges, OnDestroy {
  horizontal;
  replacement;
  vertical;
  @Input() totalWeightage;
  horizontalWeightage = 100;
  types = ['Replacement', 'Horizontal Expansion', 'Vertical Expansion'];
  mapper = {
    Replacement: 'replacement',
    'Horizontal Expansion': 'horizontal',
    'Vertical Expansion': 'vertical',
  };
  panelOpenState1 = false;
  panelOpenState2;
  panelOpenState3;
  myOptions = {
    placement: 'right',
  };

  @Output() passFiltersToParentEvnet = new EventEmitter<string>();

  constructor(
    private dataService: DataService,
    private placementState: PlacementStateService
  ) {}
  mode;
  kpiMapper = {
    'Profit Per Door': 'Revenue Per Door',
    'KO Door Share': 'VPO',
    'Outlet density': 'VPO',
  };
  kpiInfoMapper = {
    'Profit generated from one Door': 'Average Revenue Per Door',
    'Nos of TCCC doors / Total  Nos of doors': 'Average Volume Per Outlet',
    'Number of outlet in 5km radius': 'Average Volume Per Outlet',
  };
  subscriptionsToUnsubscribe = {};
  replacementWeightge = 100;
  cde = 20;
  ngOnInit(): void {
    this.subscriptionsToUnsubscribe['1'] = this.placementState.mode.subscribe(
      (mode) => {
        this.mode = mode;
      }
    );
    this.subscriptionsToUnsubscribe['2'] =
      this.placementState.outletVertical.subscribe((data) => {
        this.vertical = data;
      });
    this.subscriptionsToUnsubscribe['3'] =
      this.placementState.outletHorizontal.subscribe((data) => {
        this.horizontal = data;
      });

    this.subscriptionsToUnsubscribe['4'] =
      this.placementState.outletReplacement.subscribe((data) => {
        this.replacement = data;
      });
    if (this.mode === 'new') {
      if (!this.vertical.length) {
        this.placementState.outletPotentialInitialRequests();
      }
    }
    if (this.mode === 'draft') {
      if (!this.vertical.length) {
        this.placementState.outletPotentialDraftRequest();
      }
    }
  }

  weightageChange(e, type, index?) {
    let value = e.target.value;
    if (!this.isValidInput(value)) {
      this[type][index]['isValid'] = false;
    } else {
      this[type][index]['isValid'] = true;
      this[type][index]['value'] = value;
    }
    this.activateNext();
    this.placementState.setStateWithKey('outletVertical', this.vertical);
  }

  horizontalWeightageChange(e, type, index) {
    let value = e.target.value;
    if (!this.isValidInput(value)) {
      this[type][index]['isValid'] = false;
    } else {
      this[type][index]['isValid'] = true;
      this[type][index]['value'] = value;
    }
    this.activateNext();
    this.placementState.setStateWithKey('outletHorizontal', this.horizontal);
  }

  replacementWeightageChange(e, type, index) {
    let value = e.target.value;
    if (!this.isValidInput(value)) {
      this[type][index]['isValid'] = false;
    } else {
      this[type][index]['isValid'] = true;
      this[type][index]['value'] = value;
    }
    this.activateNext();
    this.placementState.setStateWithKey('outletReplacement', this.replacement);
  }

  isValidInput(value) {
    return typeof Number(value) == 'number' && Number(value) >= 0;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.vertical?.currentValue) {
      this.activateNext();
    }
  }
  activateNext() {
    let isNexActive = this.checkSumOfKpis();
    this.placementState.setNextButtonState(isNexActive);
  }
  checkSumOfKpis() {
    let verticalTotal = this.vertical
      .map((o) => Number(o.value))
      .reduce((a, b) => a + b);
    this.totalWeightage = verticalTotal;
    verticalTotal = this.totalWeightage;

    let horizontalTotal = this.horizontal
      .map((o) => Number(o.value))
      .reduce((a, b) => a + b);
    this.horizontalWeightage = horizontalTotal;

    let replacementTotal = this.replacement
      .map((o) => Number(o.value))
      .reduce((a, b) => a + b);
    this.replacementWeightge = replacementTotal;

    if (
      Number(verticalTotal) === 100 &&
      Number(horizontalTotal) === 100 &&
      Number(replacementTotal) === 100
    ) {
      return false;
    } else {
      return true;
    }

  }
  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }
  passToParent(value, key) {
    let data: any = {
      value: value,
      key: key,
    };
    this.passFiltersToParentEvnet.emit(data);
  }
}
