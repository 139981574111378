import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getHeaders, handleError, handleResponse } from './service-utilities';
import { CONFIG } from 'src/helper/app-config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  saveUserToken(user_info: any) {
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: 'text' as 'text',
    };
    let payload = {
      user_name: user_info.name,
      email: user_info.username,
      access_token: user_info.access_token,
    };
    return this.http
      .post(
        environment.api_base_url + CONFIG.API.SAVEUSERTOKEN,
        JSON.stringify(payload),
        options
      )
      .pipe(map(handleResponse), catchError(handleError));
  }
}
