<div class="outlet-container">
  <div class="header normal-text-montserrat italic_bold">
    Assign Weightage to KPIs for Outlet Prioritization
  </div>
  <div class="vertical">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState1 = true"
        (closed)="panelOpenState1 = false"
        [expanded]="panelOpenState1"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            VERTICAL EXPANSION
          </mat-panel-title>
          <mat-panel-description>
            Increase cooler capacity in existing outlets
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="dataset-vertical-table">
          <div class="heading-row custom-row">
            <div class="left normal-text-montserrat heading-kpi">KPIs</div>
            <div class="right normal-text-montserrat heading-kpi">
              Weightage
            </div>
          </div>
          <div class="custom-row" *ngFor="let row of vertical; let i = index">
            <div class="left special-left normal-text-montserrat kpi">
              <ng-container>
                <span>
                  {{ row.kpi }}
                </span>
              </ng-container>
              <span
                [tooltip]="HtmlContent"
                [options]="myOptions"
                contentType="template"
                placement="right"
              >
                <ng-template #HtmlContent>
                  <ng-container>
                    <p style="font-size: 12px; margin-bottom: 0px">
                      {{ row.info }}
                    </p>
                  </ng-container>
                </ng-template>
                <mat-icon
                  style="width: 14px; margin-bottom: 8px"
                  class="mat-icon-tooltip"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5.5"
                      cy="5.5"
                      r="5.1"
                      stroke="#333333"
                      stroke-width="0.8"
                    />
                    <path
                      d="M5.75954 8.3508C5.85108 8.32382 5.94866 8.27598 6.05209 8.20875C6.15503 8.14184 6.27002 8.0549 6.39695 7.94829C6.52922 7.84143 6.68381 7.70455 6.86059 7.53787C6.86065 7.53782 6.86071 7.53776 6.86077 7.5377L7.54857 6.90281L7.58809 6.86633L7.54883 6.82957L7.19339 6.49682L7.16058 6.46611L7.12655 6.49547L5.65956 7.7614L5.55246 7.7195L6.2025 4.35725L6.214 4.29776H6.15341H4.14178H4.09932L4.09244 4.33966L4.00925 4.84635L3.99972 4.90445H4.05859H5.40464L4.90201 7.50628C4.89181 7.55223 4.88165 7.60306 4.87153 7.65875L4.87137 7.65873L4.87097 7.66272C4.86576 7.71479 4.86316 7.76686 4.86316 7.81895C4.86316 7.98179 4.91516 8.12053 5.02059 8.23151L5.02055 8.23156L5.02241 8.23333C5.13556 8.34082 5.29597 8.39076 5.49547 8.39076C5.58602 8.39076 5.67411 8.37745 5.75954 8.3508ZM5.75954 8.3508C5.75968 8.35076 5.75981 8.35072 5.75995 8.35067L5.74503 8.30295L5.75914 8.35092C5.75927 8.35088 5.75941 8.35084 5.75954 8.3508ZM6.38759 3.42732L6.38762 3.42735L6.38912 3.42613C6.48012 3.3522 6.53582 3.25043 6.5579 3.1248C6.56806 3.07898 6.5758 3.03772 6.58103 3.00115C6.58635 2.96391 6.58909 2.92875 6.58909 2.89576C6.58909 2.79958 6.54929 2.71612 6.4747 2.64727C6.39488 2.57359 6.26521 2.54326 6.10047 2.54326C5.94324 2.54326 5.81461 2.57714 5.72182 2.65216C5.63002 2.72133 5.57432 2.82117 5.55229 2.94653C5.54213 2.99236 5.53438 3.03362 5.52916 3.07019C5.52384 3.10742 5.52109 3.14258 5.52109 3.17557C5.52109 3.27176 5.5609 3.35521 5.63549 3.42406C5.71531 3.49774 5.84497 3.52807 6.00972 3.52807C6.16665 3.52807 6.29491 3.49684 6.38759 3.42732Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="0.1"
                    />
                  </svg>
                </mat-icon>
              </span>
            </div>
            <div class="right normal-text-montserrat">
              <input
                type="text"
                min="0"
                style="text-align: center"
                [(ngModel)]="row.value"
                (change)="weightageChange($event, 'vertical', i)"
                [value]="row.value"
                class="vertical-input"
                [ngStyle]="{
                  border: row.isValid
                    ? '1px solid rgba(148, 164, 196, 0.4)'
                    : '1px solid red'
                }"
              />

              &nbsp;%
            </div>
          </div>

          <div
            class="custom-row weightage-column"
            style="grid-template-columns: 2fr 1fr; grid-column-gap: 0px"
          >
            <span
              class="normal-text-montserrat"
              style="justify-self: flex-start; font-weight: bold"
            >
              Total Weightage</span
            >
            <div class="right normal-text-montserrat">
              <input
                style="text-align: center; font-weight: bold"
                disabled
                [(ngModel)]="totalWeightage"
                class="vertical-input"
                type="text"
              />
              &nbsp;%
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="horizontal">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState2 = true"
        (closed)="panelOpenState2 = false"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            HORIZONTAL EXPANSION
          </mat-panel-title>
          <mat-panel-description>
            Add new coolers to non-cooler outlets
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="dataset-horizontal-table">
          <div class="heading-row custom-row">
            <div class="left normal-text-montserrat heading-kpi">KPIs</div>
            <div class="right normal-text-montserrat heading-kpi">
              Weightage
            </div>
          </div>
          <div class="custom-row" *ngFor="let row of horizontal; let i = index">
            <ng-container *ngIf="!row.disabled">
              <div class="left special-left normal-text-montserrat kpi">
                <ng-container>
                  <span>
                    {{ row.kpi }}
                  </span>
                </ng-container>
                <span
                  [tooltip]="HtmlContent"
                  [options]="myOptions"
                  contentType="template"
                  placement="right"
                >
                  <ng-template #HtmlContent>
                    <ng-container>
                      <p style="font-size: 12px; margin-bottom: 0px">
                        {{ row.info }}
                      </p>
                    </ng-container>
                  </ng-template>
                  <mat-icon
                    style="width: 14px; margin-bottom: 8px"
                    class="mat-icon-tooltip"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="5.5"
                        cy="5.5"
                        r="5.1"
                        stroke="#333333"
                        stroke-width="0.8"
                      />
                      <path
                        d="M5.75954 8.3508C5.85108 8.32382 5.94866 8.27598 6.05209 8.20875C6.15503 8.14184 6.27002 8.0549 6.39695 7.94829C6.52922 7.84143 6.68381 7.70455 6.86059 7.53787C6.86065 7.53782 6.86071 7.53776 6.86077 7.5377L7.54857 6.90281L7.58809 6.86633L7.54883 6.82957L7.19339 6.49682L7.16058 6.46611L7.12655 6.49547L5.65956 7.7614L5.55246 7.7195L6.2025 4.35725L6.214 4.29776H6.15341H4.14178H4.09932L4.09244 4.33966L4.00925 4.84635L3.99972 4.90445H4.05859H5.40464L4.90201 7.50628C4.89181 7.55223 4.88165 7.60306 4.87153 7.65875L4.87137 7.65873L4.87097 7.66272C4.86576 7.71479 4.86316 7.76686 4.86316 7.81895C4.86316 7.98179 4.91516 8.12053 5.02059 8.23151L5.02055 8.23156L5.02241 8.23333C5.13556 8.34082 5.29597 8.39076 5.49547 8.39076C5.58602 8.39076 5.67411 8.37745 5.75954 8.3508ZM5.75954 8.3508C5.75968 8.35076 5.75981 8.35072 5.75995 8.35067L5.74503 8.30295L5.75914 8.35092C5.75927 8.35088 5.75941 8.35084 5.75954 8.3508ZM6.38759 3.42732L6.38762 3.42735L6.38912 3.42613C6.48012 3.3522 6.53582 3.25043 6.5579 3.1248C6.56806 3.07898 6.5758 3.03772 6.58103 3.00115C6.58635 2.96391 6.58909 2.92875 6.58909 2.89576C6.58909 2.79958 6.54929 2.71612 6.4747 2.64727C6.39488 2.57359 6.26521 2.54326 6.10047 2.54326C5.94324 2.54326 5.81461 2.57714 5.72182 2.65216C5.63002 2.72133 5.57432 2.82117 5.55229 2.94653C5.54213 2.99236 5.53438 3.03362 5.52916 3.07019C5.52384 3.10742 5.52109 3.14258 5.52109 3.17557C5.52109 3.27176 5.5609 3.35521 5.63549 3.42406C5.71531 3.49774 5.84497 3.52807 6.00972 3.52807C6.16665 3.52807 6.29491 3.49684 6.38759 3.42732Z"
                        fill="#333333"
                        stroke="#333333"
                        stroke-width="0.1"
                      />
                    </svg>
                  </mat-icon>
                </span>
              </div>
              <div class="right normal-text-montserrat">
                <input
                  [(ngModel)]="row.value"
                  style="text-align: center"
                  min="0"
                  (change)="horizontalWeightageChange($event, 'horizontal', i)"
                  [value]="row.value"
                  type="text"
                  class="horizontal-input"
                  [ngStyle]="{
                    border: row.isValid
                      ? '1px solid rgba(148, 164, 196, 0.4)'
                      : '1px solid red'
                  }"
                />
                &nbsp; %
              </div>
            </ng-container>
          </div>
          <div
            class="custom-row weightage-column"
            style="grid-template-columns: 2fr 1fr; grid-column-gap: 0px"
          >
            <span
              class="normal-text-montserrat"
              style="justify-self: flex-start; font-weight: bold"
            >
              Total Weightage</span
            >
            <div class="right normal-text-montserrat">
              <input
                style="text-align: center; font-weight: bold"
                [(ngModel)]="horizontalWeightage"
                disabled
                class="vertical-input"
                type="text"
              />
              &nbsp; %
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="replacement">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState3 = true"
        (closed)="panelOpenState3 = false"
      >
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title class="big-header-montserrat">
            REPLACEMENT
          </mat-panel-title>
          <mat-panel-description>
            Replace coolers based on health,age and other relevant factors
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="dataset-horizontal-table">
          <div class="heading-row custom-row">
            <div class="left normal-text-montserrat heading-kpi">KPIs</div>
            <div class="right normal-text-montserrat heading-kpi">
              Weightage
            </div>
          </div>
          <div
            class="custom-row"
            *ngFor="let row of replacement; let i = index"
          >
            <div class="left special-left normal-text-montserrat kpi">
              <span>
                {{ row.kpi }}
              </span>
              <span
                [tooltip]="HtmlContent"
                [options]="myOptions"
                contentType="template"
                placement="right"
              >
                <ng-template #HtmlContent>
                  <ng-container>
                    <p style="font-size: 12px; margin-bottom: 0px">
                      {{ row.info }}
                    </p>
                  </ng-container>
                </ng-template>
                <mat-icon
                  style="width: 14px; margin-bottom: 8px"
                  class="mat-icon-tooltip"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5.5"
                      cy="5.5"
                      r="5.1"
                      stroke="#333333"
                      stroke-width="0.8"
                    />
                    <path
                      d="M5.75954 8.3508C5.85108 8.32382 5.94866 8.27598 6.05209 8.20875C6.15503 8.14184 6.27002 8.0549 6.39695 7.94829C6.52922 7.84143 6.68381 7.70455 6.86059 7.53787C6.86065 7.53782 6.86071 7.53776 6.86077 7.5377L7.54857 6.90281L7.58809 6.86633L7.54883 6.82957L7.19339 6.49682L7.16058 6.46611L7.12655 6.49547L5.65956 7.7614L5.55246 7.7195L6.2025 4.35725L6.214 4.29776H6.15341H4.14178H4.09932L4.09244 4.33966L4.00925 4.84635L3.99972 4.90445H4.05859H5.40464L4.90201 7.50628C4.89181 7.55223 4.88165 7.60306 4.87153 7.65875L4.87137 7.65873L4.87097 7.66272C4.86576 7.71479 4.86316 7.76686 4.86316 7.81895C4.86316 7.98179 4.91516 8.12053 5.02059 8.23151L5.02055 8.23156L5.02241 8.23333C5.13556 8.34082 5.29597 8.39076 5.49547 8.39076C5.58602 8.39076 5.67411 8.37745 5.75954 8.3508ZM5.75954 8.3508C5.75968 8.35076 5.75981 8.35072 5.75995 8.35067L5.74503 8.30295L5.75914 8.35092C5.75927 8.35088 5.75941 8.35084 5.75954 8.3508ZM6.38759 3.42732L6.38762 3.42735L6.38912 3.42613C6.48012 3.3522 6.53582 3.25043 6.5579 3.1248C6.56806 3.07898 6.5758 3.03772 6.58103 3.00115C6.58635 2.96391 6.58909 2.92875 6.58909 2.89576C6.58909 2.79958 6.54929 2.71612 6.4747 2.64727C6.39488 2.57359 6.26521 2.54326 6.10047 2.54326C5.94324 2.54326 5.81461 2.57714 5.72182 2.65216C5.63002 2.72133 5.57432 2.82117 5.55229 2.94653C5.54213 2.99236 5.53438 3.03362 5.52916 3.07019C5.52384 3.10742 5.52109 3.14258 5.52109 3.17557C5.52109 3.27176 5.5609 3.35521 5.63549 3.42406C5.71531 3.49774 5.84497 3.52807 6.00972 3.52807C6.16665 3.52807 6.29491 3.49684 6.38759 3.42732Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="0.1"
                    />
                  </svg>
                </mat-icon>
              </span>
            </div>
            <div class="right normal-text-montserrat">
              <input
                [(ngModel)]="row.value"
                min="0"
                [value]="row.value"
                (change)="replacementWeightageChange($event, 'replacement', i)"
                type="text"
                class="horizontal-input"
                [ngStyle]="{
                  border: row.isValid
                    ? '1px solid rgba(148, 164, 196, 0.4)'
                    : '1px solid red'
                }"
              />
              &nbsp;%
            </div>
          </div>
          <div
            class="custom-row weightage-column"
            style="grid-template-columns: 2fr 1fr; grid-column-gap: 0px"
          >
            <span
              class="normal-text-montserrat"
              style="justify-self: flex-start; font-weight: bold"
            >
              Total Weightage</span
            >
            <div class="right normal-text-montserrat">
              <input
                disabled
                style="text-align: center; font-weight: bold"
                [(ngModel)]="replacementWeightge"
                class="vertical-input"
                type="text"
              />
              &nbsp;%
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
