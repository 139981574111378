import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthResponseComponent } from 'src/components/auth-response/auth-response.component';
import { BaseComponent } from 'src/components/placement-base/base/base.component';
import { ScenarioLibraryComponent } from 'src/components/scenario-library/scenario-library.component';
import { CONFIG } from 'src/helper/app-config';
import { ComparisonComponent } from './comparison/comparison.component';

const routes: Routes = [
  { path: CONFIG.PATH.AUTHRESPONSE, component: AuthResponseComponent },
  {
    path: CONFIG.PATH.SCENARIO_LIBRARY,
    component: ScenarioLibraryComponent,
  },
  {
    path: CONFIG.PATH.COMPARISON,
    component: ComparisonComponent,
  },

  {
    path: CONFIG.PATH.PLACEMENT_BASE + ':screenId',
    pathMatch: 'full',
    component: BaseComponent,
  },
  {
    path: CONFIG.PATH.EMPTY,
    redirectTo: CONFIG.PATH.SCENARIO_LIBRARY,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: CONFIG.PATH.SCENARIO_LIBRARY },
];

const isIframe = window !== window.parent && !window.opener;

console.log('isIframe :: ', isIframe);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation:'enabled'
      //initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
