<div class="cooler-placement-container">
  <div class="vertical">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState1 = true"
        (closed)="panelOpenState1 = false"
        [expanded]="panelOpenState1"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="big-header-montserrat">
            VERTICAL EXPANSION
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-table [dataSource]="vertical">

          <ng-container matColumnDef="channel">
            <mat-header-cell *matHeaderCellDef>Channel </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["channel"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Volume Per Store">
            <mat-header-cell *matHeaderCellDef>
              Volume Per Store (UC)
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Volume Per Store"]) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Revenue Per Store">
            <mat-header-cell *matHeaderCellDef>
              Revenue Per Store ($)
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Revenue Per Store"]) }}
            </mat-cell>
          </ng-container>

       
          <ng-container matColumnDef="Current No Of Doors">
            <mat-header-cell *matHeaderCellDef>
              Current Doors Per Store
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Current No Of Doors"]) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="No of stores">
            <mat-header-cell *matHeaderCellDef>
              Number of Stores
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["No of stores"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Current Store Coverage">
            <mat-header-cell *matHeaderCellDef>
              Current Store Coverage %
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["Current Store Coverage"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Recommended Store Coverage">
            <mat-header-cell *matHeaderCellDef
              >Recommended Store Coverage %
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
          

              <input
                style="width: 87px; height: 35px; font-weight: bold"
                type="number"
                class="store_coverage"
                [value]="element['Recommended Store Coverage']"
                [(ngModel)]="element['Recommended Store Coverage']"
              />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Recommended Door Number">
            <mat-header-cell *matHeaderCellDef
              >Recommended Doors Per Store
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <input
                style="width: 87px; height: 35px; font-weight: bold"
                type="number"
                class="store_coverage"
                [value]="element['Recommended Door Number']"
                [(ngModel)]="element['Recommended Door Number']"
              />
            </mat-cell>
          </ng-container>

          <mat-header-row
            style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
            class="heading-row"
            *matHeaderRowDef="verticalData.verticalColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: verticalData.verticalColumns">
          </mat-row>
        </mat-table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="horizontal">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState2 = true"
        (closed)="panelOpenState2 = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="big-header-montserrat">
            HORIZONTAL EXPANSION
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-table [dataSource]="horizontal">
       
          <ng-container matColumnDef="channel">
            <mat-header-cell *matHeaderCellDef>Channel </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["channel"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Volume Per Store">
            <mat-header-cell *matHeaderCellDef>
              Volume Per Store (UC)
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Volume Per Store"]) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Revenue Per Store">
            <mat-header-cell *matHeaderCellDef>
              Revenue Per Store ($)
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Revenue Per Store"]) }}
            </mat-cell>
          </ng-container>

       
          <ng-container matColumnDef="Current No Of Doors">
            <mat-header-cell *matHeaderCellDef>
              Current Doors Per Store
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ formatingData(element["Current No Of Doors"]) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="No of stores">
            <mat-header-cell *matHeaderCellDef>
              Number of Stores
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["No of stores"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Current Store Coverage">
            <mat-header-cell *matHeaderCellDef>
              Current Store Coverage %
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element["Current Store Coverage"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Recommended Store Coverage">
            <mat-header-cell *matHeaderCellDef
              >Recommended Store Coverage %
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <input
                style="width: 87px; height: 35px; font-weight: bold"
                type="number"
                class="store_coverage"
                [value]="element['Recommended Store Coverage']"
                [(ngModel)]="element['Recommended Store Coverage']"
              />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Recommended Door Number">
            <mat-header-cell *matHeaderCellDef
              >Recommended Doors Per Store
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <input
                style="width: 87px; height: 35px; font-weight: bold"
                type="number"
                class="store_coverage"
                [value]="element['Recommended Door Number']"
                [(ngModel)]="element['Recommended Door Number']"
              />
            </mat-cell>
          </ng-container>

          <mat-header-row
            style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
            class="heading-row"
            *matHeaderRowDef="horizontalData.horizontalColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: horizontalData.horizontalColumns"
          >
          </mat-row>
        </mat-table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="replacement">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState3 = true"
        (closed)="panelOpenState3 = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="big-header-montserrat">
            REPLACEMENT
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="replacement-wrapper">
          <div class="filters-wrapper">
            <div class="age-cooler-filters">
              <ng-container *ngFor="let obj of ageCoolerFilters; let i = index">
                <div class="product input-box">
                  <div class="custom-label">{{ obj.name }}</div>
                  <div
                    [ngClass]="{
                      border_red: obj.filterIcon === 'keyboard_arrow_up'
                    }"
                    [matMenuTriggerFor]="a.menu"
                    (click)="
                      iconClick(
                        'ageCooler',
                        'keyboard_arrow_down',
                        'product',
                        'up',
                        i
                      )
                    "
                    class="category filter m-bottom-right"
                  >
                    <div>
                      <span
                        class="place-holder-text"
                        *ngIf="!obj.selectedFilters.length"
                      >
                        Select {{ obj.name }}
                      </span>
                      <ng-container>
                        <b
                          class="normal-text-montserrat"
                          style="font-weight: 600"
                        >
                          {{ obj.selectedFilters }}
                        </b>
                      </ng-container>
                    </div>
                    <div class="icon">
                      <mat-icon style="cursor: pointer" class="info-icon">
                        <app-menu-buttons
                          class="menu-display"
                          [dropDownValues]="obj.filters"
                          [changeFunc]="filterChange.bind(this)"
                          #a="menuInOtherComponent"
                          [downFunc]="downArrows.bind(this)"
                          [applyFilters]="applyFilters.bind(this)"
                          [clearAll]="clearAll.bind(this)"
                          [name]="obj.name"
                          [showAll]="showAll"
                          [customWidth]="ageCoolerfilterWidth"
                        ></app-menu-buttons>
                        <ng-container
                          *ngIf="obj.filterIcon === 'keyboard_arrow_down'"
                        >
                          <app-down-arrow></app-down-arrow>
                        </ng-container>
                        <ng-container
                          *ngIf="obj.filterIcon === 'keyboard_arrow_up'"
                        >
                          <app-uparrow></app-uparrow>
                        </ng-container>
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="second-row">
              <div class="region-channel-base-filters">
                <ng-container
                  *ngFor="let obj of regionChannelbaseFilters; let i = index"
                >
                  <div class="product input-box">
                    <div class="custom-label">{{ obj.name }}</div>
                    <div
                      [ngClass]="{
                        border_red: obj.filterIcon === 'keyboard_arrow_up'
                      }"
                      [matMenuTriggerFor]="a.menu"
                      (click)="
                        iconClick(
                          'regionChannelBase',
                          'keyboard_arrow_down',
                          'product',
                          'up',
                          i
                        )
                      "
                      class="category filter m-bottom-right"
                    >
                      <div>
                        <span
                          class="place-holder-text"
                          *ngIf="!obj.selectedFilters.length"
                        >
                          Select {{ obj.name }}
                        </span>
                        <ng-container>
                          <b
                            class="normal-text-montserrat"
                            style="font-weight: 600"
                          >
                            {{ obj.selectedFilters }}
                          </b>
                        </ng-container>
                      </div>
                      <div class="icon">
                        <mat-icon style="cursor: pointer" class="info-icon">
                          <app-menu-buttons
                            class="menu-display"
                            [dropDownValues]="obj.filters"
                            [changeFunc]="filterChange.bind(this)"
                            #a="menuInOtherComponent"
                            [downFunc]="downArrows.bind(this)"
                            [applyFilters]="applyFilters.bind(this)"
                            [clearAll]="clearAll.bind(this)"
                            [name]="obj.name"
                            [customWidth]="regionChannelBasefilterWidth"
                          ></app-menu-buttons>
                          <ng-container
                            *ngIf="obj.filterIcon === 'keyboard_arrow_down'"
                          >
                            <app-down-arrow></app-down-arrow>
                          </ng-container>
                          <ng-container
                            *ngIf="obj.filterIcon === 'keyboard_arrow_up'"
                          >
                            <app-uparrow></app-uparrow>
                          </ng-container>
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div style="display: none" class="chart">
            <div class="bold-big-header-montserrat">
              Distribution by Age (in Years)
            </div>
            <div class="combo-chart">
              <canvas
                baseChart
                chartType="bar"
                [datasets]="chart.datasets"
                [labels]="chart.labels"
                [options]="chart.options"
                legend="true"
              >
              </canvas>
            </div>
          </div>
          <div class="table-wrap">
            <div class="bold-big-header-montserrat">
              Aggregated Numbers across Region * Channel
            </div>
            <div class="custom-table">
              <mat-table [dataSource]="replacement">

                <ng-container matColumnDef="channel">
                  <mat-header-cell *matHeaderCellDef>Channel </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element["channel"] }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="No of outlets">
                  <mat-header-cell *matHeaderCellDef>
                    Number of Outlets
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element["No of outlets"] }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="No of Doors">
                  <mat-header-cell *matHeaderCellDef>
                    Number of Doors
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element["No of Doors"] }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="region">
                  <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element["region"] }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
                  class="heading-row"
                  *matHeaderRowDef="replacementData.displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="
                    let row;
                    columns: replacementData.displayedColumns
                  "
                >
                </mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
