<div class="cooler-purchase-wrapper">
  <div class="acc-container">
    <div class="header normal-text-montserrat italic_bold custom-label2">
      <span class="asterick">*</span>
      All fields are mandatory
    </div>
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="panelOpenState"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="big-header-montserrat">
            SCENARIO DETAILS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion">
          <div class="acc-title big-header-montserrat"></div>
          <div class="user-inputs">
            <div class="input-box">
              <div class="normal-text-montserrat custom-label2">
                <span class="asterick">*</span>

                Scenario Name
              </div>
              <div class="box">
                <input
                  [ngModel]="scenarioName"
                  placeholder="Enter Scenario Name"
                  (ngModelChange)="scenarioNameChange($event)"
                  (change)="checkUniqueness($event)"
                  type="text"
                  [disabled]="scenarioId | async"
                  [ngClass]="{
                    error_input: !validName || containsSpecialCharacters,
                    normal_input: validName
                  }"
                />
                <div *ngIf="!validName" style="color: red" class="validation">
                  Scenario already Exists
                </div>
                <div
                  *ngIf="containsSpecialCharacters"
                  style="color: red"
                  class="validation"
                >
                  {{ specialCharacters }}
                </div>
              </div>
            </div>

            <ng-container
              *ngFor="let obj of coolerPuchaseFilters; let i = index"
            >
              <div class="product input-box">
                <div class="custom-label2">
                  <span class="asterick">*</span>
                  <span>
                    {{ obj.name }}
                  </span>
                </div>

                <div
                  [ngClass]="{
                    border_red: obj.filterIcon === 'keyboard_arrow_up'
                  }"
                  [matMenuTriggerFor]="a.menu"
                  (click)="
                    iconClick(
                      'shoppertype',
                      'keyboard_arrow_down',
                      'product',
                      'up',
                      i
                    )
                  "
                  style="width: 420px"
                  class="category filter m-bottom-right"
                >
                  <div>
                    <span
                      class="place-holder-text"
                      *ngIf="!obj.selectedFilters.length"
                    >
                      Select {{ obj.name }}
                    </span>
                    <ng-container>
                      <b
                        class="normal-text-montserrat"
                        style="font-weight: 600"
                      >
                        {{ addSpace(obj.selectedFilters) }}
                      </b>
                    </ng-container>
                  </div>
                  <div class="icon">
                    <mat-icon style="cursor: pointer" class="info-icon">
                      <app-menu-buttons
                        class="menu-display"
                        [dropDownValues]="obj.filters"
                        [changeFunc]="
                          obj.single
                            ? filterChangeSingle.bind(this)
                            : filterChange.bind(this)
                        "
                        #a="menuInOtherComponent"
                        [downFunc]="downArrows.bind(this)"
                        [applyFilters]="applyFilters.bind(this)"
                        [clearAll]="clearAll.bind(this)"
                        [name]="obj.name"
                        [showAll]="obj.single"
                        [customWidth]="filterWidth"
                      ></app-menu-buttons>
                      <ng-container
                        *ngIf="obj.filterIcon === 'keyboard_arrow_down'"
                      >
                        <app-down-arrow></app-down-arrow>
                      </ng-container>
                      <ng-container
                        *ngIf="obj.filterIcon === 'keyboard_arrow_up'"
                      >
                        <app-uparrow></app-uparrow>
                      </ng-container>
                    </mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="input-box">
              <div class="box">
                <div class="child-container">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      align-items: center;
                    "
                  >
                    <div class="text-label normal-text-montserrat">
                      <span class="asterick">*</span>
                      Import Purchase Plan
                    </div>

                    <button
                      style="
                        width: fit-content;
                        justify-self: flex-end;
                        margin-right: 8px;
                      "
                      (click)="downloadCsv()"
                      mat-stroked-button
                      color="info"
                    >
                      <mat-icon> file_download </mat-icon>
                      Download Purchase Plan
                    </button>
                  </div>
                  <div (click)="files.click()" class="file-upload">
                    <input
                      #files
                      type="file"
                      hidden
                      id="purchase-plan-input"
                      class="upload"
                      accept=".csv"
                      (change)="changeListener($event.target['files'], $event)"
                    />
                    <ng-container *ngIf="!currentFileName">
                      <mat-icon> file_upload </mat-icon>
                      <div class="upload">Upload Data</div>
                    </ng-container>

                    <ng-container>
                      <div
                        *ngIf="currentFileName"
                        class="currentFile"
                        style="color: rgb(134, 236, 134)"
                      >
                        <b> Current File: </b> {{ currentFileName }}
                      </div>
                    </ng-container>
                  </div>

                  <div class="note">
                    <b>Note:</b>

                    <span>
                      Upload data in the following format as a CSV file</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div style="display: none" class="dataset-table">
    <div class="heading-row custom-row">
      <div class="left normal-text-montserrat">DataSets</div>
      <div class="right normal-text-montserrat">Granularity</div>
    </div>
    <div class="custom-row" *ngFor="let row of data">
      <div class="left normal-text-montserrat">{{ row.key }}</div>
      <div class="right normal-text-montserrat">
        {{ row.value }}
      </div>
    </div>
  </div>
</div>
