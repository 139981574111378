<app-loader [is_show_loader]="showLoader | async"></app-loader>

<div class="base-wrapper">
  <div class="header">
    <div class="stepper">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <h3 style="margin: 0px" class="medium-header-montserrat single-tab">
          <img
            *ngIf="showCompleted(i)"
            src="../../../assets/CDE_Placement_SVGs/Icons/completed.svg"
            alt=""
          />
          <span
            class="tab-number"
            [ngClass]="{
              active_tab: tab === activeTab,
              not_active: tab != activeTab
            }"
            *ngIf="!showCompleted(i)"
          >
            {{ i + 1 }}
          </span>
          <span
            [ngClass]="{
              active_text: tab === activeTab
            }"
            class="medium-header-montserrat"
            style="cursor: pointer"
            (click)="moveLevel(tab)"
          >
            {{ tab }}
          </span>
        </h3>
        <mat-icon *ngIf="i !== 3"> chevron_right </mat-icon>
      </ng-container>
    </div>
    <div class="control-btns">
      <button [disabled]="mode != 'new'" (click)="discard()" b mat-button>
        Discard
      </button>
      <button
        (click)="moveToNextLevel('asDraft', true)"
        [disabled]="isNextDisabled || mode != 'new'"
        mat-button
      >
        Save as draft
      </button>
      <button
        class="next"
        style="background-color: #25d4cf; width: 100px; color: white"
        mat-button
        (click)="
          activeTab === 'Placement Plan' ? activatePlan() : moveToNextLevel()
        "
        [disabled]="isNextDisabled"
        [ngStyle]="{ opacity: isNextDisabled ? 0.5 : 1 }"
      >
        <ng-container *ngIf="activeTab === 'Placement Plan'">
          Submit
        </ng-container>
        <ng-container *ngIf="activeTab != 'Placement Plan'">
          Next
        </ng-container>
      </button>
    </div>
  </div>
  <div class="child-comps-wrap">
    <div class="back-wrap">
      <mat-icon style="font-size: 30px; cursor: pointer" (click)="goBack()">
        arrow_back
      </mat-icon>
    </div>
    <div class="child-comps">
      <app-cooler-purchase *ngIf="activeTab === tabs[0]"></app-cooler-purchase>
      <app-outlet-potential
        [totalWeightage]="outletPotentialData.totalWeightage"
        (passFiltersToParentEvnet)="getDataFromChildOutletPotential($event)"
        *ngIf="activeTab === tabs[1]"
      ></app-outlet-potential>

      <app-cooler-placement
        *ngIf="activeTab === tabs[2]"
      ></app-cooler-placement>
      <app-placement-plan *ngIf="activeTab === tabs[3]"></app-placement-plan>
    </div>
  </div>

  <div></div>
</div>
