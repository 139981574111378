import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FILTER_KEYS, STATIC_DATA } from 'src/shared/data-store';
import { cloneDeep } from 'lodash';
import { Output, EventEmitter } from '@angular/core';
import {
  checkForSpecialCharacters,
  createObjectWithChecked,
  getCheckedValues,
  getCheckIds,
  getRequiredObj,
  hasOnlySpaces,
  hasSpecialCharacters,
  makeFalse,
  selectAll,
} from 'src/shared/common-methods';
import { MatMenuTrigger } from '@angular/material/menu';
import { Papa } from 'ngx-papaparse';
import { DataService } from 'src/app/services/data.service';
import { CONFIG } from 'src/helper/app-config';
import { PlacementStateService } from 'src/app/placement-state.service';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cooler-purchase',
  templateUrl: './cooler-purchase.component.html',
  styleUrls: ['./cooler-purchase.component.scss'],
})
export class CoolerPurchaseComponent implements OnInit, OnDestroy {
  token = 'abcd';
  upload_message='';
  filterWidth = '420px';
  constructor(
    private papa: Papa,
    private dataService: DataService,
    private placementState: PlacementStateService
  ) {}
  afuConfig = {
    multiple: false,
    formatsAllowed: '.jpg,.png',
    maxSize: '1',
    uploadAPI: {
      url: 'https://example-file-upload-api',
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        page: '1',
      },
      responseType: 'blob',
      withCredentials: false,
    },
    theme: 'dragNDrop',
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    fileNameIndex: true,
    autoUpload: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit',
    },
  };
  resetVar;
  panelOpenState: boolean = true;
  coolerPuchaseFilters;
  scenarioName = '';
  data: any = [];
  @ViewChildren(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('files') myInputVariable;
  currentFileName = this.dataService.getFileName();
  mode = '';
  purchasePlan = [];
  validName = true;
  scenarioId: Observable<any> = this.placementState.scenarioId;
  specialCharacters = 'Scenario Name should not contain special characters';
  containsSpecialCharacters = false;
  subscriptionsToUnsubscribe = {};
  ngOnInit(): void {
    this.subscriptionsToUnsubscribe['1'] =
      this.placementState.scenarioName.subscribe((name) => {
        this.scenarioName = name;
      });
    this.subscriptionsToUnsubscribe['2'] = this.placementState.mode.subscribe(
      (observer) => {
        this.mode = observer;
        if (this.mode === 'edit' || this.mode === 'draft') {
          this.currentFileName = '';
        }
      }
    );
    this.subscriptionsToUnsubscribe['3'] =
      this.placementState.coolerPurchaseFilters.subscribe((observer) => {
        this.coolerPuchaseFilters = observer;
      });
    if (!this.coolerPuchaseFilters[0]['filters'].length) {
      this.placementState.getCoolerPurchaseRegions();
    }
    if (this.mode === 'new') {
      this.placementState.setStateWithKey('purchasePlanData', []);
    }
    this.subscriptionsToUnsubscribe['4'] =
      this.placementState.purchasePlanData.subscribe((data) => {
        this.purchasePlan = data;
      });
  }

  downArrows() {
    this.coolerPuchaseFilters = this.coolerPuchaseFilters.map((obj) => {
      obj.filterIcon = 'keyboard_arrow_down';
      return obj;
    });
  }
  downloadCsv() {
    this.dataService
      .getRequest(CONFIG.API.DOWNLOAD_CSV)
      .then((res: any) => {
        res = JSON.parse(res);
        // let regionObj = {};
        // let regionColumns = Object.keys(res['data']).filter(
        //   (key) => key != 'Channel' && key != 'Type'
        // );
        // regionColumns.map((column) => {
        //   regionObj[column] = res['data'][column];
        // });

        // let finalData = [];
        // res['data']['Channel'].map((channel, i) => {
        //   let obj = {};
        //   obj['Channel'] = channel;
        //   obj['Type'] = res['data']['Type'][i];
        //   obj = { ...obj, ...regionObj };
        //   finalData.push(obj);
        // });

        // console.log(finalData);

        let revertCsv = this.papa.unparse(res['data']);
        console.log(revertCsv);

        var blob = new Blob([revertCsv], { type: 'text/csv;charset=utf-8;' });

        var link = document.createElement('a');
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'Purchase Plan.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  scenarioNameChange(e) {
    console.log(e);

    this.placementState.setScenarioName(e);
    if (!checkForSpecialCharacters(e)) {
      this.containsSpecialCharacters = true;
    } else {
      this.containsSpecialCharacters = false;
    }

    if (!e) {
      this.containsSpecialCharacters = false;
    }

    if (this.checkDisability()) {
      this.placementState.setNextButtonState(true);
    } else {
      this.placementState.setNextButtonState(false);
    }
  }
  checkUniqueness(val) {
    if (!val.target.value) {
      this.validName = true;
    }
    // if (
    //   !hasSpecialCharacters(val.target.value) ||
    //   !hasOnlySpaces(val.target.value)
    // ) {
    //   this.containsSpecialCharacters = true;
    //   this.placementState.setNextButtonState(true);
    //   return;
    // } else {
    //   this.containsSpecialCharacters = false;
    // }

    if (val.target.value) {
      this.dataService
        .postRequest2(
          { scenario_name: val.target.value },
          CONFIG.API.COOLER_PURCHASE.UNIQUENESS
        )
        .then((res) => {
          console.log(res);
          if (res['status'] === 'success') {
            console.log(res);
            this.validName = true;
          } else {
            this.validName = false;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  checkDisability() {
    let channel = getRequiredObj(
      FILTER_KEYS.CHANNEL,
      this.coolerPuchaseFilters
    )[0]['selectedIds'];
    let region = getRequiredObj(
      FILTER_KEYS.REGION,
      this.coolerPuchaseFilters
    )[0]['selectedIds'];

    if (channel && !channel.length || !region.length) {return true;}

    if (!this.purchasePlan.length) return true;

    if (!this.validName || this.containsSpecialCharacters) return true;
    if (!this.scenarioName.length) return true;
    // if (
    //   this.scenarioName ||
    //   !channel?.length ||
    //   !region?.length ||
    //   this.purchasePlan.length ||
    //   this.validName ||
    //   !this.containsSpecialCharacters
    // ) {
    //   return true;
    // }
  }
  ngOnDestroy(): void {
    for (let key in this.subscriptionsToUnsubscribe) {
      this.subscriptionsToUnsubscribe[key].unsubscribe();
    }
  }

  changeListener(files: FileList, e) {
    // if (!this.validName || this.containsSpecialCharacters) {
    //   return;
    // }
    console.log(e.target.files[0].name);
    this.currentFileName = e.target.files.length ? e.target.files[0].name : '';
    this.dataService.setFilename(this.currentFileName);
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let reader: FileReader = new FileReader();
      console.log(reader);
      reader.readAsText(file);
      reader.onload = () => {
        let csv: string = reader.result as string;
        this.papa.parse(csv, {
          skipEmptyLines: true,
          header: true,
          complete: (result) => {
            //finding column names
            let column_values=Object.keys(result['data'][0])
            let render_output=false;
            //finding dynamic region names
            let regions = result.meta.fields.filter(
              (val) =>
                val != 'Channel' && val != 'Type' && val != 'Channel Total'
            );

            let expected_column_values=['Channel','Type','Channel Total',...regions]
            let column_mismatch=[]
            //check if another csv other than purchace plan csv is uploaded
            if(expected_column_values.length == column_values.length){
            for(let item=0;item<expected_column_values.length;item++){
              if(column_values.includes(expected_column_values[item])){
                column_mismatch.push('true')
              }
              else{
                column_mismatch.push('false')
              }
            }
          }

            //check if all cells are zero
            let all_cell_zero=[]
            for(let i=0;i<result.data.length;i++){
              for(let reg=0;reg<regions.length;reg++){
                if(result.data[i][regions[reg]] == '0' && result.data[i]["Channel Total"] == "0"){
                  all_cell_zero.push('true')                  
                }
                else{
                  all_cell_zero.push('false')
                }
              }
            }

            //check if any cell in region row has value other than 0
            for(let i=0;i<result.data.length;i++){
              let row_wise_region_zero=false;
              for(let reg=0;reg<regions.length;reg++){
                if(result.data[i][regions[reg]] != '0'){
                  row_wise_region_zero=true;
                }
              }

              if(expected_column_values.length != column_values.length){
                this.currentFileName=''
                this.purchasePlan=[]
                Swal.fire({
                  icon: "warning",
                  title: "Please download purchase plan and upload in the same format",
                  showConfirmButton: false,
                  timer: 4000,
                })
                e.target.value=''
                render_output=true

              }
              else if(column_mismatch.includes('false')){
                this.currentFileName=''
                this.purchasePlan=[]
                this.placementState.setStateWithKey('purchasePlanData', []);
                Swal.fire({
                  icon: "warning",
                  title: "Please download purchase plan and upload in the same format",
                  showConfirmButton: false,
                  timer: 4000,
                })
                e.target.value=''
                render_output=true
              }
              else if(
               !all_cell_zero.includes('false')
              ){
                this.currentFileName=''
                this.purchasePlan=[]
                Swal.fire({
                  icon: "warning",
                  title: 'Channel Total and Region values cannot be zero',
                  showConfirmButton: false,
                  timer: 4000,
                })
                
                e.target.value=''
                render_output=true
              }
           
                else if(result.data[i]["Channel Total"] == 0 && row_wise_region_zero==true){
                this.currentFileName=''
                this.purchasePlan=[]
                Swal.fire({
                  icon: "warning",
                  title: 'Channel Total cannot be zero',
                  showConfirmButton: false,
                  timer: 4000,
                })
                e.target.value=''
                render_output=true
                
              }
              else if(result.data[i]["Channel Total"] != 0){
                let region_zero=[]
                this.purchasePlan=[]
                for(let reg=0;reg<regions.length;reg++){
                  if(result.data[i][regions[reg]] == '0'){
                    region_zero.push('false')
                  }
                  else{
                    region_zero.push('true')
                  }
                }
                if(!region_zero.includes('true')){
                this.currentFileName=''
                Swal.fire({
                  icon: "warning",
                  title: 'Please fill values for regions',
                  showConfirmButton: false,
                  timer: 4000,
                })
                e.target.value=''
                render_output=true

                } 
              }
              else{

              }
            }
            let revertCsv = this.papa.unparse(result.data);
            let finalArr = [];
            regions.map((region) => {
              result.data.map((obj) => {
                if (!obj[region]) {
                  delete obj[region];
                }
                if (obj[region]) {
                  let o = {
                    Channel: obj['Channel'],
                    [obj['Type']]: obj[region],
                    'Channel Total': obj['Channel Total'],
                    Region: region,
                  };

                  finalArr = [...finalArr, o];
                }
              });
            });
            if(!render_output){
            this.placementState.setStateWithKey('purchasePlanData', finalArr);
            }
            if (this.checkDisability()) {
              this.placementState.setNextButtonState(true);
            } else {
              this.placementState.setNextButtonState(false);
            }
            this.myInputVariable.nativeElement.value = '';
          },
        });
      };
    }
  }
  iconClick(type, key, val, resize?, index?) {
    if (type === 'shoppertype') {
      if (
        this.coolerPuchaseFilters[index]['filterIcon'] === 'keyboard_arrow_down'
      ) {
        this.coolerPuchaseFilters[index]['filterIcon'] = 'keyboard_arrow_up';
      } else {
        this.coolerPuchaseFilters[index]['filterIcon'] = 'keyboard_arrow_down';
      }
      return;
    }
  }

  mapFilters() {
    this.coolerPuchaseFilters = this.coolerPuchaseFilters.map((obj) => {
      obj.filters = createObjectWithChecked(obj.filters);
      return obj;
    });
  }
  filterChange(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      arr[i].checked = !arr[i].checked;
    }
  }
  filterChangeSingle(event, i, type?, arr?) {
    if (type === 'All') {
      arr = selectAll(arr, event);
    } else {
      arr[i].checked = !arr[i].checked;
      makeFalse(arr, i);
    }
  }
  addSpace = (arr) => {
    return arr.map((str, i) => {
      let st;
      if (arr.length === 1) {
        st = str;
      } else if (arr.length === i + 1) {
        st = ' ' + str + ' ';
      } else {
        st = ' ' + str + ' ';
      }
      return st;
    });
  };

  applyFilters(name) {
    this.coolerPuchaseFilters = this.coolerPuchaseFilters.map((obj) => {
      obj.selectedFilters = getCheckedValues(obj.filters);
      obj.selectedIds = getCheckIds(obj.filters);
      return obj;
    });
    if (this.checkDisability()) {
      this.placementState.setNextButtonState(true);
    } else {
      this.placementState.setNextButtonState(false);
    }
    this.placementState.setCoolerPurchaseFilters(this.coolerPuchaseFilters);
    this.trigger['_results'].map((obj) => {
      obj.closeMenu();
    });
    if (name === 'Region') {
      this.placementState.getCoolerPurchaseChannels();
    }
  }
  clearAll = (arr) => {
    arr.forEach((element) => {
      if (!element.disabled) {
        element.checked = false;
      }
    });
  };
  fileSelected(e) {
    console.log(e);
  }
  docUpload(e) {
    console.log(e);
  }
}
