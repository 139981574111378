<div class="placement-container">
  <div *ngIf="isAggregatedActive" class="placement-container-inner-wrap">
    <div class="plan-summary-wrap common-box">
      <div class="tab-label medium-header-montserrat">Plan Summary</div>
      <ng-container>
        <div class="small-text-montserrat">Current Cooler Universe</div>

        <ng-container *ngIf="planSummaryTotal">
          <div
            class="bold-big-header-montserrat"
            style="align-self: center; font-size: 30px"
          >
            {{ formatingData(planSummaryTotal) }}
          </div>
        </ng-container>

        <ng-container *ngIf="!planSummaryTotal">
          <div
            class="bold-big-header-montserrat"
            style="align-self: center; font-size: 30px"
          >
            0
          </div>
        </ng-container>
      </ng-container>
      <div class="plan-summary">
        <ng-container *ngFor="let data of planSummaryData">
          <div class="box-row">
            <div class="col1 small-text-montserrat">
              {{ data.key }}
            </div>
            <div class="col2 bold-big-header-montserrat">
              {{ formatingData(data.value) }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="execution-impact-wrap common-box">
      <div class="tab-label medium-header-montserrat">Execution Impact</div>
      <div class="execution-impact">
        <div class="execution-box-row">
          <div class="col1 small-text-montserrat">Metrics</div>
          <div
            style="justify-self: flex-start"
            class="col1 small-text-montserrat"
          >
            Old
          </div>
          <div
            style="justify-self: flex-start"
            class="col1 small-text-montserrat"
          >
            New
          </div>
        </div>
        <ng-container *ngFor="let data of executionImpactData">
          <div class="execution-box-row">
            <div class="col1 small-text-montserrat">
              {{ data.key }}
            </div>
            <div
              class="col2 bold-big-header-montserrat"
              style="justify-self: flex-start"
            >
              <ng-container *ngIf="percentageRows[data.key]">
                {{ formatingData(data.old) }}%
              </ng-container>

              <ng-container *ngIf="!percentageRows[data.key]">
                {{ formatingData(data.old) }}
              </ng-container>
            </div>
            <div
              class="col2 bold-big-header-montserrat"
              style="
                display: grid;
                grid-template-columns: 1fr 0.8fr;
                grid-column-gap: 2px;
                justify-self: flex-start;
              "
            >
              <span>
                <ng-container *ngIf="percentageRows[data.key]">
                  {{ formatingData(data.new) }}%
                </ng-container>
                <ng-container *ngIf="!percentageRows[data.key]">
                  {{ formatingData(data.new) }}
                </ng-container>
              </span>
              <span
                [ngClass]="{
                  green_background:
                    changeToNumber2(data['diff'], data['key']) >= 0,
                  red_background: changeToNumber2(data['diff'], data['key']) < 0
                }"
                style="display: flex"
              >
                <ng-container
                  *ngIf="changeToNumber2(data['diff'], data['key']) >= 0"
                >
                  <img
                    src="../../../assets/CDE_Placement_SVGs/Icons/Arrow up.svg "
                    alt=""
                    height="15px"
                  />
                </ng-container>

                <ng-container
                  *ngIf="changeToNumber2(data['diff'], data['key']) < 0"
                >
                  <img
                    height="13px"
                    width="14px"
                    src="../../../assets/CDE_Placement_SVGs/Icons/Down.svg"
                    alt=""
                  />
                </ng-container>
                {{ formatingData(changeToNumber(data["diff"], data["key"])) }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="business-impact-wrap common-box">
      <div class="tab-label medium-header-montserrat">
        Business Impact ($)
        <span
          [tooltip]="HtmlContent"
          [options]="myOptions"
          contentType="template"
          placement="right"
        >
          <ng-template #HtmlContent>
            <ng-container>
              <p style="font-size: 12px; margin-bottom: 0px">
                Revenue considered for 1 Year only
              </p>
            </ng-container>
          </ng-template>
          <mat-icon
            style="width: 14px; margin-bottom: 8px"
            class="mat-icon-tooltip"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="5.5"
                cy="5.5"
                r="5.1"
                stroke="#333333"
                stroke-width="0.8"
              />
              <path
                d="M5.75954 8.3508C5.85108 8.32382 5.94866 8.27598 6.05209 8.20875C6.15503 8.14184 6.27002 8.0549 6.39695 7.94829C6.52922 7.84143 6.68381 7.70455 6.86059 7.53787C6.86065 7.53782 6.86071 7.53776 6.86077 7.5377L7.54857 6.90281L7.58809 6.86633L7.54883 6.82957L7.19339 6.49682L7.16058 6.46611L7.12655 6.49547L5.65956 7.7614L5.55246 7.7195L6.2025 4.35725L6.214 4.29776H6.15341H4.14178H4.09932L4.09244 4.33966L4.00925 4.84635L3.99972 4.90445H4.05859H5.40464L4.90201 7.50628C4.89181 7.55223 4.88165 7.60306 4.87153 7.65875L4.87137 7.65873L4.87097 7.66272C4.86576 7.71479 4.86316 7.76686 4.86316 7.81895C4.86316 7.98179 4.91516 8.12053 5.02059 8.23151L5.02055 8.23156L5.02241 8.23333C5.13556 8.34082 5.29597 8.39076 5.49547 8.39076C5.58602 8.39076 5.67411 8.37745 5.75954 8.3508ZM5.75954 8.3508C5.75968 8.35076 5.75981 8.35072 5.75995 8.35067L5.74503 8.30295L5.75914 8.35092C5.75927 8.35088 5.75941 8.35084 5.75954 8.3508ZM6.38759 3.42732L6.38762 3.42735L6.38912 3.42613C6.48012 3.3522 6.53582 3.25043 6.5579 3.1248C6.56806 3.07898 6.5758 3.03772 6.58103 3.00115C6.58635 2.96391 6.58909 2.92875 6.58909 2.89576C6.58909 2.79958 6.54929 2.71612 6.4747 2.64727C6.39488 2.57359 6.26521 2.54326 6.10047 2.54326C5.94324 2.54326 5.81461 2.57714 5.72182 2.65216C5.63002 2.72133 5.57432 2.82117 5.55229 2.94653C5.54213 2.99236 5.53438 3.03362 5.52916 3.07019C5.52384 3.10742 5.52109 3.14258 5.52109 3.17557C5.52109 3.27176 5.5609 3.35521 5.63549 3.42406C5.71531 3.49774 5.84497 3.52807 6.00972 3.52807C6.16665 3.52807 6.29491 3.49684 6.38759 3.42732Z"
                fill="#333333"
                stroke="#333333"
                stroke-width="0.1"
              />
            </svg>
          </mat-icon>
        </span>
      </div>
      <ng-container>
        <div class="small-text-montserrat">Inc. Revenue</div>
        <ng-container *ngIf="businessImpactTotal">
          <div
            class="bold-big-header-montserrat"
            style="align-self: center; font-size: 30px"
          >
            {{ formatingData2(businessImpactTotal) }}
          </div>
        </ng-container>

        <ng-container *ngIf="!businessImpactTotal">
          <div
            class="bold-big-header-montserrat"
            style="align-self: center; font-size: 30px"
          >
            0
          </div>
        </ng-container>
      </ng-container>
      <div class="business-impact">
        <ng-container *ngFor="let data of businessImpactData">
          <div class="box-row">
            <ng-container *ngIf="businessImpactMapper[data.key]">
              <div class="col1 small-text-montserrat">
                {{ businessImpactMapper[data.key] }}
              </div>
            </ng-container>
            <ng-container *ngIf="!businessImpactMapper[data.key]">
              <div class="col1 small-text-montserrat">
                {{ data.key }}
              </div>
            </ng-container>
            <div class="col2 bold-big-header-montserrat">
              {{ formatingData(data.value) }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="charts-wrap common-box">
      <div class="output-filters">
        <ng-container *ngFor="let obj of outputFilters; let i = index">
          <div
            [ngClass]="{
              disabled: obj.hide
            }"
            #filter
            class="product input-box"
            style="margin-right: 20px; display: flex; align-items: center"
          >
            <div class="custom-label">{{ obj.name }}:</div>
            <div
              [ngClass]="{
                border_red: obj.filterIcon === 'keyboard_arrow_up'
              }"
              [matMenuTriggerFor]="a.menu"
              (click)="
                iconClick(
                  'ageCooler',
                  'keyboard_arrow_down',
                  'product',
                  'up',
                  i
                )
              "
              class="category filter m-bottom-right"
            >
              <div>
                <span
                  class="place-holder-text"
                  *ngIf="!obj.selectedFilters.length"
                >
                  Select {{ obj.name }}
                </span>
                <ng-container>
                  <b class="normal-text-montserrat" style="font-weight: 600">
                    {{ obj.selectedFilters }}
                  </b>
                </ng-container>
              </div>
              <div class="icon">
                <mat-icon style="cursor: pointer" class="info-icon">
                  <app-menu-buttons
                    class="menu-display"
                    [dropDownValues]="obj.filters"
                    [changeFunc]="outputFilterChange.bind(this)"
                    #a="menuInOtherComponent"
                    [downFunc]="downArrows.bind(this)"
                    [applyFilters]="outputApplyFilters.bind(this)"
                    [clearAll]="clearAll.bind(this)"
                    [showAll]="showAll"
                    [name]="obj.name"
                    [customWidth]="200 + 'px'"
                  ></app-menu-buttons>
                  <ng-container
                    *ngIf="obj.filterIcon === 'keyboard_arrow_down'"
                  >
                    <app-down-arrow></app-down-arrow>
                  </ng-container>
                  <ng-container *ngIf="obj.filterIcon === 'keyboard_arrow_up'">
                    <app-uparrow></app-uparrow>
                  </ng-container>
                </mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="new-coolers common-chart">
        <div class="chart-label medium-header-montserrat">
          New coolers addition by channel type
        </div>
        <div class="chart chart_width">
          <ngx-charts-bar-horizontal-2d
            [scheme]="newCoolersconfig.colorScheme"
            [schemeType]="newCoolersconfig.schemeType"
            [results]="newCoolersData"
            [barPadding]="20"
            [gradient]="newCoolersconfig.gradient"
            [xAxis]="newCoolersconfig.showXAxis"
            [yAxis]="newCoolersconfig.showYAxis"
            [showXAxisLabel]="newCoolersconfig.showXAxisLabel"
            [showYAxisLabel]="newCoolersconfig.showYAxisLabel"
            [xAxisLabel]="newCoolersconfig.xAxisLabel"
            [yAxisLabel]="newCoolersconfig.yAxisLabel"
          >
          </ngx-charts-bar-horizontal-2d>
        </div>
      </div>
      <div class="roi-distribution common-chart">
        <div
          class="chart-label medium-header-montserrat"
          style="display: flex; align-items: flex-start"
        >
          Inc. Revenue vs Investment
          <span
            style="position: relative; bottom: 8px; left: 6px"
            [tooltip]="HtmlContent"
            [options]="myOptions"
            contentType="template"
            placement="right"
          >
            <ng-template #HtmlContent>
              <ng-container>
                <p style="font-size: 12px; margin-bottom: 0px">
                  Revenue considered for 1 Year only
                </p>
              </ng-container>
            </ng-template>
            <mat-icon
              style="width: 14px; margin-bottom: 8px"
              class="mat-icon-tooltip"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="5.5"
                  cy="5.5"
                  r="5.1"
                  stroke="#333333"
                  stroke-width="0.8"
                />
                <path
                  d="M5.75954 8.3508C5.85108 8.32382 5.94866 8.27598 6.05209 8.20875C6.15503 8.14184 6.27002 8.0549 6.39695 7.94829C6.52922 7.84143 6.68381 7.70455 6.86059 7.53787C6.86065 7.53782 6.86071 7.53776 6.86077 7.5377L7.54857 6.90281L7.58809 6.86633L7.54883 6.82957L7.19339 6.49682L7.16058 6.46611L7.12655 6.49547L5.65956 7.7614L5.55246 7.7195L6.2025 4.35725L6.214 4.29776H6.15341H4.14178H4.09932L4.09244 4.33966L4.00925 4.84635L3.99972 4.90445H4.05859H5.40464L4.90201 7.50628C4.89181 7.55223 4.88165 7.60306 4.87153 7.65875L4.87137 7.65873L4.87097 7.66272C4.86576 7.71479 4.86316 7.76686 4.86316 7.81895C4.86316 7.98179 4.91516 8.12053 5.02059 8.23151L5.02055 8.23156L5.02241 8.23333C5.13556 8.34082 5.29597 8.39076 5.49547 8.39076C5.58602 8.39076 5.67411 8.37745 5.75954 8.3508ZM5.75954 8.3508C5.75968 8.35076 5.75981 8.35072 5.75995 8.35067L5.74503 8.30295L5.75914 8.35092C5.75927 8.35088 5.75941 8.35084 5.75954 8.3508ZM6.38759 3.42732L6.38762 3.42735L6.38912 3.42613C6.48012 3.3522 6.53582 3.25043 6.5579 3.1248C6.56806 3.07898 6.5758 3.03772 6.58103 3.00115C6.58635 2.96391 6.58909 2.92875 6.58909 2.89576C6.58909 2.79958 6.54929 2.71612 6.4747 2.64727C6.39488 2.57359 6.26521 2.54326 6.10047 2.54326C5.94324 2.54326 5.81461 2.57714 5.72182 2.65216C5.63002 2.72133 5.57432 2.82117 5.55229 2.94653C5.54213 2.99236 5.53438 3.03362 5.52916 3.07019C5.52384 3.10742 5.52109 3.14258 5.52109 3.17557C5.52109 3.27176 5.5609 3.35521 5.63549 3.42406C5.71531 3.49774 5.84497 3.52807 6.00972 3.52807C6.16665 3.52807 6.29491 3.49684 6.38759 3.42732Z"
                  fill="#333333"
                  stroke="#333333"
                  stroke-width="0.1"
                />
              </svg>
            </mat-icon>
          </span>
        </div>
        <div *ngIf="roiChartData.length" class="chart chart_width">
          <ngx-charts-bar-vertical
            [scheme]="roiChartConfig.colorScheme"
            [results]="roiChartData"
            [gradient]="roiChartConfig.gradient"
            [xAxis]="roiChartConfig.showXAxis"
            [yAxis]="roiChartConfig.showYAxis"
            [showXAxisLabel]="roiChartConfig.showXAxisLabel"
            [showYAxisLabel]="roiChartConfig.showYAxisLabel"
            [xAxisLabel]="roiChartConfig.xAxisLabel"
            [yAxisLabel]="roiChartConfig.yAxisLabel"
            [barPadding]="roiChartConfig.barPadding"
          >
          </ngx-charts-bar-vertical>
        </div>
      </div>
      <div class="reasons common-chart">
        <div class="chart-label medium-header-montserrat">
          Reasons to place coolers
        </div>
        <div *ngIf="reasonChartData.length" class="chart chart_width">
          <ngx-charts-bar-vertical
            [scheme]="reasonsChartConfig.colorScheme"
            [results]="reasonChartData"
            [gradient]="reasonsChartConfig.gradient"
            [xAxis]="reasonsChartConfig.showXAxis"
            [yAxis]="reasonsChartConfig.showYAxis"
            [showXAxisLabel]="reasonsChartConfig.showXAxisLabel"
            [showYAxisLabel]="reasonsChartConfig.showYAxisLabel"
            [xAxisLabel]="reasonsChartConfig.xAxisLabel"
            [yAxisLabel]="reasonsChartConfig.yAxisLabel"
            [barPadding]="reasonsChartConfig.barPadding"
          >
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
    <div class="note-wrap normal-text-montserrat">
      You can also check outlet level view information by clicking
      <span
        style="color: #e61a27; cursor: pointer"
        (click)="toggleAggregated()"
      >
        here
      </span>
    </div>
  </div>
  <div
    [ngClass]="{
      outlet_view_wrap: !isAggregatedActive
    }"
  >
    <div class="outlet-inner-wrap">
      <div
        *ngIf="!isAggregatedActive"
        class="tab-label medium-header-montserrat"
      >
        OUTLET LEVEL OUTPUT SAMPLE
      </div>

      <mat-sidenav-container fxFlex="0 1 auto">
        <mat-sidenav-content>
          <div
            [hidden]="isAggregatedActive"
            class="table-wrap table-container mat-elevation-z8 m-12 example-container"
          >
            <mat-table
              class="mat-table"
              style="border: 1px solid lightgray; border-bottom: none"
              [dataSource]="aggregatedDataSource"
              matSort
            >
              <ng-container
                *ngFor="let column of aggregatedDisplayedColumns; let i = index"
                [cdkColumnDef]="column"
              >
                <mat-header-cell class="table-column-160" *cdkHeaderCellDef>
                  {{ [column] }}
                </mat-header-cell>
                <mat-cell class="table-column-160" *cdkCellDef="let row">
                  <ng-container *ngIf="column === symbolColumns[column]">
                    <ng-container *ngIf="row[column] >= 1">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8.97363" cy="8" r="8" fill="#3DD5C8" />
                        <path
                          d="M5.97363 8.35897L7.8307 10.0076L12.4736 5.88574"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="normalColumns[column]">
                    {{ row[column] }}
                  </ng-container>

                  <ng-container *ngIf="formatingColumns[column]">
                    {{ formatingData(row[column]) }}
                  </ng-container>
                  <!-- {{ row[column] }} -->
                </mat-cell>
              </ng-container>

              <mat-header-row
                style="border-left: 1px solid #f4f6fa; background: #f4f6fa"
                *matHeaderRowDef="aggregatedDisplayedColumns; sticky: true"
              ></mat-header-row>
              <div>
                <mat-row
                  *matRowDef="let row; columns: aggregatedDisplayedColumns"
                >
                </mat-row>
              </div>
            </mat-table>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
      <div [hidden]="isAggregatedActive" class="csv-btn">
        <button
          style="
            background-color: #25d4cf;
            width: 159px;
            height: 45px;
            color: white;
          "
          class="next"
          mat-button
          (click)="downloadCsv()"
        >
          Download CSV
        </button>
        <mat-paginator
          #paginator
          [hidden]="aggregatedDataSource.data.length === 0"
          [pageSize]="20"
          [pageSizeOptions]="[20, 50]"
        >
        </mat-paginator>
      </div>
    </div>
    <div
      [hidden]="isAggregatedActive"
      class="note-wrap note normal-text-montserrat"
    >
      You can also check aggregated view by clicking
      <span
        style="color: #e61a27; cursor: pointer"
        (click)="toggleAggregated()"
      >
        here
      </span>
    </div>
  </div>
</div>
